import React from 'react'
import { ContentFrameWrapper } from '../content-frame-wrapper/ContentFrameWrapper';
import ReleaseNotesModalContent from './release-notes-modal-content/ReleaseNotesModalContent';
import { useAppSelector } from '../../../app/store';
import { EAppModalContentType } from './appModals.interfaces';
import FeedbackModalContent from './feedback-modal-content/FeedbackModalContent';
import { SettingsMenu } from '../content-frame-wrapper/settings-menu/SettingsMenu';
import './AppModals.scss';

const AppModals = () => {
  const { appModalContentType } = useAppSelector(store => store.appModalsReducer);

  const getModalContent = () => {
    switch (appModalContentType) {
      case EAppModalContentType.RELEASE_NOTES:
        return <ReleaseNotesModalContent />;
      case EAppModalContentType.FEEDBACK:
        return <FeedbackModalContent />;
      case EAppModalContentType.SETTINGS:
        return <SettingsMenu />;
      default:
        return <></>;
    }
  }

  return (
    <>
      {!!appModalContentType &&
        <div className='app-modals-container'>
          <ContentFrameWrapper className='app-modals-content-frame'>
            {getModalContent()}
          </ContentFrameWrapper>
        </div>
      }
    </>
  )
}

export default AppModals
