import { FunctionComponent, useEffect, useRef, useState } from "react";
import { ETasksGroupsType, IMessageDataTask } from "../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface";
import SingleTaskItemContainer, { ETaskViewType } from "../../chat-wrapper/chat/chat-conversation/message-data-card/tasks-list/tasks-list-single-item/SingleTaskItemContainer";
import { DragSourceMonitor } from "react-dnd";
import { EDragAndDropType, isDesktopView } from "../../../shared/utils/utils";
import { isMobileDevice } from "../../../shared/utils/isMobileDevice";
import { convertSingleItemToCalendarEvent } from "../calendar/calendar-day/CalendarDay.util";
import DraggableWrapper from "../../../shared/components/dragged-destination-preview/DraggableWrapper";
import { IDropResult } from "../calendar/calendar-day/CalendarDay";
import { useAppSelector } from "../../../app/store";
import { getStyleForDraggingPreview } from "../../../shared/components/dragged-destination-preview/DragAndDrop.utils";
import { IDragItem } from "../calendar/calendar-day/CalendarDragAndDrop.util";

interface IPlannerTaskProps {
  task: IMessageDataTask;
  index: number;
}

const defaultDuration = 60 * 60; // 1 hour

const PlannerTask: FunctionComponent<IPlannerTaskProps> = ({ task, index }) => {
  const taskContainerRef = useRef<HTMLDivElement | null>(null);
  const [shouldHide, setShouldHide] = useState(false);
  const { tasksFilter } = useAppSelector(store => store.StageTasksReducer);
  const taskElement = taskContainerRef.current?.querySelector(`.tasks-list-item`);
  const taskLeft = taskContainerRef.current?.getBoundingClientRect().left;
  const [draggingPreviewStyle, setDraggingPreviewStyle] = useState(getStyleForDraggingPreview(taskElement, taskLeft || null));

  useEffect(() => {
    setDraggingPreviewStyle({ ...getStyleForDraggingPreview(taskElement, taskLeft || null) });
  }, [taskContainerRef, taskElement, taskLeft])

  const getDraggedItemObj = (): IDragItem => {
    return {
      event: convertSingleItemToCalendarEvent({ ...task, duration: defaultDuration }),
      previewStyle: { ...draggingPreviewStyle, backgroundColor: "white" }
    }
  }

  const onEndDrag = (draggedItem: IDragItem, monitor: DragSourceMonitor<IDragItem, IDropResult>) => {
    if (monitor.didDrop() && draggedItem.event.id === task.id) setShouldHide(true);
  }

  // only in Desktop in the calendar screens (not in MY_PLAN screen)
  const canDrag = (isDesktopView() && !isMobileDevice() && !!tasksFilter && tasksFilter !== ETasksGroupsType.MY_PLAN)

  return (
    <>
      <DraggableWrapper<IDragItem, IDropResult>
        wrapperRef={taskContainerRef}
        className={`planner-task-container ${shouldHide ? 'd-none' : ""}`}
        id={`planner-task-draggable-wrapper-${task.id}`}
        type={EDragAndDropType.PLANNER_TO_CALENDAR_EVENT}
        item={getDraggedItemObj()}
        canDrag={() => canDrag}
        onEndDrag={onEndDrag}
        keyAttr={task.id!}
      >
        <SingleTaskItemContainer
          shouldHideOnComplete={false}
          viewType={ETaskViewType.CALENDAR_PLACEMENT}
          shouldDisplayActionButtons={false}
          shouldDisplayFirstTagColor={true}
          key={index}
          singleTask={task}
        />
      </DraggableWrapper>
    </>
  )
}

export default PlannerTask