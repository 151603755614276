import { useTranslation } from "react-i18next"
import micIcon from '../../../../assets/images/mic.svg'
import audioSendIcon from '../../../../assets/images/audio-send.svg'
import { useFormContext, useWatch } from "react-hook-form"
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from "react"
import { ResultReason, ProfanityOption, SpeechConfig, AudioConfig, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk'
import { EAPIStatus } from "../../../../shared/api/models"
import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk'
import sendMessageImg from '../../../../assets/images/send-button.svg';
import { IChatForm, IChatMessage, IHumanStudentTurnSendInputPayload } from "../../chat.interfaces"
import { useAppDispatch, useAppSelector } from "../../../../app/store"
import { useLocalStorage } from "../../../../shared/utils/useLocalStorage"
import { chatInputId, chatRecordCookieName, chatSessionIdLocalStorageKey } from "../../../../app/constants"
import { speechCookieParseToTokenPayload } from "./speech.utils"
import { setQueueMessageData } from "../../chat.store"
import { ApplicationInsightsApi } from "../../../../application-insights"
import { ConfirmModal } from "../../../../shared/components/confirm-modal/ConfirmModal"
import { isMobileDevice } from "../../../../shared/utils/isMobileDevice"
import { shouldDisplayTime } from "../Chat.utils"
import { onResizeTextareaHeightByTheContext } from "../../../../shared/utils/utils"
import AppButton from "../../../../shared/components/app-button/AppButton"
import chatFormSassVars from './ChatFormUserInput.module.scss'
import { useSwipeToClose } from "../../../../shared/hooks/swipe-hooks/useSwipeToClose"
import { ECloseSwipeDirection } from "../../../../shared/hooks/swipe-hooks/swipe.utils"
import './ChatFormUserInput.scss';
import Cookie from 'universal-cookie';

interface IChatFormUserInputProps {
  resizeInputByContentDelay?: number;
}

export const ChatFormUserInput = ({resizeInputByContentDelay}:IChatFormUserInputProps) => {
  const cookie = new Cookie();
  const { resetField, setValue, getValues, register, formState, control, setFocus, watch } = useFormContext<IChatForm>();
  const { sessionResponse, chatQueueProcessingType } = useAppSelector(store => store.chatReducer);
  const [sessionIdLocalStorage,] = useLocalStorage(chatSessionIdLocalStorageKey, '');
  const { t } = useTranslation();
  const recorderRef = useRef<SpeechRecognizer | null>(null);
  const resizableTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [isRecorderLoading, setIsRecorderLoading] = useState(false);
  const recognizedTextRef = useRef<string>('');
  const dispatch = useAppDispatch();
  const [shouldDisplayErrorModal, setShouldDisplayErrorModal] = useState(false);
  const chatMessagesArr = useWatch({ control, name: 'messagesArr' });
  const isRecording = useWatch({ control, name: 'isRecording' });
  const userMessageWatch = watch('userMessage');
  const chatBarTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const swipeThresholdForClosingKeyboard = 25;
  // swipe down to close the keyboard
  const { handleTouchStart, handleTouchMove } = useSwipeToClose({
    swipeDirection: ECloseSwipeDirection.DOWN,
    onSwipeMove: () => onSwipeChatBarDown(),
    shouldCalculateTranslate: false,
    threshold: swipeThresholdForClosingKeyboard
  });
  
  const onSwipeChatBarDown = () => {
    if (chatBarTextareaRef.current) {
      chatBarTextareaRef.current.blur();
    }
  }

  const handleTextAreaTouchMove = (e: React.TouchEvent) => {
    if(!chatBarTextareaRef.current) return;
    const chatBarCurrentHeight = parseInt(chatBarTextareaRef.current.style.height);
    const chatBarMaxHeight =  parseInt(chatFormSassVars.chatBarTextAreaMaxHeightMobile);
    // when there is scroller on the textarea and the user scrolling to see more from his input,
    // the event will not bubble to the parent onTouchMove event so the keyboard will not be closed.
    if(chatBarCurrentHeight > chatBarMaxHeight){
      e.stopPropagation();
    }
  }

  const onSubmitChatForm = useCallback(() => {
    try {
      const formData = getValues();
      setValue('shouldDisplayRecordingErrorMsg', false);
      const sessionIdReq = sessionResponse?.data?.sessionId || sessionIdLocalStorage;
      const userMessage: IChatMessage = {
        party: 'User',
        msg: formData.userMessage.trim(),
        messageTime: Date.now(),
        creationTime: new Date().toISOString(),
        shouldDisplayTime: shouldDisplayTime(Date.now(), chatMessagesArr.length > 0 ? chatMessagesArr[chatMessagesArr.length - 1].messageTime : null),
        sessionId: sessionIdReq
      }
      const payload: IHumanStudentTurnSendInputPayload = {
        sessionId: sessionIdReq,
        studentInput: formData.userMessage,
      }
      resetField('userMessage');
      onResizeTextareaHeightByTheContext(chatBarTextareaRef.current);
      setFocus('userMessage');
      dispatch(setQueueMessageData({ type: 'manual', botRequestJson: JSON.stringify(payload), localUserChatMessage: JSON.stringify(userMessage) }))
    }
    catch (e) {
      ApplicationInsightsApi.trackException(e);
      console.error(e);
    }
  }, [getValues, setValue, sessionResponse?.data?.sessionId, sessionIdLocalStorage, chatMessagesArr, resetField, setFocus, dispatch]);

  const onSpeechSubmit = (text: string) => {
    setValue('userMessage', text);
    onSubmitChatForm();
  };

  const handleStartRecording = () => {
    try {
      if (cookie.get(chatRecordCookieName)) {
        setIsRecorderLoading(true);
        const speechTokenData = speechCookieParseToTokenPayload(cookie.get(chatRecordCookieName) || "");
        const speechConfig: SpeechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(speechTokenData?.token || "", speechTokenData?.region || "");
        speechConfig.enableDictation();
        speechConfig.setProfanity(ProfanityOption.Removed);
        const audioConfig: AudioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        recorderRef.current = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
        recorderRef.current?.startContinuousRecognitionAsync(startRecordingCallBack, (e) => {
          setIsRecorderLoading(false);
          setValue('isRecording', false);
          if (e.toLowerCase().includes('permission')) {
            setShouldDisplayErrorModal(true);
            ApplicationInsightsApi.trackEvent('Speech to text client permission denied.', { message: e });
          }
        });
        subscribeToRecordingEvents();
      }
    } catch (error) {
      console.error(error);
      ApplicationInsightsApi.trackException(error);
      setIsRecorderLoading(false);
      setValue('isRecording', false);
    }
  };

  const startRecordingCallBack = () => {
    setIsRecorderLoading(false);
    recognizedTextRef.current = '';
    setValue('isRecording', true);
  }

  const subscribeToRecordingEvents = () => {
    if (recorderRef.current) {
      recorderRef.current.recognized = (_, event) => {
        if (event.result.reason === ResultReason.RecognizedSpeech) {
          recognizedTextRef.current += event.result.text + ' '; // each succesful speech to text recognition event is accumulated
        }
        if ([ResultReason.NoMatch].includes(event.result.reason)) {
          setValue('shouldDisplayRecordingErrorMsg', true);
        }
      }
    }
  }

  const handleStopRecording = (type: "send" | "cancel") => {
    if (recorderRef.current) {
      recorderRef.current.stopContinuousRecognitionAsync(() => {
        const userText = recognizedTextRef.current.trim()
        if (type === "send" && !!userText.trim().length) onSpeechSubmit(userText.trim());
        else if (type === "cancel") setValue('shouldDisplayRecordingErrorMsg', false);
        recorderRef?.current?.close();
        setValue('isRecording', false);
      })
    }
  }


  const handleTextareaKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    // if it is not a mobile device, pressing the 'enter' key will submit the form.
    if (!isMobileDevice() && event.key.toLowerCase() === 'enter' && !event.shiftKey && chatQueueProcessingType !== 'manual') {
      event.preventDefault();
      // Trigger the form submission
      submitFormIfMessageHasValidContent();
    }
  };

  const onClickSendMessageButton = () => {
    if (chatQueueProcessingType === 'manual') {
      setFocus('userMessage');
      return;
    }
    else submitFormIfMessageHasValidContent();
  }
  
  const submitFormIfMessageHasValidContent = () => {
    const hasValidContent = userMessageWatch.trim().length > 0;
    if (hasValidContent) onSubmitChatForm();
  }

  const cleanResizableTimer = useCallback(() => {
    if(resizableTimerRef.current) clearTimeout(resizableTimerRef.current);
  },[])

  // responsible for resizing the textarea height based on the content at the first time the component is rendered
  // when the component move from chat to stage on expend and vise versa
  useEffect(() => {
    cleanResizableTimer();
    resizableTimerRef.current = setTimeout(() => onResizeTextareaHeightByTheContext(chatBarTextareaRef.current),resizeInputByContentDelay||0);
    return () => cleanResizableTimer();
  },[cleanResizableTimer, resizeInputByContentDelay])

  return (
    <>
      <form 
        data-testid="chat-form" 
        id="chat-form" 
        className="form chat-bar-form"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        >
        <section>
          <textarea
            {...register('userMessage', { required: true, minLength: 1 })}
            onInput={(e) => onResizeTextareaHeightByTheContext(e?.currentTarget)}
            onFocus={(e) => onResizeTextareaHeightByTheContext(e?.currentTarget)}
            onKeyDown={(e) => handleTextareaKeyPress(e)}
            disabled={getValues('isRecording') || [EAPIStatus.PENDING, EAPIStatus.REJECTED].includes(sessionResponse.status)}
            data-testid="student-input"
            id={chatInputId}
            className={`${isRecording ? 'recording' : ''}`}
            aria-label={t('chatStudentInputLabelText')}
            placeholder={isRecording ? t("chatFormRecordingPlaceholderText") : t("chatFormPlaceholderText")} autoComplete="off"
            ref={(node) => {
              register('userMessage').ref(node);
              chatBarTextareaRef.current = node;
            }}
            onTouchMove={handleTextAreaTouchMove}
          />

          {isRecording &&
            <AppButton
              type="button"
              className="cancel-recording static-string"
              onClick={() => handleStopRecording("cancel")}
              id="chat-cancel-recording"
            >
              {t("cancelRecordingButtonText")}
            </AppButton>
          }

          {formState.isValid ?
            <AppButton
              form="chat-form"
              data-testid="chat-form-submit"
              id="chat-form-submit"
              className={`chat-form-submit ${chatQueueProcessingType === 'manual' ? 'chat-form-submit--disabled' : ''}`}
              type='button'
              onClick={onClickSendMessageButton}
            >
              <img
                src={sendMessageImg}
                alt={t("chatSendButtonAltText")}
                className='send-button'
              />
            </AppButton>
            :
            <AppButton
              onClick={() => { isRecording ? handleStopRecording("send") : handleStartRecording() }}
              data-testid="chat-form-audio"
              className="chat-form-submit"
              id={`${isRecording ? 'chat-send-recording' : 'chat-start-recording'}`}
              disabled={chatQueueProcessingType === 'manual'}>
              <img
                src={isRecording ? audioSendIcon : micIcon}
                alt={t("chatSendButtonAltText")}
                className={`mic-button${isRecording ? ' mic-button--active' : (isRecorderLoading || !cookie.get(chatRecordCookieName)) ? ' mic-button--loading' : ""}`}
              />
            </AppButton>
          }
        </section>
      </form>
      {shouldDisplayErrorModal && <ConfirmModal title={t("chatRecordingPermissionModalText")}
        confirmBtnText={t("chatRecordingPermissionModalConfirmButtonText")}
        onConfirm={() => setShouldDisplayErrorModal(false)} />}
    </>
  )
}