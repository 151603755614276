import { FunctionComponent } from "react";
import './AppEmptyState.scss';

export interface IAppEmptyStateProps {
  emptyStateImage: string;
  paragraph: string;
  className?: string;
}

const AppEmptyState:FunctionComponent<IAppEmptyStateProps> = ({emptyStateImage,paragraph,className=''}) => {
  return (
    <div className={`app-empty-state ${className}`}>
      <div className='app-empty-state--paragraph-container'>
        <p className='app-empty-state--paragraph static-string'>{paragraph}</p>
      </div>
     <img src={emptyStateImage} alt="empty-state-mascot" className='app-empty-state--mascot'/>
    </div>
  )
}

export default AppEmptyState