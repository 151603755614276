import React from 'react';
import myTasksEmptyStateImage from '../../../../../../assets/images/mascot/mascot-tasks.png';
import quickWinEmptyStateImage from '../../../../../../assets/images/mascot/mascot-TV-laptop.png';
import doneEmptyStateImage from '../../../../../../assets/images/mascot/mascot-meditate.png';
import lateEmptyStateImage from '../../../../../../assets/images/mascot/mascot-running.png';
import unscheduledEmptyStateImage from '../../../../../../assets/images/mascot/mascot-chart.png';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../app/store';
import AppEmptyState, { IAppEmptyStateProps } from '../../../../../../shared/components/app-empty-state/AppEmptyState';
import { ETasksGroupsType } from '../stageTasks.interface';

export const StageTasksEmptyState = () => {
  const { tasksFilter } = useAppSelector(store => store.StageTasksReducer);
  const { t } = useTranslation();

  const getEmptyStateProps = (): IAppEmptyStateProps => { 
    switch (tasksFilter) {
      case ETasksGroupsType.QUICK_WINS:
        return {
          emptyStateImage: quickWinEmptyStateImage,
          paragraph: t("quickWinTasksEmptyStateText"),
        }
      case ETasksGroupsType.OVERDUE:
        return {
          emptyStateImage: lateEmptyStateImage,
          paragraph: t("lateTasksEmptyStateText"),
        }
      case ETasksGroupsType.UNSCHEDULED:
        return {
          emptyStateImage: unscheduledEmptyStateImage,
          paragraph: t("unscheduledTasksEmptyStateText"),
        }
      case ETasksGroupsType.DONE:
        return {
          emptyStateImage: doneEmptyStateImage,
          paragraph: t("completedTasksEmptyStateText"),
        }
      default:
        return {
          emptyStateImage: myTasksEmptyStateImage,
          paragraph: t("myTasksEmptyStateText"),
        }
    }
  }



  return (
    <AppEmptyState {...getEmptyStateProps()} className='stage-tasks-empty-state' />
  )
};