import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import './ScratchpadNotification.scss';

interface IScratchpadNotificationProps {
  notificationText: string;
  destroyAnimationType: "fadeOut" | "shrinkOut";
  onDestroy?: () => void;
  className?: string;
}

const ScratchpadNotification: FunctionComponent<IScratchpadNotificationProps> = ({ notificationText, destroyAnimationType, onDestroy, className }) => {
  const [shouldStartDestroyAnimation, setShouldStartDestroyAnimation] = useState(false);
  const startAnimationTimerRef = useRef<NodeJS.Timeout | null>(null);
  // After this time passes, the destroy animation (fade-out) will start.
  const timeInMilliSecondsToStartTheDestroyAnimation = 1500;

  const clearTimer = () => {
    if (startAnimationTimerRef.current) clearTimeout(startAnimationTimerRef.current);
  }

  const startTimerForDestroyAnimation = useCallback(() => {
    clearTimer();
    setTimeout(() => {
      setShouldStartDestroyAnimation(true);
    }, timeInMilliSecondsToStartTheDestroyAnimation);
  }, []);

  useEffect(() => {
    return clearTimer();
  }, []);

  return (
    <div
      className={`scratchpad-notification fadeIn ${className} ${shouldStartDestroyAnimation ? destroyAnimationType : ""}`}
      onAnimationEnd={(animationData) => {
        if (["fadeOutAnimation", "shrinkOutAnimation"].includes(animationData.animationName)) {
          !!onDestroy && onDestroy();
        } else startTimerForDestroyAnimation();
      }}>
      {notificationText}
    </div>
  )
}

export default ScratchpadNotification;
