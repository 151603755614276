import { ReactComponent as taskSvgComponent } from "../../../../assets/images/planner-create-overlay-task-icon.svg"
import { ReactComponent as eventSvgComponent } from "../../../../assets/images/planner-create-overlay-event-icon.svg"
import { ReactComponent as workBlockSvgComponent } from "../../../../assets/images/planner-create-overlay-work-block-icon.svg"
import { useTranslation } from 'react-i18next';
import PlusBtnOverlaySingleOption from './PlusBtnOverlaySingleOption';
import './PlusButtonOverlay.scss';

const PlusButtonOverlay = () => {
  const { t } = useTranslation();

  return (
    <div className='planner-plus-overlay'>
      <h3 className='planner-plus-overlay-title static-string'>{t("plannerPlusOverlayTitle")}</h3>
      <PlusBtnOverlaySingleOption type='task' description={t("plannerPlusOverlayTaskOptionDescription")} SvgComponent={taskSvgComponent}/>
      <PlusBtnOverlaySingleOption type='event' description={t("plannerPlusOverlayEventOptionDescription")} SvgComponent={eventSvgComponent}/>
      <PlusBtnOverlaySingleOption type='workBlock' description={t("plannerPlusOverlayWorkBlockOptionDescription")} SvgComponent={workBlockSvgComponent}/>
    </div>
  )
}

export default PlusButtonOverlay
