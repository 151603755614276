import React, { FunctionComponent } from 'react';
import './InstructionsModal.scss';
import closeImg from "../../../assets/images/close-create-task-modal-desk.svg";

export interface IInstructionsModalProps {
  text: string;
  onClose?: () => void;
}

export const InstructionsModal: FunctionComponent<IInstructionsModalProps> = ({ text, onClose }) => {
  return (
    <div className="instructions-modal">
      <button id="close-instructions-modal" className="close-button" onClick={onClose}>
        <img src={closeImg} alt="close" />
        </button>
      <p>{text}</p>
    </div>
  );
}