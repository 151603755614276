import React from 'react'
import { useTranslation } from 'react-i18next';
import DiscordLink from '../../discord-link/DiscordLink';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import releaseNotesImg from '../../../../assets/images/release-notes-image.png';
import { setAppModalContentType } from '../appModals.store';
import './ReleaseNotesModalContent.scss';

const ReleaseNotesModalContent = () => {
  const { userAppData } = useAppSelector(store => store.userReducer)
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    dispatch(setAppModalContentType(null));
  }

  return (
    <div className='release-notes-container'>
      <section>
        <h2 className="title">{t("releaseNotesModalTitle")}</h2>
        <div className="release-notes-content">
          <h3 className='subtitle static-string'>{t("releaseNotesModalSubTitle")}</h3>
          <img src={releaseNotesImg} alt={t("releaseNotesModalSubTitle")} />
          <div className='separation' />
          {userAppData.data?.releaseNotes}
        </div>
      </section>
      <section className="bottom-container">
        <DiscordLink className='release-notes-discord-link' textFollowTheDiscordLink={t("AppModalTextFollowJoinOurDiscordLink")} />
        <button className='modal-button' onClick={onCloseModal}>{t("releaseNotesModalButtonText")}</button>
      </section>
    </div>
  )
}

export default ReleaseNotesModalContent;
