import React, { FunctionComponent } from 'react'
import OverlayTypeDetails from '../../task-edit-overlays/overlay-type-details/OverlayTypeDetails'
import { getRecurringFrequencyText, getReminderCalendarText } from '../../task-edit-overlays/TaskEditOverlay.utils'
import { IRecurrenceValues } from '../../stageTasks.interface';
import recurrenceIcon from '../../../../../../../assets/images/recurrence.svg';
import bellIcon from '../../../../../../../assets/images/task-overlay-bell-icon.svg';
import { useTranslation } from 'react-i18next';
import './ExternalEventsRecurrenceAndReminder.scss';

interface IExternalEventsRecurrenceAndReminder {
  reminderValue: number | null | undefined;
  recurrenceValues: IRecurrenceValues;
  workTimeDate?: Date;
}

const ExternalEventsRecurrenceAndReminder: FunctionComponent<IExternalEventsRecurrenceAndReminder> = ({ reminderValue, recurrenceValues, workTimeDate }) => {
  const { t } = useTranslation();

  return (
    <>
      {(!!reminderValue || reminderValue === 0) &&
        <OverlayTypeDetails
          icon={bellIcon}
          title={t("ReminderCalendarTitle")}
          data={getReminderCalendarText(reminderValue, 'workTime', workTimeDate)}
          className='cursor-default task-detail-section external-event-detail'
        />
      }
      {recurrenceValues?.workTimeRecurrenceType &&
        <OverlayTypeDetails
          icon={recurrenceIcon}
          title={t("recurringFrequencyTitle")}
          data={getRecurringFrequencyText(recurrenceValues)}
          className='cursor-default task-detail-section external-event-detail'
        />
      }
    </>
  )
}

export default ExternalEventsRecurrenceAndReminder
