import { useTranslation } from "react-i18next";
import { ContentFrameWrapper } from "../../../../shared/components/content-frame-wrapper/ContentFrameWrapper"
import { useAppDispatch, useAppSelector } from "../../../store";
import { useCallback, useState } from "react";
import { API_ROUTES, APP_ROUTING_PATHS, EMagiLinkLoginPageViewType, loginTypeStorageKey, registrationParametersStorageKey } from "../../../constants";
import { useLocalStorage } from "../../../../shared/utils/useLocalStorage";
import { Controller, useForm } from "react-hook-form";
import { isEmbeddedBrowser } from "../../auth.utils";
import { EAPIStatus } from "../../../../shared/api/models";
import { loginReqAction, requestMagicLink } from "../../auth.store";
import { baseUrl } from "../Login";
import emailMascot from '../../../../assets/images/email-mascot.png';
import OtpInput from "../../../../shared/components/otp-input/OtpInput";
import './LoginWithEmailPage.scss';
import AppButton from "../../../../shared/components/app-button/AppButton";
import { ApplicationInsightsApi } from "../../../../application-insights";
import { useNavigate } from "react-router-dom";

export const LoginWithEmailPage = () => {
    const { magicLinkRes, loginRes } = useAppSelector(store => store.authReducer);
    const { t } = useTranslation();
    const [viewType, setViewType] = useState<EMagiLinkLoginPageViewType>(EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK);
    const [errorText, setErrorText] = useState('');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [, setLoginType] = useLocalStorage(loginTypeStorageKey, '');
    const { control, register, watch, formState: { isValid } } = useForm({
        defaultValues: {
            email: "",
            code: ""
        }
    });
    const isMobileOrEmbeddedBrowser = isEmbeddedBrowser();

    const emailWatch = watch('email');
    const codeWatch = watch('code');

    const onSubmit = () => {
        if (viewType === EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK) {
            onSubmitEmail();
        }
        else {
            onSubmitVerificationCode();
        }
    }

    const onSubmitEmail = () => {
        if (magicLinkRes.status === EAPIStatus.PENDING) return;
        setErrorText('');
        dispatch(requestMagicLink({
            payload: {
                email: emailWatch || "",
                redirectUri: `${baseUrl}${APP_ROUTING_PATHS.SSO_MAGIC_LINK}`,
                registrationParameters: localStorage.getItem(registrationParametersStorageKey) || undefined,
                isShortCodeRequested: !isMobileOrEmbeddedBrowser
            },
            api: API_ROUTES.AUTH.REQUEST_MAGIC_LINK_EMAIL
        })).unwrap().then((res) => {
            if (res) {
                setLoginType("email")
                setViewType(isMobileOrEmbeddedBrowser ? EMagiLinkLoginPageViewType.MAGIC_LINK_SENT : EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP);
            }
        }).catch(() => { setErrorText(t('somethingWentWrongTryAgainError').toString()) });
    }

    const onSubmitVerificationCode = () => {
        dispatch(loginReqAction({ payload: { accessToken: codeWatch, redirectUri: "", error: "", email: emailWatch }, loginType: "email" }))
            .unwrap()
            .catch(e => {
                ApplicationInsightsApi.trackException(e);
                setErrorText(t('phoneVerificationPhoneNumberError').toString());
            });
    }

    const getTitle = useCallback(() => {
        switch (viewType) {
            case EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK:
                return t('continueWithEmailTitle1');
            case EMagiLinkLoginPageViewType.MAGIC_LINK_SENT:
                return t('continueWithEmailTitle2');
            case EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP:
                return t('continueWithEmailTitle3');
            default:
                return '';
        }
    }, [viewType, t]);

    const getInstructions = useCallback(() => {
        switch (viewType) {
            case EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK:
                return isMobileOrEmbeddedBrowser ? t('continueWithEmailInstructions1') : t('continueWithEmailInstructions2');
            case EMagiLinkLoginPageViewType.MAGIC_LINK_SENT:
                return t('continueWithEmailInstructions3');
            case EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP:
                return t('continueWithEmailInstructions4');
            default:
                return '';
        }
    }
        , [viewType, isMobileOrEmbeddedBrowser, t]);

    const handleNavigateBack = () => {
        switch (viewType) {
            case EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK:
                navigate(-1);
                break;
            case EMagiLinkLoginPageViewType.MAGIC_LINK_SENT:
            case EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP:
                setViewType(EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK);
                break;
            default:
                navigate(-1);
                break;
        }
    }

    return (
        <div className={`login-with-email-page login-with-email-page--view-type-${viewType} shorten-onboarding-flow auth-screen`} id="login-with-email-page">
            <ContentFrameWrapper className='login-with-email-frame-wrapper'>
                <div className="login-with-email-content-container scroll-y-container-hidden-scroll-bar">
                    <form onSubmit={e => { e.preventDefault(); onSubmit() }} className="login-with-email-text-and-email-content-container">
                        <div className="login-with-email-text-container">
                            <div className='title-container'>
                                <img src={emailMascot} alt="mascot" className='mascot' />
                                <h1 className='title login-with-email-title'>{getTitle()}</h1>
                            </div>
                            <p className="login-with-email-instructions white-space-pre-wrap">{getInstructions()}</p>
                            {viewType === EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP && <p className='email-verification-text email-address-to-verify'>{emailWatch}</p>}
                        </div>
                        {viewType === EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP && <p className='email-verification-enter-code'>{t("phoneVerificationEnterCodeText")}</p>}
                        <div className="login-with-email-input-container registration-input-container">
                            {viewType === EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK && <input className="register-input register-input--email" {...register('email', {
                                required: "required", pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "",
                                }
                            })} type="email" name="email" id="login-with-email-input" required autoFocus />}
                            {viewType === EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP && <Controller
                                name='code'
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <OtpInput
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />}
                            {errorText && <small className='error' data-testid="register-error">{errorText}</small>}
                        </div>
                        {viewType === EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK && <AppButton onClick={onSubmit} className='auth-next-btn' form="update-email-form" id="login-with-email-submit" data-testid="login-with-email-submit" type='button' disabled={!isValid || magicLinkRes.status === EAPIStatus.PENDING} >{t('sendButtonText')}</AppButton>}
                        {viewType === EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP && <AppButton onClick={onSubmit} className='auth-next-btn' form="update-email-form" id="login-with-email-verification-code-submit" data-testid="login-with-email-verification-code-submit" type='button' disabled={!codeWatch || !isValid || loginRes.status === EAPIStatus.PENDING} >{t('registerScreenNextButtonText')}</AppButton>}
                    </form>
                </div>
            </ContentFrameWrapper>
            <AppButton id="login-with-email-back-button" onClick={() => {setErrorText(''); handleNavigateBack();}} className='back-link'>{t('registerScreenBackButtonText')}</AppButton>
        </div>
    )
}