import React, { ButtonHTMLAttributes, FunctionComponent, MouseEvent, ReactNode } from 'react'

interface IAppButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: MouseEvent<HTMLButtonElement, any>) => void;
  children?: ReactNode | ReactNode[];
  id: string; // mandatory
}

const AppButton: FunctionComponent<IAppButtonProps> = (attributes) => {
  const getButtonAttr = () => {
    let tempAttributes = {...attributes}
    delete tempAttributes.type;
    delete tempAttributes.className;
    delete tempAttributes.onClick;
    delete tempAttributes.children;
    return {...tempAttributes};
  }

  return (
    <button {...getButtonAttr()} type={attributes.type || 'button'} className={`app-button ${attributes.className}`} onClick={attributes?.onClick} >
      {attributes?.children}
    </button>
  )
}

export default AppButton
