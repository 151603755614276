import { FunctionComponent } from "react";
import { EComponentStep } from "../TaskEditOverlay.utils";
import QuickOptionsRecurrence from "./quick-options-recurrence/QuickOptionsRecurrence";
import { IRecurrenceValues } from "../../stageTasks.interface";
import CustomRecurrence from "./custom-recurrence/CustomRecurrence";

interface IRecurrenceOverlayProps {
  workTimeComponentStep: number;
  setWorkTimeComponentStep: React.Dispatch<React.SetStateAction<number>>;
  setRecurrenceValues: React.Dispatch<React.SetStateAction<IRecurrenceValues>>;
  recurrenceValues: IRecurrenceValues;
  workTimeWeekDayIndx: number;
}

const RecurrenceOverlay: FunctionComponent<IRecurrenceOverlayProps> = ({workTimeComponentStep, setWorkTimeComponentStep, setRecurrenceValues, recurrenceValues, workTimeWeekDayIndx}) => {
  const getReminderComponentByType = () => {
    switch (workTimeComponentStep) {
      case EComponentStep.RECURRENCE_PRESET_OPTIONS:
        return <QuickOptionsRecurrence setWorkTimeComponentStep={setWorkTimeComponentStep} setRecurrenceValues={setRecurrenceValues} workTimeWeekDayIndx={workTimeWeekDayIndx} />;
      case EComponentStep.RECURRENCE_CUSTOM_SELECTOR:
        return <CustomRecurrence setWorkTimeComponentStep={setWorkTimeComponentStep} setRecurrenceValues={setRecurrenceValues} recurrenceValues={recurrenceValues} workTimeWeekDayIndx={workTimeWeekDayIndx} />;
      default:
        return <></>;
    }
  }

  return (
    <div>
      {getReminderComponentByType()}
    </div>
  )
}

export default RecurrenceOverlay
