import { FunctionComponent, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import {  tokenLocalStorageKey } from '../constants';
import { useLocalStorage } from '../../shared/utils/useLocalStorage';

interface IProps {
  children: ReactElement;
}

// will wrap the auth routing - if there is token and userInfo with phone number navigate the user inside the app to the chat page
export const ProtectedLoginRoute: FunctionComponent<IProps> = ({ children }) => {
  const [tokenLocalStorage,] = useLocalStorage(tokenLocalStorageKey, '');

  return (!!tokenLocalStorage) ? <Navigate to='/' replace/> : children;
};
