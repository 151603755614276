import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../app/store';
import { createOrContinueSession } from './chat.store';
import { ResizableContainer } from './resizable-container/ResizableContainer';
import WelcomeScreen from './welcome-screen/WelcomeScreen';
import { ApplicationInsightsApi } from '../../application-insights';
import ProductTourWrapper, { EViewType } from '../product-tour/ProductTourWrapper';
import './ChatWrapper.scss';

export const ChatWrapper: FunctionComponent = () => {
  // this carousel open only after registration
  const [isWelcomeScreenOpen, setIsWelcomeScreenOpen] = useState(false);
  const componentShouldLoad = useRef(true);
  const dispatch = useAppDispatch();

  const onStartSession = useCallback(() => {
    // call createOrContinueSession Api
    dispatch(createOrContinueSession())
    .unwrap()
    .catch((e) => {
      ApplicationInsightsApi.trackException(e);
    });
  }, [dispatch])

  useEffect(() => {
    if (componentShouldLoad.current) {
      componentShouldLoad.current = false;
      onStartSession();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onStartSession]);

  return (
    <div className="chat-wrapper">
      {!isWelcomeScreenOpen && <ResizableContainer />}
      {/* This carousel will be displayed only after registration. */}
      <WelcomeScreen isWelcomeScreenOpen={isWelcomeScreenOpen} setIsWelcomeScreenOpen={setIsWelcomeScreenOpen} />
      <ProductTourWrapper
        dataAttribute='data-product-tour'
        viewType={EViewType.PRODUCT_TOUR}
      /> 
    </div>
  )
}