import { usePreview } from 'react-dnd-multi-backend';
import { CSSProperties, useRef } from 'react';
import { useAppSelector } from '../../../app/store';
import { EDragAndDropType, getDomElementByQuerySelector, isCursorInElement } from '../../utils/utils';
import { getPreviewTransformStyle, IDragAndDropPreviewCalendarDimensions } from './DragAndDrop.utils';
import { plannerTaskListInnerContainerClassName } from '../../../app/constants';
import { IDragItem } from '../../../features/plan/calendar/calendar-day/CalendarDragAndDrop.util';
import { ETasksGroupsType } from '../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import SassVariables from "../../../styles/style.module.scss";
import WorkBlockSassVariables from "../../../features/plan/calendar/calendar-day/work-block-tasks-list/WorkBlockTasksList.module.scss";
import CalendarSassVariables from "../../../features/plan/calendar/calendar-day/CalendarDay.module.scss";

export const DraggedCalendarPlanDestinationPreview = () => {
  const ref = useRef(null);
  const preview = usePreview();
  const { dragAndDropCalendarDimensions, calendarFirstCellVisiblePortion } = useAppSelector(store => store.StagePlannerReducer);
  const { tasksFilter } = useAppSelector(store => store.StageTasksReducer);

  if (!preview.display) {
    return null;
  }

  const { item, itemType, style, monitor } = preview;
  let previewStyle = (item as IDragItem)?.previewStyle || {};
  let shouldSnap: boolean = itemType === EDragAndDropType.CALENDAR_EVENT;
  let width = previewStyle.width;
  let height = previewStyle.height;
  let backgroundColor = (item as IDragItem)?.event?.backgroundColor || previewStyle.backgroundColor;
  let shouldMoveOnXAxis = true;
  let left: number = 0;
  let titleColor = (item as IDragItem)?.event?.titleColor || SassVariables.MaxDarkColor;

  if (itemType === EDragAndDropType.PLANNER_TO_CALENDAR_EVENT) {
    const plannerContainerEl = getDomElementByQuerySelector(`.${plannerTaskListInnerContainerClassName}`);
    const isOverPlanner = isCursorInElement(plannerContainerEl, monitor.getClientOffset()?.x, monitor.getClientOffset()?.y);
    shouldSnap = !isOverPlanner;
    if (isOverPlanner) {
      width = previewStyle.width;
      height = previewStyle.height;
      backgroundColor = previewStyle.backgroundColor;
      shouldMoveOnXAxis = false;
      titleColor = SassVariables.MaxDarkColor;
    }
    else {
      backgroundColor = (item as IDragItem)?.event?.backgroundColor || previewStyle?.backgroundColor;
      titleColor = (item as IDragItem)?.event?.titleColor ||  SassVariables.MaxDarkColor;
    }
  }

  if (shouldSnap) {
    width = dragAndDropCalendarDimensions?.cellWidth ? `${dragAndDropCalendarDimensions?.cellWidth}px` : previewStyle?.width;
    height = (item as IDragItem)?.event?.height ? `${(item as IDragItem)?.event?.height}px` : previewStyle?.height;

    if (
      [EDragAndDropType.CALENDAR_EVENT, EDragAndDropType.PLANNER_TO_CALENDAR_EVENT].includes(itemType as EDragAndDropType)
      && tasksFilter === ETasksGroupsType.MY_DAY
      && dragAndDropCalendarDimensions?.cellWidth
    ) {
      left = 40;
      width = `${dragAndDropCalendarDimensions?.cellWidth - left}px`;
    }
  }

  const getCalendarDimensionsForCalcPreviewPosition = (): IDragAndDropPreviewCalendarDimensions | null => {
    if (!dragAndDropCalendarDimensions || !calendarFirstCellVisiblePortion) return null;
    return {
      ...dragAndDropCalendarDimensions,
      firstCellVisiblePortion: calendarFirstCellVisiblePortion
    }
  }

  const previewTransformAndPosition = getPreviewTransformStyle(
    monitor.getInitialSourceClientOffset(),
    monitor.getClientOffset(),
    shouldSnap,
    getCalendarDimensionsForCalcPreviewPosition(),
    shouldMoveOnXAxis,
    previewStyle?.left
  );

  const localStyle: CSSProperties = {
    ...style,
    ...previewStyle,
    ...previewTransformAndPosition.style,
    left: `${left}px`,
    width,
    height,
    backgroundColor,
    cursor: 'move',
    color: titleColor,
    paddingTop: (item as IDragItem)?.event?.isWorkBlock ? WorkBlockSassVariables.workBlockContainerPadding : CalendarSassVariables.calendarEventPaddingTop,
    paddingLeft: (item as IDragItem)?.event?.isWorkBlock ? WorkBlockSassVariables.workBlockContainerPadding : CalendarSassVariables.calendarEventPaddingLeft,
    paddingRight: (item as IDragItem)?.event?.isWorkBlock ? WorkBlockSassVariables.workBlockContainerPadding : CalendarSassVariables.calendarEventPaddingRight,
    fontSize: (item as IDragItem)?.event?.titleFontSize || "14px",
    boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.25)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  (item as IDragItem).snapYDroppedPosition = previewTransformAndPosition.snapY;

  return (
    <div 
      ref={ref} 
      style={localStyle} 
    >
    {(item as IDragItem)?.event?.title}
  </div>
  );
}