import { XYCoord } from "react-dnd"
import { CSSProperties } from "react"
import { IPreviewStyleForDestinationPreview } from "../../utils/utils"
import { IDragAndDropCalendarDimensions } from "../../../features/plan/calendar/calendar-day/CalendarDragAndDrop.util";

interface IGetItemStyles {
  style: CSSProperties,
  snapY?: number
}

export interface IDragAndDropPreviewCalendarDimensions extends IDragAndDropCalendarDimensions {
  firstCellVisiblePortion: number;
}

export const getTranslateString = (x: number, y: number) => `translate(${x}px, ${y}px)`;

export function snapToGrid(x: number, y: number, calendarDimensions: IDragAndDropPreviewCalendarDimensions): [number, number] {
  const relativeToCalendarX = x - calendarDimensions.calendarContainerStartX;
  const relativeToCalendarY = y - calendarDimensions.calendarContainerStartY;

  const cursorOffsetInCellX = relativeToCalendarX % calendarDimensions.cellWidth
  const cursorOffsetInCellY = relativeToCalendarY % calendarDimensions.cellHeight
  // the cell index the cursor is currently on
  const cursorCellIndex = Math.floor(relativeToCalendarX / calendarDimensions.cellWidth);
  const rightBoundary = calendarDimensions.calendarContainerWidth-calendarDimensions.cellWidth;
  const bottomBoundary = calendarDimensions.calendarContainerHeight-calendarDimensions.cellHeight;
  let offsetRight = relativeToCalendarX - cursorOffsetInCellX + cursorCellIndex;
  // prevent the element from going out of the calendar container (right/left)
  offsetRight = Math.min(Math.max(offsetRight,0),rightBoundary);
  
  let offsetBottom = relativeToCalendarY - cursorOffsetInCellY;
  // prevent the element from going out of the calendar container (top/bottom)
  offsetBottom = Math.min(Math.max(offsetBottom,0), bottomBoundary);

  const snappedX = calendarDimensions.calendarContainerStartX+offsetRight;
  let snappedY = calendarDimensions.calendarContainerStartY+offsetBottom;
  if (calendarDimensions.firstCellVisiblePortion < calendarDimensions.cellHeight) {
    if (calendarDimensions.firstCellVisiblePortion < calendarDimensions.cellHeight / 2) {
      snappedY += calendarDimensions.firstCellVisiblePortion;
    }
    else {
      snappedY -= calendarDimensions.cellHeight - calendarDimensions.firstCellVisiblePortion;
    }
  }
  return [snappedX, snappedY]
}

export const getPreviewTransformStyle = (
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  isSnapToGrid: boolean,
  calendarDimensions: IDragAndDropPreviewCalendarDimensions | null,
  shouldMoveOnXAxis: boolean = true,
  permanentPositionX?: number,
): IGetItemStyles => {
  if (!initialOffset || !currentOffset) {
    return {
      style: { display: 'none' },
    }
  }
  let { x, y } = currentOffset

  let transform = getTranslateString(x, y);

  if (isSnapToGrid && calendarDimensions) {
    const [snappedX, snappedY] = snapToGrid(x, y, calendarDimensions);
 
    transform = getTranslateString(snappedX, snappedY);
  }

  if(!shouldMoveOnXAxis && permanentPositionX){
    transform = getTranslateString(permanentPositionX, y);;
  }

  return {
    style: {
      transform,
      WebkitTransform: transform
    },
    snapY: y
  }
}

export const getStyleForDraggingPreview = (DraggableItem: Element | null | undefined, leftPosition: number | null): IPreviewStyleForDestinationPreview => {
  if (!DraggableItem) return {};
  const computedStyles = getComputedStyle(DraggableItem);
  return {
    height: computedStyles.height,
    width: computedStyles.width,
    backgroundColor: computedStyles.backgroundColor,
    border: computedStyles.border,
    borderRadius: computedStyles.borderRadius,
    boxShadow: computedStyles.boxShadow,
    zIndex: '9999',
    left: leftPosition || 0,
  }
}