import { FunctionComponent, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { OnBoardingLayout } from '../../../shared/components/on-boarding-layout/onBoardingLayout';
import './AuthLayout.scss';
import { useKeyboard } from '../../../shared/hooks/useKeyboard';

export const AuthLayout: FunctionComponent = () => {
  const {isKeyboardOpen} = useKeyboard();

  useEffect(() => { 
    if (!isKeyboardOpen) {
        document.getElementById("login-with-email-input")?.blur();
        document.getElementById("app-phone-control-input")?.blur();
        document.getElementById("otp-input")?.blur();
        document.getElementById("user-phone-input")?.blur();
    }
}, [isKeyboardOpen]);

  return (
    <div className="auth-layout">
      <OnBoardingLayout>
        <Outlet />
      </OnBoardingLayout>
    </div>
  )
}