import React, { FunctionComponent } from 'react'
import TasksListSingleItem from '../TasksListSingleItem';
import SwipeableItemWrapper from '../../../../../../../../shared/components/swipeable-item-wrapper/SwipeableItemWrapper';
import { taskActionOverlaysPositionStyle } from '../../../../../../resizable-container/stage-container/stage-scratchpad/scratchpad-task-list/ScratchpadTaskList.utils';
import TaskDueActionButton from '../task-action-buttons/TaskDueActionButton';
import TaskScheduleActionButton from '../task-action-buttons/TaskScheduleActionButton';
import { ISingleTaskItemContainerChildrenProps } from '../SingleTaskItemContainer';
import sassVariables from '../singleTaskItemContainer.module.scss';
import './SwipeableSingleTaskItemMobile.scss';
import { isMobileDevice } from '../../../../../../../../shared/utils/isMobileDevice';
import TaskDeleteActionButton from '../task-action-buttons/TaskDeleteActionButton';
import { ETaskStatus } from '../../../../../../resizable-container/stage-container/stage-tasks/stageTasks.interface';

interface ISwipeableElement{
  itemWidth: number;
  element: JSX.Element;
}

const SwipeableSingleTaskItemMobile: FunctionComponent<ISingleTaskItemContainerChildrenProps> = ({ ...props }) => {
  const isMobile = isMobileDevice();

  let leftSideSwipeableElements:ISwipeableElement[] = [];

  if(props.singleTask.status === ETaskStatus.SCRATCHPAD){
    leftSideSwipeableElements = [...leftSideSwipeableElements, {
      itemWidth: Number(sassVariables.singleTaskActionButtonWidth),
      element:
        <TaskDeleteActionButton
          task={props.singleTask}
          onUpdateTask={props.onUpdateTask}
        />
    } ]
  }

  const rightSideSwipeableElements = [
    {
      itemWidth: Number(sassVariables.singleTaskActionButtonWidth),
      element:
        <TaskDueActionButton
          task={props.singleTask}
          onUpdateTask={props.onUpdateTask}
          actionOverlaysPositionStyle={taskActionOverlaysPositionStyle}
        />
    },
    {
      itemWidth: Number(sassVariables.singleTaskActionButtonWidth),
      element:
        <TaskScheduleActionButton
          task={props.singleTask}
          onUpdateTask={props.onUpdateTask}
          actionOverlaysPositionStyle={taskActionOverlaysPositionStyle}
        />
    }
  ]

  return (
    <div className={`swipeable-task-item ${isMobile ? 'swipeable-task-item--mobile' : ''}`}>
      <SwipeableItemWrapper
        rangeLimitedSwipeRight={Number(sassVariables.singleTaskActionButtonWidth) * leftSideSwipeableElements.length}
        rangeLimitedSwipeLeft={Number(sassVariables.singleTaskActionButtonWidth) * rightSideSwipeableElements.length}
        translateXCalcType='px'
        shouldReturnToStartWhenEndSwipe={false}
        itemHeightInPx={Number(sassVariables.singleTaskHight.replace('px',''))}
        swipeableItemElement={<TasksListSingleItem {...props} testid="task-mobile"/>}
        leftSideElements={leftSideSwipeableElements}
        rightSideElements={rightSideSwipeableElements}
      />
    </div>
  )
}

export default SwipeableSingleTaskItemMobile;
