import { useState } from 'react';
import './InviteFriendsCard.scss';
import { InviteFriends } from './invite-friend/InviteFriends';
import { CopyLink } from './copy-link/CopyLink';

export const InviteFriendsCard = () => {
  const [shouldDisplayShareLink, setShouldDisplayShareLink] = useState(false);

  return (
    <section className={`invite-friends-card-container invite-friends-card-container${shouldDisplayShareLink ? '--copy' :'--invite'}`}>
      {shouldDisplayShareLink ? <CopyLink /> : <InviteFriends setShouldDisplayShareLink={setShouldDisplayShareLink}/>}
    </section>
  )
}