import { useCallback, FunctionComponent, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useLocalStorage } from '../../../shared/utils/useLocalStorage';
import { appendNewMessageResetOrFocusArg, IChatForm, IChatMessage } from '../chat.interfaces';
import { chatInputId, chatSessionIdLocalStorageKey, dislikeFeedbackParam, settingsMenuParam, stageParam, userInfoLocalStorageKey, turnsCounterStorageKey, sendUserActivationConversionEvent, aiToolNames } from '../../../app/constants';
import { store, useAppDispatch, useAppSelector } from '../../../app/store';
import { setPrepopulatedOptions, setSelectedOptionIndex } from '../chat.store';
import { useApiData } from '../../../shared/hooks/useApiData';
import { EAPIStatus, IAPIError } from '../../../shared/api/models';
import { FORBIDDEN, UNAUTHORIZED } from '../../../shared/api/axios';
import { ContentFrameWrapper } from '../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { LottieAppLoader } from '../../../shared/components/lottie-loader/LottieLoader';
import { ChatConversation } from './chat-conversation/ChatConversation';
import { isDesktopView, isMobileView, onResizeTextareaHeightByTheContext } from '../../../shared/utils/utils';
import { SessionSummariesWrapper } from './chat-history/SessionSummariesWrapper';
import { isDebugModeAllowed } from '../../../shared/utils/isDebugModeAllowed';
import { getItemFromLocalStorage } from '../../../shared/utils/localStorage.utils';
import StageContainer from '../resizable-container/stage-container/StageContainer';
import { ChatFormUserInput } from './chat-form-user-input/ChatFormUserInput';
import { getTasksListReqAction, setUpdatedTasksViaTheChat } from '../resizable-container/stage-container/stage-tasks/stageTasks.store';
import { EFeedbackSurveyType, getPreviousUserOrBotMessageTimestamp, handleAppSurvey, handleMobileAppAwareness, shouldDisplayTime, transformResponseMessageToChatMessage } from './Chat.utils';
import { IUser } from '../../../app/auth/auth.interfaces';
import { ETasksGroupsType } from '../resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { setScratchpadTaskUpdatedItem } from '../resizable-container/stage-container/stage-scratchpad/stageScratchpad.store';
import { handleShowDiscordToast, resetDiscordToastVariablesWithNewSession } from '../../../shared/components/app-pop-up-toast/utils/handleDiscordToast.util';
import createAppOverlayPopover from '../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import ProactiveSurveyPopup from '../../../shared/components/proactive-survey/ProactiveSurveyPopup';
import removeAppOverlayPopover from '../../../shared/components/app-overlay-popover/removeAppOverlayPopover';
import { ApplicationInsightsApi } from '../../../application-insights';
import { MobileAwarenessToast } from './popups/MobileAwarenessToast';
import { isMobileAppWebView } from '../../../mobile-application-utils';
import './Chat.scss';
import ChatHelpersLogic from '../ChatHelpersLogic';
import { useSearchParams } from 'react-router-dom';
import { setAIControl } from '../../ai-control-ux/AIControlUX.store';

export const Chat: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { botResponse, sessionResponse, selectedOptionIndex, prepopulatedOptions } = useAppSelector(store => store.chatReducer);
  const [sessionIdLocalStorage, setSessionIdLocalStorage] = useLocalStorage(chatSessionIdLocalStorageKey, '');
  const [userInfoLocalStorage,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const turnsCounterRef = useRef<number>(Number(getItemFromLocalStorage(turnsCounterStorageKey) || 0));
  const { shouldStageExpand, shouldOpenAddEditTaskFrom } = useAppSelector(store => store.StageTasksReducer);
  const { shouldDisplayProductTour } = useAppSelector(store => store.sharedStoreReducer);
  const shouldScrollToNewMessagesRef = useRef<boolean>(false);
  const shouldDisplayStageInsideTheChat = isMobileView();
  const [searchParams,] = useSearchParams();
  const chatForm = useFormContext<IChatForm>();
  // will be false on expand while the stage displayed in desktop
  // To prevent the input from displaying with an incorrect height after the user submits a new message in expanded mode through the stage container, 
  // we remove the chat input from the dom when the chat is not displayed on the screen and recreate the input form when the chat is displayed on the screen again.
  const [isChatDisplayedBaseOnAnimation, setIsChatDisplayedBaseOnAnimation] = useState<boolean>(true);
  const isDesktop = isDesktopView();

  const { fields, append, remove } = useFieldArray({
    control: chatForm.control,
    name: 'messagesArr',
  });

  const focusStudentInput = useCallback(() => {
    const params = new URLSearchParams(document.location.search);
    // focusing the student's input only if there is no open popups within the chat
    if (!!!params.get(settingsMenuParam) && !!!params.get(dislikeFeedbackParam)) {
      setTimeout(() => chatForm.setFocus('userMessage'), 0);
    };
  }, [chatForm]);

  const appendNewMessage = useCallback((messages: IChatMessage[], shouldResetOrFocusInputField?: appendNewMessageResetOrFocusArg) => {
    append(messages);
    if (shouldResetOrFocusInputField === 'reset') chatForm.resetField('userMessage');
  }, [append, chatForm])

  // set user text-box with the user message, and remove the user message from the list.
  const removeLastUserMessageFromChatWindow = () => {
    if (fields.length > 0 && fields[fields.length - 1].party === 'User') {
      chatForm.setValue('userMessage', fields[fields.length - 1].msg);
      onResizeTextareaHeightByTheContext(document.getElementById(chatInputId));
      focusStudentInput();
      remove(fields.length - 1);
    }
  }

  const updateTasksList = () => {
    dispatch(getTasksListReqAction()).unwrap().then((data) => {
      if (!!shouldOpenAddEditTaskFrom) dispatch(setUpdatedTasksViaTheChat(data.tasks));
    })
  }

  useApiData(botResponse, {
    // if call HumanStudentTurnSendInput API success -> save server response and display it to the user student
    onFulfilled(botResponseData) {
      if (!botResponseData || botResponseData.length === 0) return;
      const { localHistoryMessages, summariesGroupsObj } = store.getState().chatReducer;
      const { totalTasksForUser } = store.getState().StageTasksReducer;
      const existingUserActivity = localHistoryMessages.find(message => (message.msgSequenceNumber || 0) > 1);
      const firstUserActivityInCurrentSession = botResponseData.find(message => message.messageSequenceNumber === 2 || message.messageSequenceNumber === 3);
      if (!existingUserActivity) {
        if (firstUserActivityInCurrentSession) {
          sendUserActivationConversionEvent();
          try {
            ApplicationInsightsApi.trackEvent('FirstUserActivity', { history: JSON.stringify(localHistoryMessages), botResponse: JSON.stringify(botResponseData) });
          } catch (error) {
            ApplicationInsightsApi.trackException(error);
          }
        }
      }
      if (firstUserActivityInCurrentSession && !isMobileAppWebView()) {
        handleMobileAppAwareness(triggerMobileAwareness);
      }
      const lastBotResponse = botResponseData[botResponseData.length - 1]
      const toolName = lastBotResponse?.tool?.name;
      
      if (lastBotResponse.tool && toolName === aiToolNames.XU_ENTER_FORM) {
        dispatch(setAIControl(lastBotResponse.tool));
      }

      if (lastBotResponse.text.length === 0) return;
      if (selectedOptionIndex !== null) {
        const userMessage: IChatMessage = {
          party: 'User',
          msg: prepopulatedOptions ? prepopulatedOptions[selectedOptionIndex]?.text : '',
          messageTime: Date.now(),
          creationTime: new Date().toISOString(),
          shouldDisplayTime: shouldDisplayTime(Date.now(), fields.length > 0 ? fields[fields.length - 1].messageTime : null),
          sessionId: sessionResponse?.data?.sessionId || sessionIdLocalStorage
        }
        // append the selected option as user message
        append(userMessage);
        dispatch(setSelectedOptionIndex(null));
      }

      // update stage area tasks list when botResponse include 'data' property
      if (lastBotResponse.data) {
        // get current tool name and check if the scratchpad tab is open
        const isScratchpadOpen = new URLSearchParams(window.location.search).get(stageParam);
        // extract card content and check if one of the updated tasks was a scratchpad task that is currently displayed in the scratchpad tab
        // pass the handling to the ScratchpadTaskList component if a scratchpad task was modified
        if ((toolName === aiToolNames.UPDATE_TASK || toolName === aiToolNames.DELETE_TASK) && isScratchpadOpen) {
          const responseContent = lastBotResponse.data?.content;
          const createdOrUpdatedTasks = Array.isArray(responseContent) ? responseContent : [responseContent];
          const taskListGroups = store.getState().StageTasksReducer.tasksOrderedByGroups || { [ETasksGroupsType.SCRATCHPAD]: [] };
          const existingScratchpadTasks = taskListGroups[ETasksGroupsType.SCRATCHPAD];
          const modifiedScratchPadTask = createdOrUpdatedTasks.find(updated => existingScratchpadTasks.find(existing => updated!.id === existing.id));
          if (modifiedScratchPadTask) {
            // Scratchpad task was modified, no need to update tasks list, this will be handled in the ScratchpadTaskList component
            dispatch(setScratchpadTaskUpdatedItem(modifiedScratchPadTask));
          } else {
            updateTasksList();
          }
        } else {
          updateTasksList();
        }
      }

      let newMessages: IChatMessage[] = [];
      const lastResponseMessage = { ...lastBotResponse };
      // const lastChatMessage = fields[fields.length - 1];
      // if (lastChatMessage?.party === 'Bot' && lastChatMessage?.tool && lastChatMessage.tool.name.includes("[UX_") && lastChatMessage?.party === 'Bot') {
      //   dispatch(hideChatMessage(lastChatMessage.msgId || ''));
      // }
      // Find the last message's timestamp
      const lastFieldsMessageTimestamp = getPreviousUserOrBotMessageTimestamp(fields, fields.length);
      if (isDebugModeAllowed()) {
        newMessages = transformResponseMessageToChatMessage(botResponseData.filter(item => item.party !== 'User'), lastFieldsMessageTimestamp, 'chatField');
      }
      else {
        const messages = transformResponseMessageToChatMessage([lastResponseMessage], lastFieldsMessageTimestamp, 'chatField');
        if (messages.length > 0) newMessages.push(messages[0]);
      }
      // when the user is not in chat only mode and new message is entered
      if (shouldDisplayStageInsideTheChat || shouldStageExpand) shouldScrollToNewMessagesRef.current = true;

      // Append all messages at once 
      appendNewMessage(newMessages);

      // TODO: Grouping all the actions related to the user to the user.store and use one true redux user state instead of localStorage     
      // Handle AppSurvey display
      handleAppSurvey(summariesGroupsObj, localHistoryMessages, chatForm.getValues('messagesArr'), totalTasksForUser, triggerSurveyOverlay);

      // After processing all messages, check if the last message has options
      dispatch(setPrepopulatedOptions(lastResponseMessage?.options || null));

      handleShowDiscordToast(newMessages, turnsCounterRef);
    },
    onRejected(error: IAPIError) {
      if (error.code !== FORBIDDEN && error.code !== UNAUTHORIZED) {
        removeLastUserMessageFromChatWindow();
        dispatch(setSelectedOptionIndex(null));
      }
    }
  })

  useApiData(sessionResponse, {
    onFulfilled: (createSessionResponse) => {
      setSessionIdLocalStorage(createSessionResponse.sessionId);
      if (createSessionResponse?.messages && createSessionResponse?.messages[createSessionResponse?.messages.length - 1].text) {
        let newMessages: IChatMessage[] = [];
        const lastResponseMessage = createSessionResponse?.messages[createSessionResponse?.messages.length - 1];
        if (isDebugModeAllowed()) {
          newMessages = transformResponseMessageToChatMessage(createSessionResponse?.messages, null, 'chatField');
        }
        else {
          const messages = transformResponseMessageToChatMessage([lastResponseMessage], null, 'chatField');
          if (messages.length > 0) newMessages.push(messages[0]);
        }

        // Append all messages at once 
        appendNewMessage(newMessages);
        dispatch(setPrepopulatedOptions(lastResponseMessage?.options || null));

        resetDiscordToastVariablesWithNewSession(turnsCounterRef);
      }
      // if there is no bot greeting - insert all the messages that belong to the current session in to the fields array
      else if (createSessionResponse.history.length > 0) {
        appendNewMessage(transformResponseMessageToChatMessage(createSessionResponse.history.filter(historyItem => historyItem.sessionId === createSessionResponse.sessionId), null));

      }
    }
  })

  const triggerSurveyOverlay = (surveyType: EFeedbackSurveyType) => {
    createAppOverlayPopover(
      <ProactiveSurveyPopup surveyType={surveyType} onClose={() => removeAppOverlayPopover()} />,
      null,
      null,
      undefined,
      { isAppToast: true } // Pass config object
    );
  };

  const triggerMobileAwareness = () => {
    createAppOverlayPopover(
      <MobileAwarenessToast />,
      null,
      null,
      undefined,
      { isAppToast: true }
    );
  }

  return (
    <div
      className={`chat-container`}
      data-testid="chat-window-container"
      id='chat-window-container'
      // when ending the hide chat animation, hide the chat-form input
      onAnimationEnd={({ animationName }) => {
        if (isDesktop && ["chatFromCollapseToExpandAnimation", "stageFromExpendToChatViewAnimation"].includes(animationName)) {
          setIsChatDisplayedBaseOnAnimation(false);
        }
      }}
      // when starting the display chat animation, display the chat-form input
      onAnimationStart={({ animationName }) => {
        if (isDesktop && ["chatFromExpandToCollapseAnimation", "fadeInAnimation"].includes(animationName)) {
          setIsChatDisplayedBaseOnAnimation(true);
        }
      }}
    >
      {isDebugModeAllowed() && <div className='debug-user-card'>userId: {userInfoLocalStorage?.id || ""},<br /><br />sessionId: {sessionIdLocalStorage}</div>}
      <ContentFrameWrapper shouldDisplayHeader={true} className='chat-frame'>
        <div id="chat-main" className="chat">
          {/* if Get sessionId API request that called in the background is in pending show loader, else show chat conversation */}
          {(!shouldDisplayProductTour && (sessionResponse.status === EAPIStatus.PENDING || sessionResponse.status === EAPIStatus.IDLE ))
            ? <LottieAppLoader testId='chat-lottie-loader' />
            : <>
              {shouldDisplayStageInsideTheChat && <StageContainer />}
              <>
                <ChatConversation fields={fields} shouldScrollToNewMessagesRef={shouldScrollToNewMessagesRef} /></>
                {!!sessionResponse.data && <ChatHelpersLogic append={append}/>}
              </>
          }
          {/* remove the chat input from the dom when the chat is not displayed on the screen and recreate the input form when the chat is displayed on the screen again. */}
          {(!searchParams.get(stageParam) || isChatDisplayedBaseOnAnimation) &&
            <ChatFormUserInput resizeInputByContentDelay={120} />
          }
          <SessionSummariesWrapper />
        </div>
      </ContentFrameWrapper>
    </div>
  )
}