import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import { userInfoLocalStorageKey, userPhoneCountryTwoLetterCodeLocalStorageKey, userPhoneNumberLocalStorageKey } from '../../../../app/constants';
import { IUser } from '../../../../app/auth/auth.interfaces';
import { useTranslation } from 'react-i18next';
import { loginReqAction, sendOtpReqAction, verifyOtpReqAction } from '../../../../app/auth/auth.store';
import { ApplicationInsightsApi } from '../../../../application-insights';
import { useApiData } from '../../../hooks/useApiData';
import phoneMascotImg from '../../../../assets/images/mascot/phone-mascot.png';
import OtpInput from '../../otp-input/OtpInput';
import './PhoneVerificationByOtp.scss';

interface IPhoneVerificationByOtpProps {
  isLoginWithPhoneFlow?: boolean;
  onFulfilledVerifyOtp?: (phoneNumber: string) => void;
  submitText?: string;
  className?: string;
  trackEventName: string;
}

const PhoneVerificationByOtp = ({ onFulfilledVerifyOtp, isLoginWithPhoneFlow, submitText, className = '',trackEventName }: IPhoneVerificationByOtpProps) => {
  const { control, handleSubmit, setFocus, formState, reset } = useForm<{ otp: string }>({
    defaultValues: { otp: '' }
  });
  const { verifyOtpRes } = useAppSelector(store => store.authReducer);
  const [phoneNumber,] = useLocalStorage<string | null>(userPhoneNumberLocalStorageKey, null);
  const [, setUserInfo] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const [phoneCountryTwoLetterCode,] = useLocalStorage<string | null>(userPhoneCountryTwoLetterCodeLocalStorageKey, null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const resetOtpTimer = useRef<NodeJS.Timeout | null>(null);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);

  useEffect(() => {
    // set auto focus on the otp input
    setFocus('otp');

    return () => {
      // clear Timeout when the component is destroyed
      if (resetOtpTimer.current) clearTimeout(resetOtpTimer.current);
    }
  }, [setFocus]);

  const onSubmit = (formData: { otp: string }) => {
    if (isLoginWithPhoneFlow) {
      const payload = {
        accessToken: formData.otp, 
        redirectUri: "", 
        error: "", 
        phoneNumber: phoneNumber
      }
      dispatch(loginReqAction({ payload: {...payload}, loginType: "phone" }))
        .unwrap()
        .then((data) => {
          ApplicationInsightsApi.trackEvent(trackEventName, {request: {...payload}, response: {...data}});
        })
        .catch(e => {
          ApplicationInsightsApi.trackException(`${trackEventName} failed with error: ${e}`);
          setShouldDisplayError(true);
          resetOtpInput();
        });
    } else {
      if (phoneNumber) {
        dispatch(verifyOtpReqAction({ phoneNumber: `${phoneNumber}`, otp: formData.otp, phoneCountryTwoLetterCode: `${phoneCountryTwoLetterCode}` }))
          .unwrap().then((data) => {
            setUserInfo(data);
            if (onFulfilledVerifyOtp) onFulfilledVerifyOtp(phoneNumber);
          });
      }
    }
  }

  useApiData(verifyOtpRes, {
    // reset otp input when verification API rejected
    onRejected() {
      setShouldDisplayError(true);
      resetOtpInput();
    },
  });

  const resetOtpInput = () => {
    if (resetOtpTimer.current) clearTimeout(resetOtpTimer.current);
    resetOtpTimer.current = setTimeout(() => {
      setFocus('otp');
      reset({ 'otp': '' });
    }, 500);
  }

  const resendCode = () => {
    dispatch(sendOtpReqAction({ phoneNumber: `${phoneNumber}` }));
    setShouldDisplayError(false);
  }

  return (
    <form className={`scroll-y-container-hidden-scroll-bar phone-verification-flow phone-verification-form ${className}`} id="phone-verification-form" onSubmit={handleSubmit(onSubmit)} data-testid="phone-verification-form">
      <div className='phone-verification-form-content'>
        <div className='title-container'>
          <img src={phoneMascotImg} alt="mascot" className='mascot' />
          <h1 className='title'>{t('phoneVerificationScreenTitleNewFlow')}</h1>
        </div>
        <p className='phone-verification-text'>{t("phoneVerificationTextNewFlow")}</p>
        <p className='phone-verification-text phone-number-to-verify'>{phoneNumber}</p>
        <p className='phone-verification-enter-code'>{t("phoneVerificationEnterCodeText")}</p>
        <div className="registration-input-container">
          <Controller
            name='otp'
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <OtpInput
                value={value}
                onChange={onChange}
              />
            )}
          />
          {shouldDisplayError && <small className='error' data-testid="register-error">{t("phoneVerificationPhoneNumberError")}</small>}
        </div>
        {!isLoginWithPhoneFlow && <p className='phone-verification-resend-code'>{t("phoneVerificationResendCodeText")} &nbsp;<span onClick={resendCode}>{t("phoneVerificationResendNow")}</span></p>}
      </div>
      <div className="next-container">
        <button className='auth-next-btn submit' form="phone-verification-form" id="phone-verification-form-submit" data-testid="phone-verification-form-submit" type='submit' disabled={!formState.isValid || formState.isSubmitted} >{submitText || t('submit')}</button>
      </div>
    </form>
  )
}

export default PhoneVerificationByOtp