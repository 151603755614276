import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form';
import EditTaskNotesModal from './EditTaskNotesModal';
import { IAddEditTaskForm, ITaskFields } from '../../stageTasks.interface';
import { useTranslation } from 'react-i18next';
import { isMobileView } from '../../../../../../../shared/utils/utils';
import { useSearchParams } from 'react-router-dom';
import { AppRichText } from '../../../../../../../shared/components/app-rich-text/AppRichText';

interface ITaskNotesProps {
  onUpdateTaskTextarea: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
  isReadOnly: boolean;
}

const TaskNotes = ({ onUpdateTaskTextarea, isReadOnly }: ITaskNotesProps) => {
  const [shouldOpenEditNotesModal, setShouldOpenEditNotesModal] = useState(false);
  const taskForm = useFormContext<IAddEditTaskForm>();
  const notesWatch = taskForm.watch("notes");
  const [, setSearchParams] = useSearchParams();
  const editNotesModalSearchParam = "notesModal";
  const { t } = useTranslation();

  const onCloseNotesEditModal = () => {
    onUpdateTaskTextarea({ notes: notesWatch }, false);
    setShouldOpenEditNotesModal(false);
    setSearchParams(prev => {
      prev.delete(editNotesModalSearchParam);
      return prev;
    })
  }

  const onOpenEditNotesModal = () => {
    setShouldOpenEditNotesModal(true);
    setSearchParams(prev => {
      prev.append(editNotesModalSearchParam, 'open');
      return prev;
    });
  }

  const onBlur = (value: string) => {
    taskForm.setValue("notes", value);
    onUpdateTaskTextarea({ notes: value });
  }

  return (
    <>
        <div
          className={`${!notesWatch ? isReadOnly ? 'd-none' : 'placeholder' : ''}`}
          onClick={() => isMobileView() && (!isReadOnly || !!notesWatch) && onOpenEditNotesModal()}>

          <AppRichText
            id="task-notes"
            className={`task-textarea task-notes ${isReadOnly && !notesWatch ? 'd-none' : ''}`}
            placeholder={t("addEditTaskNotesPlaceholder")}
            onBlur={(value: string) => { if (value && value !== notesWatch) onBlur(value) }}
            initialValue={notesWatch}
            disabled={isReadOnly || isMobileView()}
          />
        </div>
      
      {shouldOpenEditNotesModal &&
        <EditTaskNotesModal onCloseModal={onCloseNotesEditModal} isReadOnly={isReadOnly} onBlur={onBlur} />
      }
    </>
  )
}

export default TaskNotes
