import { EDragAndDropType } from "../../../../shared/utils/utils"
import { IDragItem } from "./CalendarDragAndDrop.util"

export interface IUpdateDragEventApiRequestFuncArgs {
  dragItem: IDragItem;
  dropType: EDragAndDropType;
  updatedWorkTime: Date | null;
  relatedWorkBlockId?: string;
  relatedWorkBlockOrder?: number;
  relatedWorkBlockInstance?: Date | null;
}

export enum ECalendarEventDurationTypeClassName {
  SHORTER_THAN_30_DURATION  = '30',
  OVER_30_DURATION = 'over-30'
}