import dayjs from "dayjs";
import { getDateFormatted, getTimeRange } from "../../features/chat-wrapper/chat/chat-conversation/message-data-card/tasks-list/TasksList.utils";

export enum EDayjsFormatter {
  FullYear = "YYYY",
  FullMonthWord = "MMMM",
  PartOfMonthWord = "MMM",
  MonthNumberWithZero = "MM",
  MonthNumberWithoutZero = "M",
  DayOfMonthNumberWithoutZero = 'D',
  DayOfMonthNumberWithOrdinalIndicator = 'Do',
  DayOfMonthNumberWithZero = 'DD',
  DayOfWeekNumber = 'd',
  DayOfWeekWord = 'dddd',
  PartOfDayOfWeekWord = 'ddd',
  TimeInAmPm = "h:mmA",
  HourInAmPm = "hA"
}

export const getTimeByDate = (date: string|number|Date, shouldIncludeMinutes:boolean = true): string => {
  try {
    return dayjs(new Date(date)).format(`${shouldIncludeMinutes ? EDayjsFormatter.TimeInAmPm : EDayjsFormatter.HourInAmPm}`)
  } catch (error) {
    return '';
  }
}

export const getDateWithYear = (date: string|number, showOrdinal:boolean=false, showDayOfTheWeek:boolean=true): string => {
  try {
    return dayjs(new Date(date)).format(`${showDayOfTheWeek ? EDayjsFormatter.PartOfDayOfWeekWord+', ' : ''}${EDayjsFormatter.PartOfMonthWord} ${showOrdinal ? EDayjsFormatter.DayOfMonthNumberWithOrdinalIndicator : EDayjsFormatter.DayOfMonthNumberWithoutZero}, ${EDayjsFormatter.FullYear}`)
  } catch (error) {
    return '';
  }
}

export const getDateWithoutYear = (date: string|number|Date, showOrdinal:boolean=false, showShortWords:boolean=true): string => {
  try {
    return dayjs(new Date(date)).format(`${showShortWords ? EDayjsFormatter.PartOfDayOfWeekWord : EDayjsFormatter.DayOfWeekWord+','} ${showShortWords ? EDayjsFormatter.PartOfMonthWord : EDayjsFormatter.FullMonthWord} ${showOrdinal ? EDayjsFormatter.DayOfMonthNumberWithOrdinalIndicator : EDayjsFormatter.DayOfMonthNumberWithoutZero}`)
  } catch (error) {
    return '';
  }
}

export const getDateAndTimeWithoutYear = (date: string|number|Date, showOrdinal:boolean=false, showShortWords:boolean=true): string => {
  try {
    return `${getDateWithoutYear(date,showOrdinal,showShortWords)}, ${getTimeByDate(date)}`;
  } catch (error) {
    return '';
  }
}

export const getDateAndTimeWithoutYearAndWithoutDay = (date: string|number|Date): string => {
  try {
    return dayjs(new Date(date)).format(`${EDayjsFormatter.PartOfMonthWord} ${EDayjsFormatter.DayOfMonthNumberWithoutZero}`) + ' ' + getTimeByDate(date);
  } catch (error) {
    return '';
  }
}

export const addDaysToDate = (date: Date | string, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result
}

export const getDateAndTimeWithoutYearAndWithoutDayWithoutTime = (date: string|number|Date): string => {
  try {
    return dayjs(new Date(date)).format(`${EDayjsFormatter.PartOfMonthWord} ${EDayjsFormatter.DayOfMonthNumberWithoutZero}`);
  } catch (error) {
    return '';
  }
}

export const getDateAndTimeWithoutYearWithoutTime = (date: string|number|Date): string => {
  try {
    return dayjs(new Date(date)).format(`${EDayjsFormatter.PartOfMonthWord} ${EDayjsFormatter.DayOfMonthNumberWithoutZero}`);
  } catch (error) {
    return '';
  }
}

// get the name of the day
export const getDayName = (dayIndex: number, fullWord: boolean = false) => {
  try {
    return dayjs().weekday(dayIndex).format(fullWord ? EDayjsFormatter.DayOfWeekWord : EDayjsFormatter.PartOfDayOfWeekWord);
  } catch (error) {
    return '';
  }
}

// get the day index(0-6) of a specific date 
export const getDayIndex = (date?: Date): number => {
  try{
    if(!!date) return dayjs(new Date(date)).day();
    return 0;
  }catch (error) {
    return 0;
  }
};

// day index 0-6, week offset 0 = current week etc...
export const getDateBasedOnDayIndexAndWeekOffset = (dayIndex: number, weekOffset: number): Date => {
  try {
    return dayjs().weekday(dayIndex).add(weekOffset, 'week').toDate();
  } catch (error) {
    return new Date();
  }
}

export const getDateBasedOnDayOffset = (dayIndex: number): Date => {
  try {
    return dayjs().add(dayIndex, 'day').toDate();
  } catch (error) {
    return new Date();
  }
}

// short date with duration format
// returns Tue Sep 12 10:00AM or Tue Sep 12 10:00AM - 11:00AM
export const getShortDateWithDuration = (date: string|Date, duration?: number|null): string => {
  const start = new Date(date);
  return `${getDateFormatted(start)} ${getTimeRange(start, duration)}`.replaceAll(": ", " ");
}
