import React from 'react'
import {ReactComponent as ChartMascotSvgComponent} from "../../../../../../../assets/images/mascot/mascot_6_chart.svg";
import './WorkBlockDetailsEmptyTasksState.scss';
import { useTranslation } from 'react-i18next';

const WorkBlockDetailsEmptyTasksState = () => {
  const { t } = useTranslation();

  return (
    <div className='work-block-details-empty-tasks-state' data-testid="empty-tasks-state">
      <ChartMascotSvgComponent className='empty-tasks-state-mascot'/>
      <div className="empty-tasks-state-description static-string">{t("workBlockDetailsEmptyTasksStateText")}</div>
    </div>
  )
}

export default WorkBlockDetailsEmptyTasksState