import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../store"
import { getUserAppData } from "../useAppData/user.store";
import { LottieAppLoader } from "../../shared/components/lottie-loader/LottieLoader";
import { EAPIStatus } from "../../shared/api/models";
import { useLocalStorage } from "../../shared/utils/useLocalStorage";
import { userInfoLocalStorageKey } from "../constants";
import { setAppInsightsUserContext } from "../../application-insights";
import { setAppModalContentType } from "../../shared/components/app-modals/appModals.store";
import { EAppModalContentType } from "../../shared/components/app-modals/appModals.interfaces";
import './InitApp.scss';
import { IUser } from "../auth/auth.interfaces";

export const InitApp: React.FC<{ children: React.ReactNode[] | React.ReactNode }> = ({ children }) => {
    const { userAppData } = useAppSelector(store => store.userReducer);
    const dispatch = useAppDispatch();
    const componentShouldLoad = useRef(true);
    const [, setUserInfo] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);

    const initApp = useCallback(() => {
        dispatch(getUserAppData())
        .unwrap()
        .then(data => {
          const { user } = data;
          if (!!user) {
            setUserInfo(user);
            setAppInsightsUserContext() ;
          }
          if (!!data.releaseNotes) dispatch(setAppModalContentType(EAppModalContentType.RELEASE_NOTES));
        })
    }, [dispatch, setUserInfo]);

    useEffect(() => {
        if (componentShouldLoad.current) {
            componentShouldLoad.current = false;
            if (!userAppData?.data?.user?.isActive && userAppData.status !== EAPIStatus.PENDING) initApp();
        }
    }, [initApp, userAppData]);

    return (
        <>
            {!!userAppData?.data ? children : <div className="init-app"><LottieAppLoader testId='lottie-loader' /></div>}
        </>
    )
}