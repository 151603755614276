import { IUser } from "../../app/auth/auth.interfaces";
import { userInfoLocalStorageKey } from "../../app/constants";
import { ApplicationInsightsApi } from "../../application-insights";
import { EClientFlags } from "../components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces"
import { FlagUtils } from "./flagUtils";
import { getItemFromLocalStorage } from "./localStorage.utils"

export const isClientFlagOn = (flag: EClientFlags): boolean => {
    try
    {
        const user = getItemFromLocalStorage<IUser>(userInfoLocalStorageKey);
        const clientFlags = user?.clientFlags || 0;
        return FlagUtils.getFlag(clientFlags, flag);
    }
    catch (e)
    {
        ApplicationInsightsApi.trackException(e);
        return false;
    }
}