import React, { FunctionComponent } from 'react';
import './onBoardingLayout.scss';

export const OnBoardingLayout: FunctionComponent<{ children: React.ReactNode[] | React.ReactNode; className?: string }> = ({ children, className }) => {

  return (
    <div className={`on-boarding-layout on-boarding-bg ${className ? className : ''}`}>
      {children}
    </div>
  )
}