import { FunctionComponent, useEffect, useState } from 'react'
import { IChatMessage } from '../../chat.interfaces';
import Message from './Message';
import { useAppSelector } from '../../../../app/store';

export interface IMessageProps {
  msgItem: IChatMessage;
  hideMsgTime?: boolean;
  isToastMessage?: boolean;
}

const MessageWrapper: FunctionComponent<IMessageProps> = ({ msgItem, hideMsgTime = false , isToastMessage = false}) => {
  // don't display messages with empty text
  const {hideMessageId} = useAppSelector(store => store.chatReducer);
  const [hidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (msgItem.msgId === hideMessageId) {
      setIsHidden(true);
    }
  }, [hideMessageId, msgItem.msgId]);

  return (
    <>
      {((!hidden && !isToastMessage) || isToastMessage) && <Message msgItem={msgItem} hideMsgTime={hideMsgTime} /> }
    </>
  )
}

export default MessageWrapper
