import React, { FunctionComponent } from 'react'
import './OverlayTypeDetails.scss';

interface IOverlayTypeDetailsProps {
  icon: string;
  title: string;
  data: string | null;
  disabled?: boolean;
  addOpacity?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  className?: string;
}

const OverlayTypeDetails: FunctionComponent<IOverlayTypeDetailsProps> = ({ icon, title, data, disabled, addOpacity, onClick, className='' }) => {
  return (
    <div
      className={
        `task-edit-overlay-type-details-container 
        ${disabled ? 'task-edit-overlay-type-details-container--disabled' : ''} 
        ${addOpacity ? 'task-edit-overlay-type-details-container--opacity' : ''}
        ${className}
      `}
      onClick={onClick}
    >
      <div className='task-edit-overlay-type-title-and-icon-container static-string'><img src={icon} alt={title} />{title}</div>
      {!!data && <div className='task-edit-overlay-type-data'>{data}</div>}
    </div>
  )
}

export default OverlayTypeDetails
