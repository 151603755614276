import { useState, useRef, useCallback } from 'react';
import { calculateSwipeDelta, isThresholdExceeded, IDelta, SwipeStartPosition, ITranslate, ECloseSwipeDirection } from './swipe.utils';

interface IUseSwipeToCloseConfig {
  swipeDirection: ECloseSwipeDirection | 'any';
  threshold?: number;
  onSwipeEnd?: (direction: 'down' | 'left' | 'right') => void;
  onSwipeMove?: (delta: IDelta) => void; // Callback for swipe move
  shouldCalculateTranslate?: boolean; // Whether to calculate translate values
}

export const useSwipeToClose = ({
  swipeDirection,
  threshold = 20,
  onSwipeEnd,
  shouldCalculateTranslate=true,
  onSwipeMove
}: IUseSwipeToCloseConfig) => {
  const [translate, setTranslate] = useState<ITranslate | null>(null);
  const startPosition = useRef<SwipeStartPosition | null>(null);

  const resetTranslate = () => {
    if (startPosition.current)
      setTranslate(null);
  }

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    const touch = e.touches[0];
    startPosition.current = { x: touch.clientX, y: touch.clientY };
  }, []);

  const shouldOnSwipeMove = useCallback((delta: IDelta) => {
    const isSwipeDown =  swipeDirection === ECloseSwipeDirection.DOWN && isThresholdExceeded(delta.deltaY, threshold);
    const isSwipeRight = swipeDirection === ECloseSwipeDirection.RIGHT_AND_LEFT && delta.deltaX > 0 && isThresholdExceeded(delta.deltaX, threshold);
    const isSwipeLeft = swipeDirection === ECloseSwipeDirection.RIGHT_AND_LEFT && delta.deltaX < 0 && isThresholdExceeded(delta.deltaX, threshold);
    return isSwipeDown || isSwipeRight || isSwipeLeft;
  },[swipeDirection, threshold])

  const handleTouchMove = useCallback((e: React.TouchEvent) => {
    const touch = e.touches[0];
    const delta = calculateSwipeDelta(startPosition.current, touch);
    if (!delta) return;
    if (onSwipeMove && shouldOnSwipeMove(delta)) {
      onSwipeMove(delta)
    };
    if (!shouldCalculateTranslate) return;

    // calc the translate values
    const { deltaX, deltaY } = delta
    if (swipeDirection === ECloseSwipeDirection.DOWN && deltaY > 0) {
      setTranslate({ x: 0, y: deltaY });
    } else if (swipeDirection === ECloseSwipeDirection.RIGHT_AND_LEFT && deltaX !== 0) {
      setTranslate({ x: deltaX, y: 0 });
    } else if (swipeDirection === 'any') {
      setTranslate({ x: deltaX, y: deltaY });
    }
  }, [onSwipeMove, shouldCalculateTranslate, swipeDirection, shouldOnSwipeMove]);

  const handleTouchEnd = useCallback((_: React.TouchEvent) => {
    if (!translate) return;

    if (swipeDirection === ECloseSwipeDirection.DOWN && isThresholdExceeded(translate.y, threshold)) {
      onSwipeEnd?.('down');
    }
    else if (swipeDirection === ECloseSwipeDirection.RIGHT_AND_LEFT) {
      if (translate.x > 0 && isThresholdExceeded(translate.x, threshold)) {
        onSwipeEnd?.('right');
      } else if (translate.x < 0 && isThresholdExceeded(translate.x, threshold)) {
        onSwipeEnd?.('left');
      } else resetTranslate();
    }
    else resetTranslate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipeDirection, threshold, onSwipeEnd]);

  return {
    translate,
    setTranslate,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};
