import { ApplicationInsightsApi } from "./application-insights";

export enum EMobileAppMessage {
    TOKEN = 1,
    OPEN_URL = 2,
}

export interface IMobileAppMessage {
    type: EMobileAppMessage,
    data: unknown,
}

export interface IMobileAppRefreshTokenMessageData {
    token: string,
    refreshToken: string,
}


// mobile app webview injects messageHandler to the window object
// on ios devices, the handler is on window.webkit.messageHandlers.messageHandler
const sendMessageToMobileApp = (message: string) => {
    try {
        const currentWindow = window as any;
        if (!currentWindow || (!currentWindow.messageHandler && !currentWindow.webkit)) {
            return;
        }
        ApplicationInsightsApi.trackEvent("sendMessageToMobileApp - sending a message to the mobile app", { message });
        if (currentWindow.messageHandler) {
            currentWindow.messageHandler.postMessage(message);
        }
        else if (currentWindow.webkit && currentWindow.webkit.messageHandlers && currentWindow.webkit.messageHandlers.messageHandler) {
            currentWindow.webkit.messageHandler.messageHandler.postMessage(message);
        }
    }
    catch (e) {
        ApplicationInsightsApi.trackException(e);
    }
}

export const sendRefreshTokenToMobileApp = (data: IMobileAppRefreshTokenMessageData) => {
    const message = createMobileAppRequestPayload(data, EMobileAppMessage.TOKEN);
    if (!message) return;
    sendMessageToMobileApp(message);
}

export const sendRequestOpenUrlToMobileApp = (url: string) => {
    const message = createMobileAppRequestPayload(url, EMobileAppMessage.OPEN_URL);
    if (!message) return;
    sendMessageToMobileApp(message);
}

export const createMobileAppRequestPayload = (data: unknown, type: EMobileAppMessage): string | null => {
    try 
    {
         if (data == null || data === undefined) {

            return null;
        }

        const message: IMobileAppMessage = {
            type,
            data,
        };
        return JSON.stringify(message);
    }
    catch (e) {
        ApplicationInsightsApi.trackException(e);
        return null;
    }
}

export const isMobileAppWebView = (): boolean => {
    const currentWindow = window as any;
    if (!!currentWindow?.messageHandler || !!currentWindow?.webkit?.messageHandlers?.messageHandler) {
        return true;
    }
    return false;
}

export const openExternalLinkOnMobileApp = (e: React.MouseEvent<HTMLElement>, url: string | null | undefined) => {
    try{
        if (!isMobileAppWebView() || !url) return;
        e.preventDefault();
        sendRequestOpenUrlToMobileApp(url);
    }
    catch(error){
        ApplicationInsightsApi.trackException(error);
    }
}