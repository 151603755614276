import { useEffect, useState } from "react";

export const useWebsiteTabVisibility = () => {
  const [isVisible, setIsVisible] = useState(!document.hidden);
  const [lastVisibleTime, setLastVisibleTime] = useState(Date.now());

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setLastVisibleTime(Date.now());
        setIsVisible(false);
      } else {
          setIsVisible(true);
      }
    };
    
    // 'visibilitychange' event fires when the user switches tabs. 
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return { isVisible, lastVisibleTime };
};