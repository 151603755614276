import React, { useState, useEffect } from 'react';
import './InternalUserSettings.scss';
import DownloadSessionButton from '../download-session-button/DownloadSessionButton';
import { getFeatureFlags } from '../../../../utils/utils';
import { useAppDispatch } from "../../../../../app/store";
import { updateUserProfileReq } from '../settingsMenu.store';
import { userInfoLocalStorageKey } from '../../../../../app/constants';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { IUser } from '../../../../../app/auth/auth.interfaces';

interface InternalUserSettingsProps {
}

export const InternalUserSettings: React.FC<InternalUserSettingsProps> = () => {
  const [flagsJson, setFlagsJson] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const [userInfo,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);

  useEffect(() => {
    const flags = getFeatureFlags();
    setFlagsJson(JSON.stringify(flags, null, 2)); // Format JSON with indentation
  }, []);

  const handleSave = async () => {
    try {
      const updatedFlags = JSON.parse(flagsJson);

      if (typeof updatedFlags !== 'object' || Array.isArray(updatedFlags)) {
        throw new Error('Invalid JSON structure: Expected an object.');
      }
    } catch (err) {
      setFlagsJson(JSON.stringify(userInfo?.featureFlags ?? {}, null, 2)); // Rollback
      setError('Failed to update feature flags.');
    }

    try {
      await dispatch(updateUserProfileReq({ featureFlags: JSON.parse(flagsJson) }));
    } catch (error) {
      const previousFeatureFlags = userInfo?.featureFlags ?? {};
      setFlagsJson(JSON.stringify(previousFeatureFlags, null, 2));
      setError('Failed to update feature flags. Changes were rolled back.');
    }
  };

  return (
    <div className="internal-user-settings">
      <h1>Internal User Settings</h1>
      <br />
      <DownloadSessionButton />
      <h3>Feature Flags</h3>
      <textarea
        value={flagsJson}
        onChange={(e) => setFlagsJson(e.target.value)}
        rows={10}
        className="feature-flags-textarea"
      />
      {error && <p className="error-message">{error}</p>}
      <button onClick={handleSave} className="save-feature-flags-button">
        Update Feature Flags
      </button>
    </div>
  );
};