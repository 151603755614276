import { FunctionComponent, useState, useRef, MutableRefObject, useEffect, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { DislikeFeedbackForm, EFeedbackType, IChatMessage, IFeedbackPayload } from '../../../chat.interfaces';
import { feedbackReqAction, resetFeedbackResponse } from '../../../chat.store';
import { dislikeFeedbackParam } from '../../../../../app/constants';
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import likeJson from '../../../../../shared/animation-json/LikeAnimation.json';
import dislikeJson from '../../../../../shared/animation-json/DislikeAnimation.json';
import { DislikeFeedback } from './dislike-feedback/DislikeFeedback';
import { EAPIStatus } from '../../../../../shared/api/models';
import { useForm, FormProvider } from 'react-hook-form';
import { FORBIDDEN, UNAUTHORIZED } from '../../../../../shared/api/axios';
import { useSearchParams } from 'react-router-dom';
import './Feedback.scss';

const Feedback: FunctionComponent<{ msgItem: IChatMessage; }> = ({ msgItem }) => {
  const { feedbackResponse } = useAppSelector(store => store.chatReducer);
  const dislikeLottieRef = useRef<LottieRefCurrentProps | null>(null);
  const likeLottieRef = useRef<LottieRefCurrentProps | null>(null);
  const [feedbackChecked, setFeedbackChecked] = useState<EFeedbackType | null>(null);
  const previousFeedbackCheck = useRef<EFeedbackType | null>(null);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const dislikeForm = useForm<DislikeFeedbackForm>({
    defaultValues: new DislikeFeedbackForm()
  });

  // this feedBackReqBody data is common to all types of requests.
  const feedBackReqBody: IFeedbackPayload = useMemo(() => {
    return {
      sessionId: msgItem.sessionId,
      messageId: msgItem.msgId || '',
      messageSequenceNumber: msgItem.msgSequenceNumber || msgItem.msgSequenceNumber === 0 ?  msgItem.msgSequenceNumber : -1,
      feedbackType: EFeedbackType.NONE,
    }
  }, [msgItem.msgId, msgItem.msgSequenceNumber, msgItem.sessionId])

  const handleLikeAnimation = useCallback(() => {
    animateFeedbackIcon(dislikeLottieRef, false);
    animateFeedbackIcon(likeLottieRef, true)
  }, [])

  const handleDislikeAnimation = useCallback(() => {
    animateFeedbackIcon(likeLottieRef, false);
    animateFeedbackIcon(dislikeLottieRef, true);
  }, [])

  const handleNoneAnimation = useCallback(() => {
    animateFeedbackIcon(dislikeLottieRef, false);
    animateFeedbackIcon(likeLottieRef, false);
  }, [])

  const handleResetForm = () => {
    dislikeForm.reset(new DislikeFeedbackForm());
  }

  const typeToAnimation = useMemo(() => {
    return {
      [EFeedbackType.LIKE]: handleLikeAnimation,
      [EFeedbackType.DISLIKE]: handleDislikeAnimation,
      [EFeedbackType.NONE]: handleNoneAnimation,
    }
  }, [handleDislikeAnimation, handleLikeAnimation, handleNoneAnimation])

  const handleFeedback = useCallback((feedbackType: EFeedbackType) => {
    // change feedbackType to the feedbackChecked state and add the feedbackTags and the feedbackText in the requestBody
    feedBackReqBody.feedbackType = feedbackChecked as EFeedbackType;
    feedBackReqBody.feedbackTags = dislikeForm.getValues('feedbackTags')
    feedBackReqBody.feedbackText = dislikeForm.getValues('feedbackText');
    // send a feedback request
    dispatch(feedbackReqAction(feedBackReqBody))
      .unwrap()
      .then(() => {
        // when feedback success start the animation
        typeToAnimation[feedbackType]();
      })
      .catch(error => {
        //  if feedback rejected revert the feedbackType state to the previous value
        if (error.code !== FORBIDDEN && error.code !== UNAUTHORIZED) {
          setFeedbackChecked(previousFeedbackCheck?.current);
        }
      });
  }, [feedBackReqBody, feedbackChecked, dislikeForm, dispatch, typeToAnimation])

  useEffect(() => {
    // return the useEffect when this is the first render - when feedbackChecked and previousFeedbackCheck.current are equal to null
    if (!feedbackChecked && !previousFeedbackCheck?.current) return;
    // return the useEffect when the current and the previous checked state are equal
    // to avoid from calling the handleFeedback again after the request failed and the feedbackChecked state change back to the previous value inside the catch() function.
    if (feedbackChecked === previousFeedbackCheck?.current) return;
    // when feedbackChecked state change - call the handleFeedback() function with the feedbackChecked value
    handleFeedback(feedbackChecked as EFeedbackType);
  }, [feedbackChecked, handleFeedback])

  const animateFeedbackIcon = (lottieRef: MutableRefObject<LottieRefCurrentProps | null>, isToBeChecked: boolean) => {
    if (isToBeChecked) lottieRef.current?.playSegments([], true);
    else lottieRef.current?.goToAndStop(0);
  }

  const handleLikeClick = () => {
    handleResetForm();
    setFeedbackChecked(feedbackChecked === EFeedbackType.LIKE ? EFeedbackType.NONE : EFeedbackType.LIKE)
  }

  const handleDislikeClick = () => {
    handleResetForm();
    // if 'dislike' is not checked yet - reset the feedbackResponse to avoid unneeded error (that connect to the 'like' response) inside the dislikeScreen and open the dislike screen
    if (feedbackChecked !== EFeedbackType.DISLIKE) {
      dispatch(resetFeedbackResponse());
      if (msgItem.msgId) {
        setSearchParams(prev => {
          prev.append(dislikeFeedbackParam, msgItem.msgId!);
          return prev;
        });
      }
    } else {
      // id 'dislike' is already checked set FeedbackChecked to 'none'
      setFeedbackChecked(EFeedbackType.NONE);
    }
  }

  return (
    <div className={`feedback-container`}>
      <Lottie
        lottieRef={likeLottieRef}
        animationData={likeJson}
        loop={false}
        onClick={() => handleLikeClick()}
        autoplay={false}
        className={`lottie-like-animation ${feedbackResponse.status === EAPIStatus.PENDING && 'disabled'}`}
        data-testid={`like-${msgItem.msgId}`}
      />
      <Lottie
        lottieRef={dislikeLottieRef}
        animationData={dislikeJson}
        loop={false}
        onClick={() => handleDislikeClick()}
        autoplay={false}
        className={`lottie-dislike-animation ${feedbackResponse.status === EAPIStatus.PENDING && 'disabled'}`}
        data-testid={`dislike-${msgItem.msgId}`}
      />
      {msgItem.msgId && !!searchParams.get(dislikeFeedbackParam)?.includes(msgItem.msgId) && <FormProvider {...dislikeForm}><DislikeFeedback msgItem={msgItem} onDislikeFeedbackSubmit={setFeedbackChecked} /> </FormProvider>}
    </div>
  )
}

export default Feedback;