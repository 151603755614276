
import { Fragment, FunctionComponent, MutableRefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { FieldArrayWithId, useFormContext } from "react-hook-form";
import { IChatForm, IHistoryMessage } from "../../chat.interfaces";
import ErrorMessage from "../message/error-message/ErrorMessage";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { EAPIStatus } from "../../../../shared/api/models";
import { FORBIDDEN, UNAUTHORIZED } from "../../../../shared/api/axios";
import Lottie from "lottie-react";
import messageLoadingAnimation from '../MessageLoadingAnimation.json';
import { CreateSessionErrorMessage } from "../message/create-session-error-message/CreateSessionErrorMessage";
import { getChatHistoryReq } from "../../chat.store";
import { numberOfMessagesToFetch } from "../../../../app/constants";
import { ApplicationInsightsApi } from "../../../../application-insights";
import { useMutationObserver } from "../../../../shared/hooks/useMutationObserver";
import PrepopulateOptionsList from "../message/prepopulate-options-message/PrepopulateOptionsList";
import { isDebugModeAllowed } from "../../../../shared/utils/isDebugModeAllowed";
import MessageDataCard from "./message-data-card/MessageDataCard";
import DebugMessageContent from "../message/debug-message-content/DebugMessageContent";
import { useTranslation } from "react-i18next";
import './ChatConversation.scss';
import MessageWrapper from "../message/MessageWrapper";

interface IChatConversationProps {
  fields: FieldArrayWithId<IChatForm, "messagesArr", "id">[];
  shouldScrollToNewMessagesRef: MutableRefObject<boolean>;
}

export const ChatConversation: FunctionComponent<IChatConversationProps> = ({ fields, shouldScrollToNewMessagesRef }) => {
  const { botResponse, feedbackResponse, sessionResponse, historyResponse, localHistoryMessages, prepopulatedOptions } = useAppSelector(store => store.chatReducer);
  const { shouldDisplayProductTour } = useAppSelector(store => store.sharedStoreReducer);
  const conversationRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const abortControllerRef = useRef(new AbortController());
  const [isMoreHistoryAvailable, setIsMoreHistoryAvailable] = useState(true);
  const [history, setHistory] = useState<IHistoryMessage[]>([]);
  const chatFormMessagesWrapperRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { watch } = useFormContext<IChatForm>();
  const shouldDisplayRecordingErrorMsg = watch('shouldDisplayRecordingErrorMsg');
  const conversationClientHeightOffset = 50;

  // with insert new message scroll to the bottom of the chat
  const scrollToTheLastMsg = useCallback(() => {
    if(conversationRef?.current) conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
  }, []);

  // Observe DOM changes of the chat messages wrapper. When a new message is inserted into the DOM, scroll down to the bottom of the chat.
  useMutationObserver(chatFormMessagesWrapperRef, scrollToTheLastMsg, shouldDisplayProductTour);

  useEffect(() => {
    if (!conversationRef.current) return;
    // when user exit chat-only mode the conversation height = 0, so we need to observe the height when it changed back (means the user return to chat-only mode)
    const resizeObserver = new ResizeObserver(() => {
      // When the user was not in chat-only mode and a new message was entered, scroll to the new message
      if(shouldScrollToNewMessagesRef?.current && conversationRef.current?.clientHeight && conversationRef.current?.clientHeight > 0) {
        scrollToTheLastMsg();
        shouldScrollToNewMessagesRef.current = false;
      }
    });
    resizeObserver.observe(conversationRef.current);
    return () => resizeObserver.disconnect(); // clean up 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[scrollToTheLastMsg])

  useLayoutEffect(() => {
    setHistory(localHistoryMessages);
  }, [localHistoryMessages]);

  useEffect(() => {
    const controller = new AbortController();
    abortControllerRef.current = controller;

    return () => {
      controller.abort();
      abortControllerRef.current.abort();
    };
  }, []);

  const isTheErrorNotForbiddenOrUnauthorized = (errorCode: number): boolean => {
    const errorCodesArr = [FORBIDDEN, UNAUTHORIZED];
    return !!!errorCodesArr.includes(errorCode);
  }

  const shouldDisplayBotResponseError = () => {
    return (
      botResponse.status === EAPIStatus.REJECTED &&
      isTheErrorNotForbiddenOrUnauthorized(botResponse.error?.code || 0)
    )
  }

  const shouldDisplayFeedbackError = () => {
    return (
      feedbackResponse.status === EAPIStatus.REJECTED &&
      isTheErrorNotForbiddenOrUnauthorized(feedbackResponse.error?.code || 0)
    )
  }

  const shouldDisplayError = () => {
    return (shouldDisplayBotResponseError() || shouldDisplayFeedbackError() || shouldDisplayRecordingErrorMsg);
  }

  const onChatScroll = () => {
    if (conversationRef.current) {
      const scrollHeight = conversationRef.current.scrollHeight;
      const scrollTop = conversationRef.current.scrollTop;
      if (scrollTop === 0 && conversationRef.current.scrollHeight > conversationRef.current.clientHeight+conversationClientHeightOffset) getChatHistory(scrollHeight);
    }
  }

  const getChatHistory = (prevConversationDivHeight: number) => {
    const [sessionId, msgSequenceNumber] = getOldestSessionIdAndMessageNumber();
    if (isMoreHistoryAvailable && ![EAPIStatus.PENDING].includes(historyResponse.status) && sessionId && msgSequenceNumber) {
      const historyParams = {
        sessionId: sessionId,
        currentMessageNumber: msgSequenceNumber
      }
      const historyQuery = {
        before: numberOfMessagesToFetch,
        after: 0
      }
      dispatch(getChatHistoryReq({ historyParams, historyQuery, signal: abortControllerRef.current.signal }))
        .unwrap()
        .then((historyData) => {
          // scroll to the position where the new messages are visible
          if (conversationRef.current) conversationRef.current.scrollTop = conversationRef.current.scrollHeight - prevConversationDivHeight
          if (historyData.length < numberOfMessagesToFetch) setIsMoreHistoryAvailable(false);
        })
        .catch((e) => {
          ApplicationInsightsApi.trackException(e);
        })
    }
  }

  const getOldestSessionIdAndMessageNumber = (): [string | null, number | null | undefined] => {
    const sessionId = history?.length && history[0] ? history[0].sessionId : fields && fields[0] ? fields[0].sessionId : null;
    const messageId = history?.length && history[0].msgSequenceNumber ? history[0].msgSequenceNumber : fields?.length && fields[0].msgSequenceNumber ? fields[0].msgSequenceNumber : null;
    return [sessionId, messageId]
  }

  const shouldDisplayBotResPendingDots = () => {
    return botResponse.status === EAPIStatus.PENDING;
  }

  const shouldShowScroller = useMemo(() => {
    // if there is more available history 
    // and the bot response padding dots are not displayed on the screen 
    // and the history req status is pending or if the user is close to the top but there is a scrollbar (meaning the entire scrollHeight is not equal to the conversation's clientHeight in the viewport)
    return isMoreHistoryAvailable && !shouldDisplayBotResPendingDots() && (historyResponse.status === EAPIStatus.PENDING || (conversationRef.current && conversationRef.current.scrollTop <= conversationRef.current.clientHeight && conversationRef.current.scrollHeight > conversationRef.current.clientHeight+conversationClientHeightOffset));
    // eslint-disable-next-line
  }, [historyResponse.status, conversationRef?.current?.scrollTop, isMoreHistoryAvailable])

  return (
    <div className={`conversation`} ref={conversationRef} data-testid="chat-conversation" onScroll={() => onChatScroll()} >
      {!shouldDisplayProductTour && <>
      {shouldShowScroller && <Lottie animationData={messageLoadingAnimation} loop={true} className='lottie-message-animation history-loading-animation' />}
      {history.length > 0 && <section className={`messages history-messages`}>
        {history.filter(m => !m.isHidden).map((msgItem, i) => {
          return (
            <Fragment key={msgItem.sessionId + msgItem.msgId}>
              {msgItem.debugInfo && isDebugModeAllowed() && <DebugMessageContent text={msgItem.debugInfo}/>}
              {msgItem.data ? <MessageDataCard msgData={msgItem.data} msgToolName={msgItem.tool?.name} /> : <></>}
              {msgItem.msg && <MessageWrapper msgItem={msgItem} />}
            </Fragment>
          )
        })}
      </section>}
      {history.length > 0 && <div className="separation" />}
      <section className="messages form-fields" ref={chatFormMessagesWrapperRef}>
        {fields.filter(m => !m.isHidden).map((msgItem, i) => {
          return (
            <Fragment key={i}>
              {msgItem.debugInfo && isDebugModeAllowed() && <DebugMessageContent text={msgItem.debugInfo}/>}
              {msgItem.data ? <MessageDataCard msgData={msgItem.data} msgToolName={msgItem.tool?.name} /> : <></>}
              {msgItem.msg && msgItem.party === 'Bot' &&
                <section className={`message-wrapper message-wrapper-type-${msgItem.party.toLocaleLowerCase()} message`}>
                  {<MessageWrapper msgItem={msgItem} />}
                </section>
              }
              {msgItem.msg && msgItem.party !== 'Bot' && <MessageWrapper msgItem={msgItem} />}
            </Fragment>
          )
        })}
        {/* if create session failed - show the CreateSessionErrorMessage component */}
        {sessionResponse.status === EAPIStatus.REJECTED && <CreateSessionErrorMessage />}
      </section>
      {prepopulatedOptions && prepopulatedOptions.length > 0 && <PrepopulateOptionsList />}
      {shouldDisplayError() && <ErrorMessage errorText={shouldDisplayRecordingErrorMsg ? t("transcribeErrorWhileRecording") : ""} />}
      {shouldDisplayBotResPendingDots() && <Lottie animationData={messageLoadingAnimation} loop={true} className='lottie-message-animation lottie-message-animation-in-chat bot-res-loading' />}
      </>}
      </div>
  )
}