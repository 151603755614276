import { useState, useRef, useEffect } from 'react';
import { isMobileDevice } from '../../../../../../shared/utils/isMobileDevice';
import './TaskEditOverlayDurationPicker.scss';
import TaskEditOverlayHeaderAndAction from './TaskEditOverlayHeaderAndAction';
import { useTranslation } from 'react-i18next';
import { EComponentStep } from './TaskEditOverlay.utils';
import { isMobileView } from '../../../../../../shared/utils/utils';
import AppButton from '../../../../../../shared/components/app-button/AppButton';

export interface ITasksEditOverlayDurationPickerProps {
    onChooseDuration: (duration: number) => void;
    handleSet: () => void;
    handleClear: () => void;
    handleDurationChange: (duration: number) => void;
    onClose: () => void;
    headerText: string;
    initialDuration?: number | null;
    isSubTask?: boolean;
    isShowHeader?: boolean;
}

export interface IDurationOption {
    name: string;
    value: number;
}

export const TaskEditOverlayDurationPicker = ({ onChooseDuration, handleSet, handleClear, handleDurationChange, headerText, initialDuration, onClose, isSubTask, isShowHeader = true }: ITasksEditOverlayDurationPickerProps) => {
    const [isMobile] = useState(isMobileDevice());
    const [componentStep, setComponentStep] = useState<EComponentStep>(initialDuration ? EComponentStep.CUSTOM_SELECTOR : EComponentStep.PRESET_OPTIONS);
    const options = [{ name: '15 Min', value: 15 * 60 }, { name: '30 Min', value: 30 * 60 }, { name: '1 Hour', value: 60 * 60 }];
    const [durationInSeconds, setDurationInSeconds] = useState(initialDuration);
    const [selectedHours, setSelectedHours] = useState(durationInSeconds ? Math.floor(durationInSeconds / 3600) : 0);
    const [selectedMinutes, setSelectedMinutes] = useState(durationInSeconds ? Math.floor((durationInSeconds % 3600) / 60) : 0);
    const selectHoursRef = useRef<HTMLSelectElement>(null);
    const selectMinutesRef = useRef<HTMLSelectElement>(null);
    const { t } = useTranslation();

    useEffect(() => {
        setDurationInSeconds(initialDuration);
        setSelectedHours(initialDuration ? Math.floor(initialDuration / 3600) : 0);
        setSelectedMinutes(initialDuration ? Math.floor((initialDuration % 3600) / 60) : 0);
    }, [initialDuration]);

    const handleChangeHours = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = Number(e.target.value);
        setSelectedHours(selectedValue);
        setDurationInSeconds(getSeconds(selectedValue, selectedMinutes));
        onChooseDuration(getSeconds(selectedValue, selectedMinutes));
        selectHoursRef.current?.blur();
    }

    const handleChangeMinutes = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = Number(e.target.value);
        setSelectedMinutes(selectedValue);
        setDurationInSeconds(getSeconds(selectedHours, selectedValue));
        onChooseDuration(getSeconds(selectedHours, selectedValue));
        selectMinutesRef.current?.blur();
    }

    const handlePresetChange = (duration: number) => {
        setDurationInSeconds(duration);
        setSelectedHours(Math.floor(duration / 3600));
        setSelectedMinutes(Math.floor((duration % 3600) / 60));
        onChooseDuration(duration);
        handleDurationChange(duration);
    }

    const incrementHours = () => {
        setSelectedHours(selectedHours + 1);
        setDurationInSeconds(getSeconds(selectedHours + 1, selectedMinutes));
        onChooseDuration(getSeconds(selectedHours + 1, selectedMinutes));
    };

    const decrementHours = () => {
        if ((durationInSeconds || 0) < 3600) return;
        const newHours = selectedHours - 1;
        setSelectedHours(newHours);
        setDurationInSeconds(getSeconds(newHours, selectedMinutes));
        onChooseDuration(getSeconds(newHours, selectedMinutes));
    };

    const incrementMinutes = () => {
        if (selectedMinutes >= 60) return;
        const newMinutes = selectedMinutes + 5;
        setSelectedMinutes(newMinutes);
        setDurationInSeconds(getSeconds(selectedHours, newMinutes));
        onChooseDuration(getSeconds(selectedHours, newMinutes));
    };

    const decrementMinutes = () => {
        if ((selectedMinutes || 0) <= 0) return;
        const newMinutes = selectedMinutes - 5;
        setSelectedMinutes(newMinutes);
        setDurationInSeconds(getSeconds(selectedHours, newMinutes));
        onChooseDuration(getSeconds(selectedHours, newMinutes));
    };

    const handleOpenDropDown = (ref: React.RefObject<HTMLSelectElement>) => {
        if (ref.current && isMobile) ref.current.focus();
    }

    const arrowUp = () => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
            <path d="M11.3484 7.5L7.73366 4.5L4.11893 7.5" stroke="#334C45" strokeWidth="1.25" stroke-linecap="round" strokeLinejoin="round" />
        </svg>)
    }

    const arrowDown = () => {
        return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
            <path d="M4.1189 4.5L7.73363 7.5L11.3484 4.5" stroke="#334C45" strokeWidth="1.25" stroke-linecap="round" strokeLinejoin="round" />
        </svg>)
    }

    const getSeconds = (hours: number, minutes: number) => {
        return hours * 3600 + minutes * 60;
    }

    return (
        <>
            {isShowHeader && <TaskEditOverlayHeaderAndAction
                headerText={headerText}
                onClear={handleClear}
                onSet={handleSet}
                shouldHideSet={componentStep === EComponentStep.PRESET_OPTIONS}
                onClickBackArrow={componentStep === EComponentStep.CUSTOM_SELECTOR ? () => setComponentStep(EComponentStep.PRESET_OPTIONS) : (isSubTask || isMobileView()) ? onClose : null}
            />}
            <div className='task-edit-overlay-duration-picker-container'>
                {componentStep === EComponentStep.PRESET_OPTIONS &&
                    <div className='task-edit-overlay-duration-picker-preset-options-container'>
                        <div className="task-overlay-flex-options-container duration-options-flex-container">
                            {options.map(option => {
                                return (
                                    <AppButton id={`duration-overlay-${option.name?.trim()}-quick-option`} className={`no-style-button duration-preset-button${durationInSeconds === option.value ? ' duration-preset-button--selected' : ''}`} key={option.name} onClick={() => handlePresetChange(option.value)}>{option.name}</AppButton>
                                )
                            })}
                        </div>
                        <AppButton id="duration-overlay-pick-custom" className='no-style-button task-edit-overlay-quick-options-custom-option' onClick={() => setComponentStep(EComponentStep.CUSTOM_SELECTOR)}>
                            <span>{t("or")}</span>
                            {" "}
                            <span>{t("pickCustomDuration")}</span>
                        </AppButton>
                    </div>}
                {componentStep === EComponentStep.CUSTOM_SELECTOR && <div className='task-edit-overlay-duration-custom-time-picker-container'>
                    <div className='task-edit-overlay-duration-custom-time-picker task-edit-overlay-duration-custom-time-picker--hours'>
                        <span className='task-edit-overlay-duration-custom-time-picker-value'
                            onClick={() => handleOpenDropDown(selectHoursRef)}>{selectedHours} {selectedHours === 1 ? 'Hour' : 'Hours'}
                        </span>
                        {isMobile && <select value={selectedHours}
                            onChange={handleChangeHours}
                            className='hidden-select-element'
                            name="duration-selector"
                            ref={selectHoursRef}
                            onFocus={e => { e.currentTarget.size = 6; e.currentTarget.style.zIndex = "1" }}
                            onBlur={e => { e.currentTarget.size = 0; e.currentTarget.style.zIndex = "-1" }}>
                            {Array.from(Array(24).keys()).map(hour =>
                                <option key={hour} value={hour}>{hour}</option>)}
                        </select>}
                        <div className='task-edit-overlay-duration-picker-action-buttons-container'>
                            <AppButton id="duration-overlay-increment-hours" className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={incrementHours}>{arrowUp()}</AppButton>
                            <AppButton id="duration-overlay-decrement-hours" className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={decrementHours}>{arrowDown()}</AppButton>
                        </div>
                    </div>
                    <div className='task-edit-overlay-duration-custom-time-picker task-edit-overlay-duration-custom-time-picker--minutes'>
                        <span className='task-edit-overlay-duration-custom-time-picker-value'
                            onClick={() => handleOpenDropDown(selectMinutesRef)}>{selectedMinutes} {selectedMinutes === 1 ? 'Minute' : 'Minutes'}
                        </span>
                        {isMobile && <select value={selectedMinutes}
                            onChange={handleChangeMinutes}
                            className='hidden-select-element'
                            name="duration-selector"
                            ref={selectMinutesRef}
                            onFocus={e => { e.currentTarget.size = 6; e.currentTarget.style.zIndex = "1" }}
                            onBlur={e => { e.currentTarget.size = 0; e.currentTarget.style.zIndex = "-1" }}>
                            {Array.from(Array(60).keys()).map(minute =>
                                <option key={minute} value={minute}>{minute}</option>)}
                        </select>}
                        <div className='task-edit-overlay-duration-picker-action-buttons-container'>
                            <AppButton id="duration-overlay-increment-minutes" className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={incrementMinutes}>{arrowUp()}</AppButton>
                            <AppButton id="duration-overlay-decrement-minutes" className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={decrementMinutes}>{arrowDown()}</AppButton>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    );
};