import React from 'react';
import { useApiData } from '../../shared/hooks/useApiData';
import { useAppDispatch, useAppSelector } from '../store';
import { ILoginResponse, IUser } from './auth.interfaces';
import { useLocalStorage } from '../../shared/utils/useLocalStorage';
import { APP_ROUTING_PATHS, loginTypeStorageKey, refreshTokenLocalStorageKey, registrationParametersStorageKey, routeParamsLocalStorageKey, tokenLocalStorageKey, userInfoLocalStorageKey } from '../constants';
import { setAppInsightsUserContext } from '../../application-insights';
import { getUserAppData } from '../useAppData/user.store';
import { setAppModalContentType } from '../../shared/components/app-modals/appModals.store';
import { EAppModalContentType } from '../../shared/components/app-modals/appModals.interfaces';
import { getItemFromLocalStorage } from '../../shared/utils/localStorage.utils';
import { applySearchParamsFromLocalStorage, useNavigateWithSearchParams } from '../../shared/utils/routingUtils';
import { registrationReqAction } from './auth.store';

export const Auth: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { loginRes, registrationRes } = useAppSelector(store => store.authReducer);
  const [, setAccessToken] = useLocalStorage(tokenLocalStorageKey, '');
  const [, setRefreshToken] = useLocalStorage(refreshTokenLocalStorageKey, '');
  const [, setUserInfo] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const navigate = useNavigateWithSearchParams();
  const dispatch = useAppDispatch();

  useApiData(loginRes, {
    onFulfilled(loginData) {
      if (loginData.token && loginData.refreshToken) {
        const { token, refreshToken } = loginData as ILoginResponse;
        setAccessToken(token);
        setRefreshToken(refreshToken);
        // apply important search params from local storage if exist during the login flow
        applySearchParamsFromLocalStorage(['routing'], routeParamsLocalStorageKey);
        dispatch(getUserAppData()).unwrap()
          .then(data => {
            const { user } = data;
            if (!!user) {
              setUserInfo(user);
              setAppInsightsUserContext();
            }
            if (!!data.releaseNotes) dispatch(setAppModalContentType(EAppModalContentType.RELEASE_NOTES));
            newSignUpFlowLogic(user);
          })
          .catch(() => {
            const loginType = getItemFromLocalStorage(loginTypeStorageKey);
            if (loginType !== 'phone') navigate(`${APP_ROUTING_PATHS.REDIRECT}`);
          })
      }
    },
  });

  const newSignUpFlowLogic = (user: IUser | null) => {
    // registration of inactive open user using registration parameters
    if (!user?.isActive) {
      dispatch(registrationReqAction({
        registrationParameters: localStorage.getItem(registrationParametersStorageKey) || null
      }))
    } else {
      navigate(APP_ROUTING_PATHS.REDIRECT);
    }
  }

  useApiData(registrationRes, {
    onFulfilled(registrationData) {
      setUserInfo(registrationData);
      setAppInsightsUserContext();
      if (registrationData.id) {
        navigate(APP_ROUTING_PATHS.REDIRECT);
      }
    } // on reject, the error message will display to the user on the registration screen
  });

  return <>{children}</>;
};