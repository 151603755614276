import { FunctionComponent, memo } from 'react';
import { TChatMessageType } from '../../../chat.interfaces';
import { getDateWithYear, getTimeByDate } from '../../../../../shared/utils/dateFormat';
import './MessageDateTime.scss';

interface IMessageDateTimeProps {
  messageTime: number;
  messageType: TChatMessageType;
}

const MessageDateTime: FunctionComponent<IMessageDateTimeProps> = ({ messageTime,messageType }) => {

  return (
    <div className={`message-date-time ${messageType === 'User' ? 'text-align-right' : ''}`}>
      {getDateWithYear(messageTime)}, {getTimeByDate(messageTime)}
    </div>
  )
}
export default memo(MessageDateTime);