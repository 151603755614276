import { Fragment, FunctionComponent } from 'react';
import SingleTaskItemContainer, { ETaskViewType } from './tasks-list-single-item/SingleTaskItemContainer';
import { ETasksGroupsType, IMessageDataTask } from '../../../../resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { unscheduledSorting } from './TasksList.utils';

interface ITasksGroupProps {
  taskGroupItems: IMessageDataTask[];
  groupTitle?: string;
  groupType: ETasksGroupsType;
  shouldHideOnComplete?: boolean
  shouldDisplayFirstTagColorEachTask?: boolean;
  shouldDisplayActionButtons?: boolean;
  viewType?: ETaskViewType;
}

const TasksGroup: FunctionComponent<ITasksGroupProps> = ({ taskGroupItems, groupTitle, shouldHideOnComplete, groupType, shouldDisplayFirstTagColorEachTask,shouldDisplayActionButtons, viewType }) => {

  const getGroupsTypeClassName = (): string => {
    switch (groupType) {
      case ETasksGroupsType.SCHEDULED:
        return "scheduled";
      case ETasksGroupsType.OVERDUE:
        return "overdue";
      case ETasksGroupsType.UNSCHEDULED:
        return "unscheduled";
      default:
        return "";
    }
  }

  const groupClassName: string = getGroupsTypeClassName();

  const orderTasksByGroupType = (): IMessageDataTask[] => {
    switch (groupType) {
      case ETasksGroupsType.SCHEDULED:
        return [...taskGroupItems].sort((a, b) => new Date(a.workTime || "").getTime() - new Date(b.workTime || "").getTime());
      case ETasksGroupsType.OVERDUE:
        return [...taskGroupItems].sort((a, b) => new Date(a.dueDate || "").getTime() - new Date(b.dueDate || "").getTime());
      case ETasksGroupsType.UNSCHEDULED:
        return [...taskGroupItems].sort((a, b) => unscheduledSorting(a, b));
      case ETasksGroupsType.DONE:
        return [...taskGroupItems].sort((a, b) => new Date(b.completionTime || "").getTime() - new Date(a.completionTime || "").getTime());
      case ETasksGroupsType.QUICK_WINS:
      case ETasksGroupsType.PRIORITY:
        return [...taskGroupItems].sort((a, b) => new Date(b.creationTime || "").getTime() - new Date(a.creationTime || "").getTime());
      default:
        return [...taskGroupItems];
    }
  }

  return (
    <>
      {!!taskGroupItems.length &&
        <section className={`tasks-group ${groupClassName}`} data-testid="tasks-group" key={groupTitle}>
          {!!groupTitle && <h3 className="group-title" data-testid={groupType}>{groupTitle}</h3>}
          {orderTasksByGroupType().map((IMessageDataTask, i) =>
            <Fragment key={IMessageDataTask.id}>
              <SingleTaskItemContainer viewType={viewType} shouldDisplayActionButtons={shouldDisplayActionButtons} shouldHideOnComplete={shouldHideOnComplete} singleTask={IMessageDataTask} className={groupClassName} shouldDisplayFirstTagColor={shouldDisplayFirstTagColorEachTask} />
            </Fragment>
          )}
        </section>
      }
    </>
  )
}

export default TasksGroup;
