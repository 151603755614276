import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IAddEditTaskForm } from '../../stageTasks.interface';
import backIcon from "../../../../../../../assets/images/thicker-2px-stroke-back-icon.svg";
import "./EditTaskNotesModal.scss";
import AppButton from '../../../../../../../shared/components/app-button/AppButton';
import { AppRichText } from '../../../../../../../shared/components/app-rich-text/AppRichText';

const EditTaskNotesModal = ({ onCloseModal, isReadOnly,onBlur }: { onCloseModal: () => void, isReadOnly:boolean, onBlur:(value:string)=>void }) => {
  const taskForm = useFormContext<IAddEditTaskForm>();
  const { t } = useTranslation();
  const notesWatch = taskForm.watch("notes");

  const onClear = () => {
    taskForm.setValue("notes", "");
  }

  return (
    <div className='edit-notes-modal--container'>
      <div className='edit-notes-modal--actions'>
        <AppButton type='button' className="edit-notes-modal--actions--back" onClick={onCloseModal} id="task-notes-modal-back-action"><img src={backIcon} alt="back" /></AppButton>
        {!isReadOnly && <AppButton id="task-notes-modal-clear-action" type='button' className="edit-notes-modal--actions--clear" disabled={!notesWatch} onClick={onClear}>{t("clearButtonText")}</AppButton>}
      </div>
      <AppRichText
        id="task-notes"
        className={`edit-notes-modal--text-box`}
        placeholder={t("addEditTaskNotesFullPageModalPlaceholder")}
        onBlur={(value: string) => { if(value && value !== notesWatch) taskForm.setValue("notes", value) }}
        initialValue={notesWatch}
        disabled={isReadOnly}
      />
    </div>
  )
}

export default EditTaskNotesModal
