import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/store"
import { useSearchParams } from "react-router-dom";
import { stageParam } from "../../app/constants";
import { EAIFormTypes } from "./AIControlUX.interface";
import { EAppStageContentType } from "../chat-wrapper/resizable-container/stage-container/stageContainer.interfaces";
import { setTasksFilter } from "../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store";
import { ETasksGroupsType } from "../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface";
import { activePlannerUnscheduledTasksPlacer } from "../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.utils";
import { setAIControl } from "./AIControlUX.store";

export const AIControlUX = () => { 
    const {aiControl} = useAppSelector(store => store.aiControlUXReducer);
    const [,setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    const removeParams = useCallback(() => {
        setSearchParams(prev => {
            prev.delete(stageParam);
            return prev;
        });
    }, [setSearchParams]);

    const replaceParams = useCallback((screen: string) => {
        setSearchParams(prev => {
            if (prev.get(stageParam) === screen) return prev;
            prev.delete(stageParam);
            prev.append(stageParam, screen);
            return prev;
        });
    }, [setSearchParams]);

    const handleAIControl = useCallback(() => {
        if (aiControl && aiControl.arg) {
            const {form, view} = aiControl.arg;
            switch (form)   {
                case EAIFormTypes.TASKLIST:
                    dispatch(setTasksFilter(view as ETasksGroupsType));
                    replaceParams(EAppStageContentType.TASKS);
                    break;
                case EAIFormTypes.PLAN:
                    dispatch(setTasksFilter(view as ETasksGroupsType));
                    replaceParams(EAppStageContentType.PLANNER);
                    break;
                case EAIFormTypes.SCRATCHPAD:
                    replaceParams(EAppStageContentType.SCRATCHPAD);
                    break;
                case EAIFormTypes.PLANNER:
                    activePlannerUnscheduledTasksPlacer();
                    replaceParams(EAppStageContentType.PLANNER);
                    break;
                case EAIFormTypes.CHAT:
                    removeParams();
                    break;
                default:
                    break;
            }
            dispatch(setAIControl(null));
        }
    }, [aiControl, removeParams, replaceParams, dispatch]);

    useEffect(() => {
        handleAIControl();
    }, [handleAIControl]);

    return null;
}