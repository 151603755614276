import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { getSessionSummariesReq } from '../../chat.store';
import { useTranslation } from 'react-i18next';
import { EAPIStatus } from '../../../../shared/api/models';
import { LottieAppLoader } from '../../../../shared/components/lottie-loader/LottieLoader';
import { SummariesGroup } from './summaries-group/SummariesGroup';
import { useMutationObserver } from '../../../../shared/hooks/useMutationObserver';
import { scrollToTheBottomOfScrollableEl } from '../../../../shared/utils/scrollToTheBottomOfScrollableEl';
import './SessionSummaries.scss';

export const SessionSummaries = () => {
  const dispatch = useAppDispatch();
  const { summariesResponse, historyResponse, summariesGroupsObj, scrollIntoMessageId } = useAppSelector(store => store.chatReducer);
  const { t } = useTranslation();
  const abortControllerRef = useRef(new AbortController());
  const summariesWrapperRef = useRef<HTMLDivElement | null>(null);

  const scrollToTheBottom = useCallback(() => {
    scrollToTheBottomOfScrollableEl('.summaries-list-scrollable-container');
  }, []);

  useMutationObserver(summariesWrapperRef, scrollToTheBottom);

  const onGetSummaries = useCallback(() => {
    dispatch(getSessionSummariesReq({signal: abortControllerRef.current.signal}));
  }, [dispatch])

  useEffect(() => {
    const controller = new AbortController();
    abortControllerRef.current = controller;
    onGetSummaries();

    return () => {
      controller.abort();
      abortControllerRef.current.abort();
    };
  }, [onGetSummaries]);
  
  return (
    <div className='chat-history-container neutral-4-bg' data-testid="chat-history-summaries">
      <div className="summaries-list-scrollable-container" ref={summariesWrapperRef}>
        {[EAPIStatus.FULFILLED].includes(summariesResponse.status) && summariesResponse?.data?.length === 0 && <div className='no-summaries' data-testid="there-is-no-summaries">{t('summariesPageNothingHereYetText')}</div>}
        {[EAPIStatus.REJECTED].includes(summariesResponse.status) && <div className='reload-summaries'>{t('somethingWentWrongError')}.<span onClick={onGetSummaries}>{t('summariesPageReload')}</span></div>}
        {[EAPIStatus.FULFILLED].includes(summariesResponse.status) &&
          ((Object.keys(summariesGroupsObj) || []).map((key, i) => {
            return (
              <Fragment key={i}>
                <SummariesGroup groupTitle={key} summaryGroupItems={summariesGroupsObj[key]} />
              </Fragment>
            )
          }))
        }
      </div>
      {([historyResponse.status, summariesResponse.status].includes(EAPIStatus.PENDING) || scrollIntoMessageId) && <LottieAppLoader testId='summary-page-loader' className='summary-page-loader' />}
    </div>
  )
}