import { Descendant, NodeEntry, Text } from "slate";
import { RenderLeafProps } from "slate-react";
import { ApplicationInsightsApi } from "../../../application-insights";
import { isMobileAppWebView, sendRequestOpenUrlToMobileApp } from "../../../mobile-application-utils";

//returns an array of tuples, each containing a URL and its starting index in the text. This function is used to identify and process URLs within the editor's content.
export const appRichTextFindUrlsInText = (text: string): [string, number][] => {
  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

  const matches = text.match(urlRegex);

  return matches ? matches.map((m) => [m.trim(), text.indexOf(m.trim())]) : [];
};

export const appRichTextGetInitValue = (initialValue?: string | null): Descendant[] => {
  const defaultValue = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];
  try {
    if (!initialValue || initialValue.length===0) return defaultValue;
    const splitByLines = initialValue.split("\n");
    const parsedData = splitByLines.map((line) => {
      return {
        type: "paragraph",
        children: [{ text: line }],
      };
    });
    return parsedData;
  }
  catch (e) {
    ApplicationInsightsApi.trackException(e);
    console.error("get the rich text initial data is failed with error: ", e);
    return defaultValue;
  }
}

export const convertRichTextDataToString = (data: Descendant[]):string => {
  try {
    return data
      .map(node => {
        // iterate over the children and extract text if it's a Text node
        if ('children' in node) {
          return node.children.map(child => Text.isText(child) ? child.text : '').join('');
        }
        return '';
      })
      .join('\n');
  }
  catch (e) {
    ApplicationInsightsApi.trackException(e);
    console.error("convert the rich text data to string is failed with error: ", e);
    return '';
  }
}


export const appRichTextIsStringifyJSON = (str: string): boolean => {
  try {
    JSON.parse(str);
    return true; // If parsing succeeds, it's valid JSON
  } catch (e) {
    return false; // If parsing throws an error, it's not valid JSON
  }
};

// returns an array of decorations, each specifying the range of text that should be styled as a link.
export const appRichTextDecorator = ([node, path]: NodeEntry) => {
  const nodeText = (node as any).text;

  if (!nodeText) return [];

  const urls = appRichTextFindUrlsInText(nodeText);

  return urls.map(([url, index]) => {
    return {
      anchor: {
        path,
        offset: index,
      },
      focus: {
        path,
        offset: index + url.length,
      },
      decoration: "link",
    };
  });
};

// Check if the URL starts with http:// or https://, otherwise prepend https://
// to avoid that links without a protocol will open as relative paths to our website.
export const normalizeUrl = (url: string): string => {
  return /^https?:\/\//i.test(url) ? url : `https://${url}`;
};

// responsible for rendering individual text leaves within the editor.
// if a leaf has a decoration property set to "link", it wraps the text in an anchor (<a>) tag
export const appRichTextLeaf = ({ attributes, children, leaf }: RenderLeafProps): JSX.Element => {
  if ((leaf as any).decoration === "link") {
    const url = normalizeUrl(leaf.text);

    children = (
      <a
        style={{ cursor: "pointer" }}
        href={url}
        onClick={() => {
          if (isMobileAppWebView()) {
            sendRequestOpenUrlToMobileApp(url);
          } else {
            window.open(url, "_blank", "noopener,noreferrer");
          }
        }}
      >
        {children}
      </a>
    );
  }

  return <span {...attributes}>{children}</span>;
};


export const appRichTextGetDataAsString = (data: Descendant[]) => {
  try {
    return JSON.stringify(data);
  }
  catch (e) {
    ApplicationInsightsApi.trackException(e);
    console.error("stringify the rich text data is failed with error: ", e);
  }
}