import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { ETaskStatus, ETasksGroupsType, IMessageDataTask } from '../../stage-tasks/stageTasks.interface';
import { getTasksListReqAction } from '../../stage-tasks/stageTasks.store';
import SingleTaskItemContainer, { ETaskViewType } from '../../../../chat/chat-conversation/message-data-card/tasks-list/tasks-list-single-item/SingleTaskItemContainer';
import { useTranslation } from 'react-i18next';
import ScratchpadNotification from '../scratchpad-notification/ScratchpadNotification';
import { setScratchpadTaskUpdatedItem } from '../stageScratchpad.store';
import { taskActionOverlaysPositionStyle } from './ScratchpadTaskList.utils';
import AddTaskField from '../../add-task-field/AddTaskField';
import './ScratchpadTaskList.scss';
import ScratchpadEmptyState from '../scratchpad-empty-state/ScratchpadEmptyState';

const ScratchpadTaskList = () => {
  const { tasksOrderedByGroups } = useAppSelector(store => store.StageTasksReducer);
  const { scratchpadTaskUpdatedItem } = useAppSelector(store => store.StageScratchpadReducer);
  const { shouldDisplayProductTour } = useAppSelector(store => store.sharedStoreReducer);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [taskIdReplacedByNotification, setTaskIdReplacedByNotification] = useState<string | null>(null);
  const [notificationText, setNotificationText] = useState<string | null>(null);
  const [localScratchpadList, setLocalScratchpadList] = useState(!!tasksOrderedByGroups ? tasksOrderedByGroups[ETasksGroupsType.SCRATCHPAD] : [])

  useEffect(() => {
    if (!!tasksOrderedByGroups) setLocalScratchpadList(tasksOrderedByGroups[ETasksGroupsType.SCRATCHPAD]);
  }, [tasksOrderedByGroups])

  const getNotificationText = useCallback((updatedTask: IMessageDataTask): string | null => {
    let notificationText: string | null = null;
    if (!!updatedTask?.workTime) notificationText = t("scratchpadMoveTaskToTaskListNotificationText");
    else {
      // TODO: get DONE and COMPLETE status notification text from Figma when Figma updated
      switch (updatedTask?.status) {
        case ETaskStatus.NOT_STARTED:
          notificationText = t("scratchpadMoveTaskToUnscheduledNotificationText");
          break;
        case ETaskStatus.DONE:
          notificationText = t("scratchpadCompleteTaskNotificationText");
          break;
        case ETaskStatus.DELETED:
          notificationText = t("scratchpadDeleteTaskNotificationText");
          break;
        default:
          notificationText = null;
      }
    }
    return (!!notificationText && updatedTask.name ?
      notificationText.replace('{task_name}', updatedTask.name.length > 20 ? updatedTask.name.slice(0, 20) + "..." : updatedTask.name)
      : null
    )
  }, [t]);

  const onUpdateTask = useCallback((updatedTask: IMessageDataTask) => {
    const notificationText = getNotificationText(updatedTask);
    if (!!notificationText && updatedTask?.id) {
      setTaskIdReplacedByNotification(updatedTask.id);
      setNotificationText(notificationText);
    }
    else dispatch(getTasksListReqAction());

    dispatch(setScratchpadTaskUpdatedItem(null));
  }, [dispatch, getNotificationText])

  useEffect(() => {
    if (!!scratchpadTaskUpdatedItem) onUpdateTask(scratchpadTaskUpdatedItem as IMessageDataTask);
  }, [scratchpadTaskUpdatedItem, onUpdateTask])

  const onDestroyNotification = () => {
    setNotificationText(null);
    dispatch(getTasksListReqAction()).unwrap()
      .then(() => {
        setTaskIdReplacedByNotification(null);
      })
  }

  const renderTasksMapElement = (task: IMessageDataTask) => {
    if (taskIdReplacedByNotification === task.id) {
      if (!!notificationText) {
        return (
          <ScratchpadNotification
            notificationText={notificationText}
            className='update-task-notification'
            destroyAnimationType="shrinkOut"
            onDestroy={onDestroyNotification}
          />
        )
      }
      else return <></>;
    }
    // display task item only if the taskId is not equal to the taskIdReplacedByNotification
    else return (
      <SingleTaskItemContainer
        singleTask={task}
        shouldDisplayActionButtons={true}
        shouldDisplayFirstTagColor={false}
        actionOverlaysPositionStyle={taskActionOverlaysPositionStyle}
        shouldFetchUpdatedListAfterUpdateSingleTask={false}
        onUpdate={(updatedTask) => onUpdateTask(updatedTask)}
        viewType={ETaskViewType.SCRATCHPAD}
      />
    )
  }

  return (
    <div className='scratchpad-task-list-container'>
      <div className="scratchpad-task-list">
        {localScratchpadList?.map((task, i) => {
          return (
            <Fragment key={task.id}>
              {renderTasksMapElement(task)}
            </Fragment>
          )
        })}
      </div>
      <AddTaskField
        taskStatus={ETaskStatus.SCRATCHPAD}
        placeholder={t("scratchpadAddTaskInputPlaceholder")}
        onChange={(newTask) => setLocalScratchpadList(prev => [...prev, {...newTask}])}
        className="add-scratchpad-task"
      />
      {!shouldDisplayProductTour && localScratchpadList.length === 0 && <ScratchpadEmptyState />}
    </div>
  )
}

export default ScratchpadTaskList;