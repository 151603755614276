import React, { FunctionComponent, MouseEvent, useEffect, useRef, useState } from 'react'
import { IMessageDataTask } from '../../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import SassVariables from "../../../../../styles/style.module.scss";
import { useTranslation } from 'react-i18next';
import { DragSourceMonitor } from 'react-dnd';
import { EDragAndDropType } from '../../../../../shared/utils/utils';
import { ICalendarEvent } from '../CalendarDay';
import { IWorkBlockDropResult } from '../CalendarEvent';
import { useAppSelector } from '../../../../../app/store';
import { convertSingleItemToCalendarEvent } from '../CalendarDay.util';
import DraggableWrapper from '../../../../../shared/components/dragged-destination-preview/DraggableWrapper';
import { getStyleForDraggingPreview } from '../../../../../shared/components/dragged-destination-preview/DragAndDrop.utils';
import { IDragItem } from '../CalendarDragAndDrop.util';
interface IWorkBlockTaskItem {
  task: IMessageDataTask;
  onClickTask?: (e: MouseEvent<HTMLElement, any>) => void;
  eventsFlatArray: ICalendarEvent[];
  dayIndex: number;
  divideEventByGroups: (events: ICalendarEvent[]) => void;
}

const WorkBlockTaskItem: FunctionComponent<IWorkBlockTaskItem> = ({
  onClickTask,
  task,
  eventsFlatArray,
  dayIndex,
  divideEventByGroups,
}) => {
  const { currentTaskPlacement } = useAppSelector(store => store.StagePlannerReducer);
  const { t } = useTranslation();
  const eventRef = useRef<HTMLDivElement | null>(null);
  const [draggingPreviewStyle, setDraggingPreviewStyle] = useState(getStyleForDraggingPreview(eventRef.current, null))

  useEffect(() => {
    setDraggingPreviewStyle(getStyleForDraggingPreview(eventRef.current, null));
  },[eventRef])

  const getBackgroundColor = () => {
    if (!task?.tags || task?.tags.length === 0) return SassVariables.whiteColor;
    else if (!task?.tags[0]?.color || task?.tags[0]?.color === 'transparent') return SassVariables.whiteColor;
    return task?.tags[0]?.color;
  }

  const backgroundColor = getBackgroundColor();


  const onEndDrag = (_: IDragItem, monitor: DragSourceMonitor<IDragItem, IWorkBlockDropResult>) => {
    const dropResult: IWorkBlockDropResult | null = monitor.getDropResult();
    if (monitor.didDrop()) {
      // if dropped in the same workBlock in the current day, return for now (in the future this block will used for reordering the tasks inside the workBlock).
      if (dropResult?.droppedWorkBlockId === task.workBlockId && dropResult?.dayIndex === dayIndex) return;
      // if dropped in different workBlock or inside the calendar itself, remove the event from the current workBlock relatedTasks array
      
      let tempEventsArr = [...eventsFlatArray];
      if(dropResult?.dayIndex === dayIndex && dropResult?.updatedDroppedEvent) {
        tempEventsArr = [...tempEventsArr, dropResult.updatedDroppedEvent];
      }
      const draggedTaskWorkBlockItem = tempEventsArr.find(e => e.id === task?.workBlockId);
      if (draggedTaskWorkBlockItem) {
        draggedTaskWorkBlockItem.relatedTasks = draggedTaskWorkBlockItem.relatedTasks.filter(t => t.id !== task.id);
        divideEventByGroups(tempEventsArr);
      }
    }
  }

  const taskStyle  = {
    backgroundColor: backgroundColor, 
    borderColor: backgroundColor,
  }

  return (
    <DraggableWrapper<IDragItem, IWorkBlockDropResult>
      className={`work-block-task-item ${backgroundColor === SassVariables.whiteColor ? 'work-block-task-item--bg-white' : ''}`} 
      id={`calendar-work-block-task-draggable-wrapper-${task.id}`}
      type={EDragAndDropType.CALENDAR_EVENT}
      item={{ event: convertSingleItemToCalendarEvent(task), previewStyle: draggingPreviewStyle, isComeFromWorkBlock: true }}
      canDrag={() => currentTaskPlacement?.id !== task.id}
      onEndDrag={onEndDrag}
      keyAttr={task.id!}
      style={taskStyle}
      onClick={onClickTask} 
      wrapperRef={eventRef}
    >
      <span className={`calendar-work-block-task-name`}>{task?.name || t("newTaskDefaultName")}</span>
    </DraggableWrapper>
  )
}

export default WorkBlockTaskItem
