import { useFormContext } from 'react-hook-form';
import { IAddEditTaskForm, ITaskFields } from '../../stageTasks.interface';
import { useTranslation } from 'react-i18next';
import {AppRichText} from '../../../../../../../shared/components/app-rich-text/AppRichText';

interface ITaskDescriptionProps {
  onUpdateTaskTextarea: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
  isReadOnly: boolean;
  isSubTask: boolean;
}

const TaskDescription = ({ onUpdateTaskTextarea, isReadOnly, isSubTask }: ITaskDescriptionProps) => {
  const taskForm = useFormContext<IAddEditTaskForm>();
  const descriptionWatch = taskForm.watch("description");
  const { t } = useTranslation();

  const onBlur = (value: string) => {
    taskForm.setValue("description", value)
    onUpdateTaskTextarea({ description: value })
  }



  return (
    <AppRichText
      id="description"
      testId={`${isSubTask ? "sub-" : ""}task-description`}
      className={`task-textarea task-description task-detail-section ${isReadOnly && !descriptionWatch ? 'd-none' : ''}`}
      placeholder={t("addEditTaskDescriptionPlaceholder")}
      onBlur={(value: string) => { if(value && value !== descriptionWatch) onBlur(value) }}
      initialValue={descriptionWatch}
      maxLength={168}
      disabled={isReadOnly}
    />
  )
}

export default TaskDescription
