import { FunctionComponent } from 'react';
import './BackgroundAnimation.scss';

interface IBackgroundAnimationBgProps{
  className?:string;
}

export const BackgroundAnimation: FunctionComponent<IBackgroundAnimationBgProps> = ({className}) => {

  return (
    <div className={`background-animation ${className ? className : ''}`}></div>
  )
}