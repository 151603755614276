import React, { memo, useCallback, useEffect } from 'react'
import { useChatMessageQueue } from '../../shared/hooks/useChatMessageQueue';
import { useAppDispatch } from '../../app/store';
import { UseFieldArrayAppend } from 'react-hook-form';
import { IChatForm } from './chat.interfaces';
import { HumanStudentTurnSendInput } from './chat.store';
import { apiServiceCSharp } from '../../shared/api/axios';
import { useRenewableCookie } from '../../shared/hooks/useRenewableCookie';
import { ISpeechTokenResponse } from './chat/chat-form-user-input/speech.interfaces';
import { API_ROUTES, chatRecordCookieName } from '../../app/constants';

interface IChatHelpersLogicProps {
  append: UseFieldArrayAppend<IChatForm, "messagesArr">
}


const ChatHelpersLogic = ({append}:IChatHelpersLogicProps) => {
  useRenewableCookie(
    {
      cookieName: chatRecordCookieName,
      expirationTimer: 540, // 10 minutes
      renewTime: 540000, // renew after 9 minutes just in case
      callback: async () => {
        const response = await apiServiceCSharp.get<ISpeechTokenResponse>(API_ROUTES.SPEECH.GET_SPEECH_SERVICE_TOKEN);
        const { token, region } = response.data;
        return region + ':' + token;
      }
    }
  );
  const dispatch = useAppDispatch();
  const [nextMessageToProcess] = useChatMessageQueue();

  const processChatMessageFromQueue = useCallback(() => {
    if (nextMessageToProcess) {
      dispatch(HumanStudentTurnSendInput(JSON.parse(nextMessageToProcess.botRequestJson)));
      if (nextMessageToProcess.localUserChatMessage) {
        append(JSON.parse(nextMessageToProcess.localUserChatMessage));
      }
    }
  }, [append, dispatch, nextMessageToProcess]);

  useEffect(() => {
    processChatMessageFromQueue();
  }, [processChatMessageFromQueue]);

  return (
    <></>
  )
}

export default memo(ChatHelpersLogic)