import { MouseEvent } from 'react';
import sassVariables from '../../styles/style.module.scss';
import { getItemFromLocalStorage } from './localStorage.utils';
import { IUser, IUserFeatureFlags } from '../../app/auth/auth.interfaces';
import { userInfoLocalStorageKey } from '../../app/constants';
import { EClientFlags } from '../components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces';
import { FlagUtils } from './flagUtils';
import { ApplicationInsightsApi } from '../../application-insights';
import { ETaskSource, ETaskStatus, IMessageDataTask } from '../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { t } from 'i18next';
import { sortingTasksByWorkBlockOrderAscending } from '../../features/chat-wrapper/chat/chat-conversation/message-data-card/tasks-list/TasksList.utils';
import { EAPIStatus } from '../api/models';

export const isMobileView = (): boolean => {
    if (window.visualViewport) return !!(window.visualViewport?.width <= Number(sassVariables.mobileScreenMaxWidth.replace('px', '')));
    return true;
}

export const isDesktopView = (): boolean => {
    if (window.visualViewport) return !!(window.visualViewport?.width >= Number(sassVariables.desktopScreenMinWidth.replace('px', '')));
    return true;
}

export const minutesToHoursAndMinutesStringRepresentation = (minutes: number): string => {
    if (isNaN(minutes)) return '';
    const localMinutes = Math.round(minutes);
    if (localMinutes === 1) return '1 minute';
    if (localMinutes < 60) return `${minutes} minutes`;
    if (localMinutes === 60) return '1 hour';
    const hours = Math.floor(localMinutes / 60);
    const hoursString = hours === 1 ? 'hour' : 'hours';
    const remainingMinutes = localMinutes % 60;
    if (remainingMinutes === 0) return `${hours} ${hoursString}`;
    if (remainingMinutes === 1) return `${hours} ${hoursString} and 1 minute`;
    return `${hours} ${hoursString} and ${remainingMinutes} minutes`;
}

export const onResizeTextareaHeightByTheContext = (element: HTMLElement |  null) => {
    if (element) {
        element.style.height = "0px"; // reset before recalculate
        element.style.height = (element.scrollHeight) + "px";
    }
}

export const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // pressing enter call blur event, and the focus is stay on the input
    if (e?.code?.toLowerCase() === 'enter' || e?.key?.toLowerCase() === 'enter') {
        (e.target as HTMLInputElement).blur();
        (e.target as HTMLInputElement).focus();
        e.preventDefault();
        e.stopPropagation();
    }
    // pressing ESC clears the field
    else if (e?.code?.toLowerCase() === 'escape' && (e.target as HTMLInputElement)?.value) {
        (e.target as HTMLInputElement).value = '';
    }
}

export const setDataAttributeOnBody = (attributeName: string, attributeValue: string) => {
    document.body.setAttribute(attributeName, attributeValue);
}

export const removeDataAttributeOnBody = (attributeName: string) => {
    document.body.removeAttribute(attributeName);
}

export const addClassToBody = (className: string) => {
    document.body.classList.add(className);
}

export const removeClassFromBody = (className: string) => {
    document.body.classList.remove(className);
}

export const disallowedCountries = ['CU', 'IR', 'KP', 'SY', 'SD', 'UA', 'VE', 'YE', 'ZW'];

export const supportedCountries = ['US', 'AU'];
export const supportedCountriesInternal = ['US', 'IL', 'AU', 'NZ', 'GB']

export const getRelativeClickPosition = (e: MouseEvent<HTMLElement, any>) => {
    // Get the position relative to the e.currentTarget
    const rect = (e.currentTarget as HTMLDivElement).getBoundingClientRect();
    const relativeX = e.clientX - rect.left;
    const relativeY = e.clientY - rect.top;

    return { x: relativeX, y: relativeY };
};

export enum ESoundType {
    TASK_COMPLETED = 1
}

// utility function to control the Audio in Max.
export const handleAppPlaySound = (soundType: ESoundType) => {
    try {
        const clientFlags = getItemFromLocalStorage<IUser>(userInfoLocalStorageKey)?.clientFlags || 0;
        const isAudioAllowed = !(FlagUtils.getFlag(clientFlags, EClientFlags.AUDIO_OFF));
        if (!isAudioAllowed) return;

        switch (soundType) {
            case ESoundType.TASK_COMPLETED:
                const completeEffect = new Audio('/sounds/max_done.mp3');
                completeEffect.play();
                break;
            default:
                return;
        }
    } catch (error) {
        ApplicationInsightsApi.trackException(error)
    }
}

export const areDatesEqual = (date1?:string | Date | null, date2?: string | Date | null) => {
    if(!date1 || !date2) return false;
    return new Date(date1).getTime() === new Date(date2 || "").getTime();
}

export const isTaskAssignToThisWorkBlock = (workBlockId: string, workBlockStartTime?: string | Date | null, taskWorkBlockId?: string | null, taskWorkBlockInstance?: string | Date | null): boolean => {
    return (
        (!!taskWorkBlockId && taskWorkBlockId === workBlockId)
        &&
        (!taskWorkBlockInstance || new Date(taskWorkBlockInstance).getTime() === new Date(workBlockStartTime || "").getTime())
    )
}

export const getWorkBlockAssignedTasksSorted = (tasks: IMessageDataTask[], workBlockId: string, workBlockInstance?: string | Date | null): IMessageDataTask[] => {
    return (
        [...tasks]
        .filter(task => task.status !== ETaskStatus.DELETED && isTaskAssignToThisWorkBlock(workBlockId, workBlockInstance || "", task?.workBlockId, task?.workBlockInstance))
        .sort((a, b) => sortingTasksByWorkBlockOrderAscending(a, b))
    );
}


export const getWorkBlockOrder = (maxOrder: number | undefined | null):number => {
    if(!maxOrder && maxOrder !== 0) return 0;
    return maxOrder + 1;
}

export const getExternalEventSourceDetails = (eventSource?: ETaskSource) => {
    switch (eventSource) {
        case ETaskSource.Google:
            return t("googleEventSourceDetails");
        case ETaskSource.Outlook:
            return t("outlookEventSourceDetails");
        default:
            return '';
    }
}

export enum EDragAndDropType {
    CALENDAR_EVENT = 'CALENDAR_EVENT',
    PLANNER_TO_CALENDAR_EVENT = 'PLANNER_TO_CALENDAR_EVENT',
}

export const isApiPending = (status: EAPIStatus) => status === EAPIStatus.PENDING;

export const getFeatureFlagByKey = (key: string): boolean => {
    return getItemFromLocalStorage<IUser>(userInfoLocalStorageKey)?.featureFlags?.[key] ?? false;
}

export const getFeatureFlags = (): IUserFeatureFlags => {
    return getItemFromLocalStorage<IUser>(userInfoLocalStorageKey)?.featureFlags ?? {};
};
export interface IPreviewStyleForDestinationPreview {
    height?: string;
    width?: string;
    backgroundColor?: string;
    border?: string;
    borderRadius?: string;
    boxShadow?: string;
    opacity?: string;
    zIndex?: string;
    left?: number;
}

export const getDomElementByQuerySelector = (selector: string) => {
    try{
        return document.querySelector(selector);
    }
    catch(error){
        console.error("Get DOM element by query selector failed with error: ", error);
        ApplicationInsightsApi.trackException(error);
    }
}

export const getAllDomElementsByQuerySelector = (selector: string): NodeListOf<HTMLElement>|undefined => {
    try{
        return document.querySelectorAll(selector);
    }
    catch(error){
        console.error("Get DOM elements by query selector failed with error: ", error);
        ApplicationInsightsApi.trackException(error);
    }
}

export const isCursorInElement = (element: Element|undefined|null, cursorX?: number, cursorY?: number): boolean => {
    if(!element || cursorX === undefined || cursorY === undefined) return false;
    const rect = element.getBoundingClientRect();
    return cursorX >= rect.left && cursorX <= rect.right && cursorY >= rect.top && cursorY <= rect.bottom;
  }

export const openUrlInNewTab = (url: string) => {
    window.open(url, '_blank');
}