import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { IUserSettingsFeedbackForm } from '../SettingsMenu.interfaces';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { sendUserFeedbackRequest } from '../settingsMenu.store';
import { useApiData } from '../../../../hooks/useApiData';
import { EAPIStatus } from '../../../../api/models';
import { isErrorCodeNotForbiddenOrUnauthorized } from '../../../../utils/isErrorCodeNotForbiddenOrUnauthorized';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { scrollIntoView } from '../../../../utils/scrollIntoView';
import './SettingsFeedback.scss';
import { Switch } from 'antd';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { userInfoLocalStorageKey } from '../../../../../app/constants';
import { IUser } from '../../../../../app/auth/auth.interfaces';

export const SettingsFeedback = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userFeedback } = useAppSelector(store => store.settingsMenuReducer);
  const [userInfo,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const { register, handleSubmit, reset, formState, watch, setValue } = useForm<IUserSettingsFeedbackForm>({
    defaultValues: {
      feedbackText: '',
      contactEmail: userInfo?.email || '',
      shouldSendEmail: true
    }
  });

  const shouldSendEmailWatch = watch('shouldSendEmail');

  const onSubmitUserFeedback = (formData: IUserSettingsFeedbackForm) => {
    if (!formData.shouldSendEmail || !formData.contactEmail) delete formData.contactEmail;
    delete formData.shouldSendEmail;
    dispatch(sendUserFeedbackRequest(formData));
  }

  useApiData(userFeedback, {
    onFulfilled() {
      reset({ feedbackText: '' });
    },
    onRejected() {
      reset({ shouldSendEmail:shouldSendEmailWatch }, { keepValues: true });
    },
  })

  const shouldDisplayError = () => {
    return [EAPIStatus.REJECTED].includes(userFeedback.status) && isErrorCodeNotForbiddenOrUnauthorized(userFeedback?.error?.code);
  }

  const handleEmailToggle = (checked: boolean) => {
    setValue('shouldSendEmail', checked);
  }

  return (
    <section className="settings-feedback settings-section-container" data-testid='settings-feedback'>
      <h2 className='sub-title static-string'>{t("settingsFeedbackTitle")}</h2>
      <div className='separation'></div>
      <form data-testid="setting-feedback-form" onSubmit={handleSubmit(onSubmitUserFeedback)}>
        <label htmlFor="feedback">{t("settingsFeedbackLabelText")}</label>
        <div className="error-relative">
          <textarea
            data-testid="setting-feedback-textarea"
            id="settings-feedback"
            placeholder={t("settingTextareaPlaceholderText")}
            {...register('feedbackText', { required: true, minLength: 2 })}
            onClick={() => scrollIntoView('#settings-feedback', { behavior: 'smooth', block: 'center' })}
          />
          {shouldDisplayError() && <ErrorMessage />}
        </div>
        <div className='email-and-submit-container'>
          <section className='email-switch-section'>
            <Switch
              className='app-toggle app-toggle-active-dark'
              checked={shouldSendEmailWatch}
              onChange={handleEmailToggle} />
              <p className='switch-email-text'>{t("settingsFeedbackSwitchEmailText")}</p>
          </section>
          <section>
            <input type="text" className='email-input' aria-label='email' autoComplete='false' placeholder={t('emailInputPlaceholder')} {...register('contactEmail', { required: false, minLength: 2 })} />
            <button id="submit-feedback-in-settings" className='submit-feedback' type="submit" data-testid="settings-feedback-submit" disabled={!formState.isValid || formState.isSubmitted}>{t("submit")}</button>
          </section>
        </div>
      </form>
    </section>
  )
}