import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import './index.scss';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { initAppInsights, reactPlugin } from './application-insights';
import { ErrorPage } from './shared/components/error/ErrorPage';
import RootErrorBoundary from './RootErrorBoundary';
import { redirectNewUserToOnboarding } from './app/auth/auth.utils';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { HTML5AndTouchBackends } from './dndMultiBackend.config';

redirectNewUserToOnboarding();
initAppInsights();

const container = document.getElementById('root');
if (!process.env.REACT_APP_IS_LOCAL)
    console.log = function no_console() {
        return;
    };

if (container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <RootErrorBoundary onError={() => <ErrorPage/>} appInsights={reactPlugin}>
                <Provider store={store}>
                    <DndProvider backend={MultiBackend} options={HTML5AndTouchBackends}>
                        {process.env.REACT_APP_HASH_ROUTER === 'true' ? (
                            <HashRouter>
                                <App />
                            </HashRouter>
                        ) : (
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        )}
                    </DndProvider>
                </Provider>
            </RootErrorBoundary>
        </React.StrictMode>
    );
}
