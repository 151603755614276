import { createReducer, createAction } from '@reduxjs/toolkit';
import { apiServiceCSharp, createApiThunk } from '../../shared/api/axios';
import { APIRequestState, IAPIRequestState } from '../../shared/api/models';
import { ApiDataStateType, createAPIReducerCases } from '../../shared/store/utils';
import { API_ROUTES, sessionSourceTypeStorageKey } from '../../app/constants';
import { IMessageResponse, ICreateSessionRequest, ICreateSessionResponse, IFeedbackPayload, IHistoryMessage, IHistoryParams, IHistoryQuery, IHistoryResponse, IHumanStudentTurnSendInputPayload, IOptionMessage, ISessionSummariesResponse } from './chat.interfaces';
import { getIanaTimeZone, getTimeZoneOffsetInMinutes } from '../../shared/utils/timezoneUtils';
import { getDateWithYear } from '../../shared/utils/dateFormat';
import { IUseChatMessageQueuePayload, TChatQueueMessageType } from '../../shared/hooks/useChatMessageQueue';
import { transformResponseMessageToChatMessage } from './chat/Chat.utils';
import { getItemFromLocalStorage } from '../../shared/utils/localStorage.utils';
import { appInsights, ApplicationInsightsApi } from '../../application-insights';

export interface IChatState extends ApiDataStateType {
    sessionResponse: IAPIRequestState<ICreateSessionResponse>;
    botResponse: IAPIRequestState<IMessageResponse[]>;
    feedbackResponse: IAPIRequestState<IFeedbackPayload>;
    historyResponse: IAPIRequestState<IHistoryResponse[]>;
    summariesResponse: IAPIRequestState<ISessionSummariesResponse[]>;
    localHistoryMessages: IHistoryMessage[];
    summariesGroupsObj: { [key: string]: ISessionSummariesResponse[] };
    scrollIntoMessageId: string|null;
    prepopulatedOptions: IOptionMessage[]|null;
    selectedOptionIndex: number|null;
    queueMessageData: IUseChatMessageQueuePayload | null;
    hideMessageId: string | null;
    chatQueueProcessingType: TChatQueueMessageType | null;
}

const initialStateChatStore: IChatState = {
    // TODO rename sessionId to createSessionData
    sessionResponse: APIRequestState.create(),
    botResponse: APIRequestState.create(),
    feedbackResponse: APIRequestState.create(),
    historyResponse: APIRequestState.create(),
    summariesResponse: APIRequestState.create(),
    localHistoryMessages: [],
    summariesGroupsObj: {},
    scrollIntoMessageId: null,
    prepopulatedOptions: null,
    selectedOptionIndex: null,
    queueMessageData: null,
    hideMessageId: null,
    chatQueueProcessingType: null,
};

const createReducerKey = (subKey: string): string => {
    return 'chat/' + subKey;
};

export const createOrContinueSession = createApiThunk(createReducerKey('CreateOrContinueSession'), () => {
    const createOrContinueSessionRequest = {
        userIanaTimeZone: getIanaTimeZone(),
        userTimeZoneOffsetInMinutes: getTimeZoneOffsetInMinutes(),
    } as ICreateSessionRequest;

    // Add sessionSourceTypeId to the request if it exists
    const sessionSourceTypeId = Number(getItemFromLocalStorage(sessionSourceTypeStorageKey)||0)
    if (sessionSourceTypeId && sessionSourceTypeId !== 0) {
        createOrContinueSessionRequest.sessionSourceTypeId = sessionSourceTypeId;
        localStorage.removeItem(sessionSourceTypeStorageKey);
    }
    return apiServiceCSharp.post<ICreateSessionResponse>(API_ROUTES.CHAT.CREATE_OR_CONTINUE_SESSION, createOrContinueSessionRequest as ICreateSessionRequest)
});

export const HumanStudentTurnSendInput = createApiThunk(createReducerKey('HumanStudentTurnSendInput'), (payload?: IHumanStudentTurnSendInputPayload) =>
    apiServiceCSharp.post<IMessageResponse[]>(API_ROUTES.CHAT.HUMAN_STUDENT_TURN + window.location.search, payload),
);

export const feedbackReqAction = createApiThunk(createReducerKey('feedbackReqAction'), (reqPayload?: IFeedbackPayload) =>
    apiServiceCSharp.patch<IFeedbackPayload>(API_ROUTES.CHAT.FEEDBACK, reqPayload),
);

export const getChatHistoryReq = createApiThunk(createReducerKey('getChatHistoryReq'), (req?: { historyParams: IHistoryParams, historyQuery: IHistoryQuery, signal: AbortSignal }) =>
    apiServiceCSharp.get<IHistoryResponse[]>(`${API_ROUTES.CHAT.HISTORY}/${req?.historyParams.sessionId}/${req?.historyParams.currentMessageNumber}`, { signal: req?.signal, params: req?.historyQuery }),
);

export const getSessionSummariesReq = createApiThunk(createReducerKey('getSessionSummariesReq'), (req?: {signal?: AbortSignal}) =>
    apiServiceCSharp.get<ISessionSummariesResponse[]>(API_ROUTES.CHAT.SESSION_SUMMARIES, { signal: req?.signal }),
);

export const downloadSessionAsDocxAction = createApiThunk(createReducerKey('downloadSessionAsDocxAction'), (sessionId?: string) =>
    apiServiceCSharp.get(`${API_ROUTES.CHAT.DOWNLOAD_SESSION}/${sessionId}`),
);

// resetFeedbackResponse expected 0 arguments
export const resetFeedbackResponse = createAction(createReducerKey('resetFeedbackResponse'));

export const resetBotResponse = createAction(createReducerKey('resetBotResponse'));

export const setScrollIntoMessageId = createAction<string|null>(createReducerKey('setScrollIntoMessageId'));

export const setPrepopulatedOptions = createAction<IOptionMessage[]|null>(createReducerKey('setPrepopulatedOptions'));

export const setSelectedOptionIndex = createAction<number|null>(createReducerKey('setSelectedOptionIndex'));

export const setQueueMessageData = createAction<IUseChatMessageQueuePayload | null>(createReducerKey('setQueueMessage'));

export const hideChatMessage = createAction<string>(createReducerKey('hideChatMessage'));

export const setChatQueueProcessingType = createAction<TChatQueueMessageType | null>(createReducerKey('setChatQueueProcessingType'));

export const chatReducer = createReducer(initialStateChatStore, (builder) => {
    createAPIReducerCases(createOrContinueSession, 'sessionResponse', builder, {
        onFulfilled(state, createSessionResponse) {
            // set session id on application insights context
            try {
                appInsights.context.session.id = createSessionResponse.sessionId;
            } catch (error) {
                ApplicationInsightsApi.trackException(error);
            }
            // when there is no bot greeting - continue session - Insert to the history array only the messages that do not belong to the current session (These messages will be added to the chat's messages array as part of the current session's messages.)
            if(!createSessionResponse?.messages?.length) state.localHistoryMessages = [...transformResponseMessageToChatMessage(createSessionResponse.history.filter(historyItem => historyItem.sessionId !== createSessionResponse.sessionId), null)];
            else state.localHistoryMessages = [...transformResponseMessageToChatMessage(createSessionResponse.history, null)];
        },
    });
    createAPIReducerCases(HumanStudentTurnSendInput, 'botResponse', builder);
    createAPIReducerCases(feedbackReqAction, 'feedbackResponse', builder);
    createAPIReducerCases(getChatHistoryReq, 'historyResponse', builder,{
        onFulfilled(state, historyData) {
            state.localHistoryMessages = [...transformResponseMessageToChatMessage(historyData, null), ...state.localHistoryMessages];
        },
    });
    createAPIReducerCases(getSessionSummariesReq, 'summariesResponse', builder, {
        onFulfilled(state, summariesData) {
            state.summariesGroupsObj = summariesData.reduce((accumulator: { [key: string]: ISessionSummariesResponse[] }, current: ISessionSummariesResponse) => {
                const currentGroupName = getDateWithYear(current.creationTime).replaceAll(' ','-');
                accumulator[currentGroupName] ? accumulator[currentGroupName].push(current) : accumulator[currentGroupName] = [current]
                return accumulator;
            }, {});
        },
    });
    builder.addCase(resetFeedbackResponse, (state, action) => {
        state.feedbackResponse = APIRequestState.create();
    });
    builder.addCase(resetBotResponse, (state, action) => {
        state.botResponse = APIRequestState.create();
    });
    builder.addCase(setScrollIntoMessageId, (state, action) => {
        state.scrollIntoMessageId = action.payload;
    });
    builder.addCase(setPrepopulatedOptions, (state, action) => {
        state.prepopulatedOptions = action.payload;
    });
    builder.addCase(setSelectedOptionIndex, (state, action) => {
        state.selectedOptionIndex = action.payload;
    });
    builder.addCase(setQueueMessageData, (state, action) => {
        state.queueMessageData = action.payload;
    });
    builder.addCase(hideChatMessage, (state, action) => {
        state.hideMessageId = action.payload;
    });
    builder.addCase(setChatQueueProcessingType, (state, action) => {
        state.chatQueueProcessingType = action.payload;
    });
});
