import { Fragment, FunctionComponent } from 'react';
import { IChatMessageData } from '../../../../chat.interfaces';
import TasksGroup from './TasksGroup';
import { ETasksGroupsType } from '../../../../resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { useTranslation } from 'react-i18next';
import './TasksList.scss';
import { ETaskViewType } from './tasks-list-single-item/SingleTaskItemContainer';

interface ITasksListProps {
  msgData: IChatMessageData;
}

const TasksList: FunctionComponent<ITasksListProps> = ({ msgData }) => {
  const { t } = useTranslation()


  return (
    <div className='in-chat-tasks-list-container' data-testid='tasks-list-container'>
      {msgData.content &&
        <Fragment>
          <TasksGroup viewType={ETaskViewType.IN_CHAT_TASK_LIST} shouldHideOnComplete={true} taskGroupItems={msgData.content[ETasksGroupsType.PRIORITY]} groupTitle={t("stageTaskPriorityGroupTitle")} groupType={ETasksGroupsType.PRIORITY} />
          <TasksGroup viewType={ETaskViewType.IN_CHAT_TASK_LIST} shouldHideOnComplete={true} taskGroupItems={msgData.content[ETasksGroupsType.OVERDUE]} groupTitle={t("stageTasksOverdueGroupTitle")} groupType={ETasksGroupsType.OVERDUE} />
          <TasksGroup viewType={ETaskViewType.IN_CHAT_TASK_LIST} shouldHideOnComplete={true} taskGroupItems={msgData.content[ETasksGroupsType.QUICK_WINS]} groupTitle={t("stageTaskQuickWinsGroupTitle")} groupType={ETasksGroupsType.QUICK_WINS} />
          <TasksGroup viewType={ETaskViewType.IN_CHAT_TASK_LIST} shouldHideOnComplete={true} taskGroupItems={msgData.content[ETasksGroupsType.SCHEDULED]} groupTitle={t("stageTasksScheduledGroupTitle")} groupType={ETasksGroupsType.SCHEDULED} />
          <TasksGroup viewType={ETaskViewType.IN_CHAT_TASK_LIST} shouldHideOnComplete={true} taskGroupItems={msgData.content[ETasksGroupsType.UNSCHEDULED]} groupTitle={t("stageTaskUnscheduledGroupTitle")} groupType={ETasksGroupsType.UNSCHEDULED} />
          <TasksGroup viewType={ETaskViewType.IN_CHAT_TASK_LIST} shouldHideOnComplete={true} taskGroupItems={msgData.content[ETasksGroupsType.SCRATCHPAD]} groupTitle={t("stageTaskScratchpadGroupTitle")} groupType={ETasksGroupsType.SCRATCHPAD} />
        </Fragment>
      }
    </div>
  )
}

export default TasksList;
