import { useEffect, useState } from "react"

export const useInViewport = (ref: React.RefObject<HTMLElement>, rootMargin = "0px", ratio: number = 0.75): boolean => {
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        if (ref.current == null) return;
        const currentRef = ref.current;
        const observer = new IntersectionObserver(
            ([entry]) => setIsVisible(entry.isIntersecting),
            { rootMargin, threshold: ratio },
        )
        observer.observe(ref.current);
        return () => {
            if (currentRef == null) return;
            observer.unobserve(currentRef)
        }
    }, [ref, rootMargin, ratio]);
    return isVisible
}