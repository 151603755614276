import { FunctionComponent } from 'react';
import { IOptionMessage } from '../../../chat.interfaces';
import '../Message.scss';
import { useAppSelector } from '../../../../../app/store';

interface IOptionMessageProps {
  option: IOptionMessage;
  onSelectOption: (index:number) => void;
  index:number;
}

const PrepopulateOptionItem: FunctionComponent<IOptionMessageProps> = ({ option,onSelectOption, index }) => {
  const { selectedOptionIndex } = useAppSelector(store => store.chatReducer);
  
  return (
    <div className={`message option ${selectedOptionIndex === index ? 'selected' : ''}`} data-testid={selectedOptionIndex === index ? 'selected option' : ''}>
      <button className="msg-content" data-testid={`option-message${selectedOptionIndex === index ? ' selected' : ''}`} disabled={selectedOptionIndex!==null} onClick={()=>onSelectOption(index)}>
        {option.text}
      </button>
    </div>
  )
}
export default PrepopulateOptionItem;