import React, { FunctionComponent, useEffect } from 'react';
import { EAppStageContentType } from '../stage-container/stageContainer.interfaces';
import { useSearchParams } from 'react-router-dom';
import { selectedFilterTypeLocalStorageKey, settingsMenuParam, stageParam } from '../../../../app/constants';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { setAppModalContentType } from '../../../../shared/components/app-modals/appModals.store';
import { EAppModalContentType } from '../../../../shared/components/app-modals/appModals.interfaces';
import createAppOverlayPopover from '../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import removeAppOverlayPopover from '../../../../shared/components/app-overlay-popover/removeAppOverlayPopover';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../shared/components/confirm-modal/ConfirmModal';
import { resetBotResponse, setQueueMessageData } from '../../chat.store';
import { setSelectedMainTaskForEditing, setShouldOpenAddEditTaskFrom } from '../stage-container/stage-tasks/stageTasks.store';
import { setClickNavbarConfirmModalText } from '../../../../shared/store/shared.store';
import { EAPIStatus } from '../../../../shared/api/models';
import { setPlannerCurrentTaskPlacement } from '../stage-container/stage-planner/stagePlanner.store';
import { setShouldOpenWorkBlockDetails, setWorkBlockForEdit } from '../stage-container/work-block-details/workBlock.store';
import { uxEventEnterForm } from '../../../../shared/utils/uxEventsUtils';
import { EAIFormTypes } from '../../../ai-control-ux/AIControlUX.interface';
import { ETasksGroupsType } from '../stage-container/stage-tasks/stageTasks.interface';
import { getItemFromLocalStorage } from '../../../../shared/utils/localStorage.utils';

interface INavButtonProps {
  className: string;
  type: EAppStageContentType;
  SvgComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  InActiveSvgComponent?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  productTourDataAttr: string;
}

export const NavButton: FunctionComponent<INavButtonProps> = ({ className, type, SvgComponent, title, InActiveSvgComponent, productTourDataAttr }) => {
  const { appModalContentType } = useAppSelector(store => store.appModalsReducer);
  const { clickNavbarConfirmModalText } = useAppSelector(store => store.sharedStoreReducer);
  const { sessionResponse } = useAppSelector(store => store.chatReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    // open close settings modal by the params
    if (searchParams.get(settingsMenuParam) && appModalContentType !== EAppModalContentType.SETTINGS) {
      dispatch(setAppModalContentType(EAppModalContentType.SETTINGS));
    } else if (!searchParams.get(settingsMenuParam) && appModalContentType === EAppModalContentType.SETTINGS) {
      dispatch(setAppModalContentType(null));
    }
  }, [appModalContentType, dispatch, searchParams])

  const isTabActive = (tab: EAppStageContentType) => {
    // chat is the default
    if (!searchParams.get(stageParam) && tab === EAppStageContentType.CHAT) return true;
    return tab === searchParams.get(stageParam);
  }

  const onClickButton = (tab: EAppStageContentType) => {
    if (!!clickNavbarConfirmModalText) {
      createAppOverlayPopover(
        <ConfirmModal
          title={clickNavbarConfirmModalText || t("areYouSureNavbarDefaultConfirmText")}
          confirmBtnText={t("settingsResetProfileConfirmModalConfirmButtonText") + "."}
          onConfirm={() => { buttonAction(tab); removeAppOverlayPopover(); }}
          onCancel={() => removeAppOverlayPopover()}
        />
      )
    }
    else buttonAction(tab);
  }

  const buttonAction = (tab: EAppStageContentType) => {
    onCloseOpenedPopup();
    if (tab === EAppStageContentType.TASKS) dispatch(resetBotResponse());
    // by default delete the stage and the settings params 
    setSearchParams(prev => {
      prev.delete(settingsMenuParam);
      prev.delete(stageParam);
      return prev;
    });
    // if not chat replace the stageParam
    if (tab !== EAppStageContentType.CHAT) {
      setSearchParams(prev => {
        prev.append(stageParam, tab);
        return prev;
      });
    }
    sendSwitchTabUxEvent(tab);
  }

  const onCloseOpenedPopup = () => {
    dispatch(setSelectedMainTaskForEditing(null));
    dispatch(setShouldOpenAddEditTaskFrom(null));
    dispatch(setClickNavbarConfirmModalText(null));
    dispatch(setPlannerCurrentTaskPlacement(null));
    dispatch(setWorkBlockForEdit(null));
    dispatch(setShouldOpenWorkBlockDetails(false));
  }

  const sendSwitchTabUxEvent = (type: EAppStageContentType): void => {
    switch (type) {
      case EAppStageContentType.TASKS:
        dispatch(setQueueMessageData({ type: 'automatic', botRequestJson: JSON.stringify(uxEventEnterForm(EAIFormTypes.TASKLIST)) }));
        break;
      case EAppStageContentType.PLANNER:
        dispatch(setQueueMessageData({ type: 'automatic', botRequestJson: JSON.stringify(uxEventEnterForm(EAIFormTypes.PLAN, getItemFromLocalStorage<ETasksGroupsType>(selectedFilterTypeLocalStorageKey) || ETasksGroupsType.MY_PLAN)) }));
        break;
      case EAppStageContentType.SCRATCHPAD:
        dispatch(setQueueMessageData({ type: 'automatic', botRequestJson: JSON.stringify(uxEventEnterForm(EAIFormTypes.SCRATCHPAD)) }));
        break;
      default:
        break;
    }
  }

  return (
    <button disabled={sessionResponse.status === EAPIStatus.PENDING} className={`${className} ${isTabActive(type) ? 'active' : 'inActive'}`} onClick={() => onClickButton(type)} data-product-tour={productTourDataAttr}>
      {(!isTabActive(type) && !!InActiveSvgComponent) ? <InActiveSvgComponent className='navbar-icon'/> : <SvgComponent className='navbar-icon'/>}
      <span className='icon-title static-string'>{title}</span>
    </button>
  )
};