import { useState } from 'react';
import { useFileDownloader } from '../../../../hooks/useFileDownloader';
import { chatSessionIdLocalStorageKey } from '../../../../../app/constants';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { downloadSessionAsDocxAction } from '../../../../../features/chat-wrapper/chat.store';
import './DownloadSessionButton.scss';
import { useAppSelector } from '../../../../../app/store';

const DownloadSessionButton = () => {
  const {summariesResponse} = useAppSelector(store => store.chatReducer)
  const { downloadFile, isLoading, error } = useFileDownloader();
  const [sessionIdLocalStorage,] = useLocalStorage(chatSessionIdLocalStorageKey, '');
  const [selectedSession, setSelectedSession] = useState<string>(sessionIdLocalStorage);
  const tooltip = !!summariesResponse?.data ? "" : "Visit summaries page first to see full sessions list."

  const handleDownload = () => {
    downloadFile(downloadSessionAsDocxAction, selectedSession);
  };

  return (
    <div className='download-session settings-section-container' title={tooltip}>
      <select name="session-selector" 
              id="session-selector" 
              title={tooltip}
              value={selectedSession}
              onChange={e => {setSelectedSession(e.target.value)}}>
        {!summariesResponse?.data &&<option value={sessionIdLocalStorage} title={tooltip}>Current Session</option>}
        {(summariesResponse?.data || []).slice().sort((a,b) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime()).map(s => 
          <option key={s.sessionId} value={s.sessionId}>{s.sessionId === sessionIdLocalStorage ? 'Current Session' : s.sessionId}</option>
        )}
      </select>
      <button id="download-session" onClick={handleDownload} disabled={isLoading}  title={tooltip}>
        {isLoading ? 'Downloading...' : 'Download session'}
      </button>
      {error && <small>Download session error: {error}</small>}
    </div>
  )
}

export default DownloadSessionButton
