import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { workBlockDetailsFormParam, workBlockDetailsParam } from '../../../../../app/constants';
import { getTasksListReqAction, setCalendarSelectedWorkTime } from '../stage-tasks/stageTasks.store';
import { setShouldOpenWorkBlockDetails, setWorkBlockForEdit } from './workBlock.store';
import DialogWrapperWithAnimation from '../../../../../shared/components/dialog-wrapper-with-animation/DialogWrapperWithAnimation';
import { ReactComponent as backIconComponent } from "../../../../../assets/images/thicker-2px-stroke-back-icon.svg";
import { ReactComponent as closeIconComponent } from "../../../../../assets/images/close-create-task-modal-desk.svg";
import { useState } from 'react';
import WorkBlockDetailsForm from './work-block-details-form/WorkBlockDetailsForm';
import WorkBlockDetails from './work-block-details/WorkBlockDetails';
import './WorkBlockDetailsContainer.scss';

export type TWorkBlockDetailsContent = "showForm" | "showDetails";

const WorkBlockDetailsContainer = () => {
  const dispatch = useAppDispatch();
  const { workBlockForEdit } = useAppSelector(store => store.workBlockReducer);
  const [shouldCloseDialog, setShouldCloseDialog] = useState(false);
  const [viewType, setViewType] = useState<TWorkBlockDetailsContent>(!workBlockForEdit ? "showForm" : "showDetails");

  const getContent = () => {
    switch (viewType) {
      case "showDetails":
        return <WorkBlockDetails setViewType={setViewType} />;
      default:
        return <WorkBlockDetailsForm setShouldCloseDialog={setShouldCloseDialog} />
    }
  }

  const onDestroyAnimationEnd = () => {
    // when animation end - destroy the component
    dispatch(getTasksListReqAction());
    dispatch(setShouldOpenWorkBlockDetails(false));
    dispatch(setCalendarSelectedWorkTime(null));
    dispatch(setWorkBlockForEdit(null));
  }

  const onCloseModalWrapper = () => {
    if(viewType === "showForm" && !!workBlockForEdit) {
      setViewType("showDetails");
    }
  }

  const getCloseSvgByViewType = () => {
    if(viewType === "showForm" && !!workBlockForEdit) return backIconComponent;
    return closeIconComponent;
  }

  return (
    <DialogWrapperWithAnimation
      id="work-block-details"
      testId="work-block-modal"
      className={`work-block-dialog-container work-block-dialog-container--${!workBlockForEdit ? 'create' : 'update'}`}
      SvgCloseIcon={getCloseSvgByViewType()}
      onDestroyAnimationEnd={onDestroyAnimationEnd}
      searchParamToUpdate={viewType === "showForm" ? workBlockDetailsFormParam : workBlockDetailsParam}
      shouldCloseDialog={shouldCloseDialog}
      onClose={onCloseModalWrapper}
      shouldDestroyOnClose={viewType === "showDetails" || !workBlockForEdit}
    >
      { getContent() }
    </DialogWrapperWithAnimation>
  )
}

export default WorkBlockDetailsContainer
