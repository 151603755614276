import { store } from "../../app/store";
import { setPlannerCurrentTaskPlacement } from "../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store";
import { ETaskStatus, IMessageDataTask, IPlannerCurrentTaskPlacement } from "../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface";

export const onPlaceUnscheduledTask = (date: Date, workBlockId?: string, workBlockName?: string, isWorkBlockRecurring?: boolean, workBlockNextOrder?: number | null) => {
    const currentTaskPlacement = store.getState().StagePlannerReducer.currentTaskPlacement;
    
    const dispatch = store.dispatch;
    if (!currentTaskPlacement) return;
    const starDateISOString = date.toISOString();
    const workBlockInstance = workBlockId && isWorkBlockRecurring ? starDateISOString : null;
    const workBlockOrder = (!workBlockNextOrder && workBlockNextOrder !== 0) ? null : workBlockNextOrder;
    const modified: IPlannerCurrentTaskPlacement = Object.assign({}, currentTaskPlacement, {
        workTime: workBlockId ? null : starDateISOString,
        duration: workBlockId ? currentTaskPlacement.duration || null : currentTaskPlacement.duration || 3600,
        workBlockId: workBlockId || null,
        workBlockStartTime: workBlockId ? starDateISOString : null,
        workBlockName: workBlockName,
        workBlockInstance: workBlockInstance,
        workBlockOrder: workBlockOrder,
    });
    dispatch(setPlannerCurrentTaskPlacement(modified));
}

export const filterForUnscheduledTasks = (task: IMessageDataTask) => {
    return !task.workTime && !task.workBlockId && !task.workBlockInstance && !task.parentId && [ETaskStatus.NOT_STARTED, ETaskStatus.SCRATCHPAD].includes(task.status);
}