import React from 'react'
import { EClientFlags } from '../content-frame-wrapper/settings-menu/SettingsMenu.interfaces';
import AppButton from '../app-button/AppButton';
import { ReactComponent as CloseSvgComponent } from "../../../assets/images/close-popup.svg";
import { getItemFromLocalStorage } from '../../utils/localStorage.utils';
import { IUser } from '../../../app/auth/auth.interfaces';
import { userInfoLocalStorageKey } from '../../../app/constants';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { FlagUtils } from '../../utils/flagUtils';
import { updateUserProfileReq } from '../content-frame-wrapper/settings-menu/settingsMenu.store';
import { useTranslation } from 'react-i18next';
import './AppPopUpToast.scss';
import { openExternalLinkOnMobileApp } from '../../../mobile-application-utils';

export interface IAppPopUpToastProps {
  id: string;
  body: string;
  title?: string;
  CTA_Text?: string; // text of the link action
  CTA_Href?: string; // href of the link action
  CTA_action?: () => void; // action of the link action
  dontShowAgainFlag?: EClientFlags;
  dontShowAgainBtnText?: string;
  onClose?: () => void;
}

const AppPopUpToast: React.FC<IAppPopUpToastProps> = ({ body, title, CTA_Href, CTA_Text, CTA_action, dontShowAgainFlag, id, onClose, dontShowAgainBtnText }) => {
  const { updateUserProfile } = useAppSelector(store => store.settingsMenuReducer);
  const { userAppData } = useAppSelector(store => store.userReducer);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  
  const handleDontShowAgain = () => {
    const userInfo = getItemFromLocalStorage<IUser>(userInfoLocalStorageKey) || updateUserProfile?.data || userAppData.data?.user;
    if (!!dontShowAgainFlag && (!!userInfo?.clientFlags || userInfo?.clientFlags === 0)) {
      const updatedClientFlags = FlagUtils.setFlag(userInfo.clientFlags, dontShowAgainFlag);
      dispatch(updateUserProfileReq({ clientFlags: updatedClientFlags }));
    }
    onCloseToast();
  }

  const onCloseToast = () => {
    !!onClose && onClose();
  }

  return (
    <div className='app-pop-up-toast' id={id}>
      <AppButton id={`close-${id}`} className='app-pop-up-toast--close' onClick={onCloseToast}><CloseSvgComponent /></AppButton>
      {title && <h3 className='app-pop-up-toast--title static-string'>{t(title)}</h3>}
      <p className='app-pop-up-toast--body'>{t(body)}</p>
      <div className="app-pop-up-toast--actions">
        {(!!dontShowAgainFlag || dontShowAgainBtnText) &&
          <AppButton
            onClick={(dontShowAgainFlag && handleDontShowAgain) || onCloseToast}
            id={`dont-show-again-${id}`}
            className='app-pop-up-toast--actions--dont-show-again'>
            {t(dontShowAgainBtnText || "appPopUpToastDontShowAgainText")}
          </AppButton>
        }
        {(!!CTA_Href || CTA_Text) &&
          <a
            id={`CTA-${id}`}
            onClick={(e) => { 
              if (!CTA_Href) {
                e.preventDefault();
                if (CTA_action) CTA_action();
                return;
              }
              openExternalLinkOnMobileApp(e, CTA_Href); 
              if (CTA_action) CTA_action();
              onCloseToast();
            }}
            className='app-pop-up-toast--actions--cta'
            href={CTA_Href}
            aria-label={id + "-CTA"}
            target="_blank"
            rel="noreferrer">
            {t(CTA_Text || "")}
          </a>
        }
      </div>
    </div>
  )
}

export default AppPopUpToast