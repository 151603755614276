import { FunctionComponent, useState } from 'react'
import { IWorkBlockFormControllerProps } from '../../workBlock.interface'
import { ConfirmModal } from '../../../../../../../shared/components/confirm-modal/ConfirmModal'
import { useTranslation } from 'react-i18next';
import { ETaskStatus } from '../../../stage-tasks/stageTasks.interface';
import AppButton from '../../../../../../../shared/components/app-button/AppButton';
import { ReactComponent as TrashSvgComponent } from "../../../../../../../assets/images/add-edit-trash-mobile.svg";

const WorkBlockDeleteController: FunctionComponent<IWorkBlockFormControllerProps> = ({ onChangeFields }) => {
  const { t } = useTranslation();
  const [shouldDisplayDeleteConfirmModal, setShouldDisplayDeleteConfirmModal] = useState(false);

  return (
    <section className='action-btn-section'>
      <AppButton id="delete-work-block" className="delete-item" onClick={() => setShouldDisplayDeleteConfirmModal(true)}><TrashSvgComponent /></AppButton>
      {shouldDisplayDeleteConfirmModal &&
        <ConfirmModal
          title={t("deleteWorkBlockConfirmModalTitle")}
          informationText={t("deleteTaskConfirmModalSubTitle")}
          confirmBtnText={t("settingsResetProfileConfirmModalConfirmButtonText") + "."}
          onConfirm={() => onChangeFields({ status: ETaskStatus.DELETED })}
          onCancel={() => setShouldDisplayDeleteConfirmModal(false)}
        />}
    </section>
  )
}

export default WorkBlockDeleteController
