import React, { FunctionComponent, HTMLInputTypeAttribute } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { IProfileForm } from '../../SettingsMenu';
import { ErrorMessage } from '../../error-message/ErrorMessage';
import { handleInputKeyDown } from '../../../../../utils/utils';

interface IEditItemSectionProps {
  desktopLabel: string;
  mobileLabel: string;
  inputValue: string;
  inputName: keyof IProfileForm;
  isInputEditable: boolean;
  register: UseFormRegisterReturn<keyof IProfileForm>;
  onFocusInput: () => void
  shouldDisplayError: boolean;
  editTestId: string;
  inputType: HTMLInputTypeAttribute;
  inputClassName?: string;
  maxLengthInput?: number;
  className?: string;
}

export const EditItemSection: FunctionComponent<IEditItemSectionProps> = ({ inputName, inputClassName, editTestId, inputType, desktopLabel, mobileLabel, inputValue, isInputEditable, register, onFocusInput, shouldDisplayError, maxLengthInput, className='' }) => {

  return (
    <section className={className}>
      <label htmlFor={inputName} data-testid={editTestId} onClick={() => onFocusInput()}>
        <span className='desktop-label-text static-string'>{desktopLabel}</span>
        <span className='mobile-label-text static-string'>{mobileLabel}</span>
      </label>
      <div className='edit-input-container'>
        {isInputEditable
          ?
          <input
            className={inputClassName}
            type={inputType}
            id={inputName}
            autoFocus
            maxLength={maxLengthInput}
            data-testid={inputName}
            {...register}
            onKeyDown={handleInputKeyDown}
          />
          :
          <h4 onClick={() => onFocusInput()} data-testid={`${inputName}Title`}>{inputValue}</h4>
        }
      </div>
      {shouldDisplayError && <ErrorMessage />}
    </section>
  )
}