import { apiServiceCSharp, createApiThunk } from "../../shared/api/axios";
import { APIRequestState, IAPIRequestState } from "../../shared/api/models";
import { ApiDataStateType, createAPIReducerCases } from "../../shared/store/utils";
import { createAction, createReducer } from '@reduxjs/toolkit';
import { ILoginReqPayload, ILoginResponse, IMagicLinkReqPayload, IRegisterPayload, IRegisterPhoneNumberRequestPayload, IUser, IVerifyOtpPayload } from "./auth.interfaces";
import { API_ROUTES, refreshTokenLocalStorageKey, registrationParametersStorageKey, tokenLocalStorageKey } from "../constants";
import { setNoRedirectFlagInLocalStorage } from "./auth.utils";
import { RootState } from "../store";
import { getItemFromLocalStorage } from "../../shared/utils/localStorage.utils";

export interface IAuthState extends ApiDataStateType {
  loginRes: IAPIRequestState<ILoginResponse>;
  registrationRes: IAPIRequestState<IUser>;
  updatePhoneNumberRes: IAPIRequestState<IUser>;
  sendOtpRes: IAPIRequestState<boolean>;
  verifyOtpRes: IAPIRequestState<IUser>;
  magicLinkRes: IAPIRequestState<boolean>;
}

const initialStateAuthStore: IAuthState = {
  loginRes: APIRequestState.create(),
  registrationRes: APIRequestState.create(),
  updatePhoneNumberRes: APIRequestState.create(),
  sendOtpRes: APIRequestState.create(),
  verifyOtpRes: APIRequestState.create(),
  magicLinkRes: APIRequestState.create()
};

export const selectIsAuthenticated = (state: RootState): boolean => {
  const tokenFromState = !!state.authReducer.loginRes.data?.token || !!state.authReducer.loginRes.data?.refreshToken;
  const tokenFromStorage = !!getItemFromLocalStorage(tokenLocalStorageKey) || !!getItemFromLocalStorage(refreshTokenLocalStorageKey);
  return tokenFromState || tokenFromStorage;
};

const createReducerKey = (subKey: string): string => {
  return 'auth/' + subKey;
};

export const loginReqAction = createApiThunk(createReducerKey('loginReqAction'), (req?: { payload: ILoginReqPayload, loginType: string, signal?: AbortSignal }) =>
  apiServiceCSharp.post<ILoginResponse>(API_ROUTES.AUTH.LOGIN + req?.loginType, req?.payload, { signal: req?.signal })
);

export const registrationReqAction = createApiThunk(createReducerKey('registrationReqAction'), (payload?: IRegisterPayload) =>
  apiServiceCSharp.post<IUser>(API_ROUTES.AUTH.REGISTER, payload)
);

export const updatePhoneNumberReqAction = createApiThunk(createReducerKey('updatePhoneNumberReqAction'), (payload?: IRegisterPhoneNumberRequestPayload) =>
  apiServiceCSharp.patch<IUser>(API_ROUTES.USER.UPDATE_USER_INFO, payload)
);

export const sendOtpReqAction = createApiThunk(createReducerKey('sendOtpReqAction'), (payload?: { phoneNumber: string }) =>
  apiServiceCSharp.post<boolean>(API_ROUTES.AUTH.SEND_OTP, payload)
);

export const verifyOtpReqAction = createApiThunk(createReducerKey('verifyOtpReqAction'), (payload?: IVerifyOtpPayload) =>
  apiServiceCSharp.post<IUser>(API_ROUTES.AUTH.VERIFY_OTP, payload)
);

export const requestMagicLink = createApiThunk(createReducerKey('requestMagicLink'), (req?: { payload: IMagicLinkReqPayload, api: string }) =>
  apiServiceCSharp.post<boolean>(req?.api as string, req?.payload)
);

export const resetLoginResState = createAction(createReducerKey('resetLoginResState'));

export const authReducer = createReducer(initialStateAuthStore, (builder) => {
  createAPIReducerCases(loginReqAction, 'loginRes', builder, {
    onFulfilled() {
      setNoRedirectFlagInLocalStorage();
    }
  });
  createAPIReducerCases(registrationReqAction, 'registrationRes', builder,{
    onFulfilled(_, payload) {
     if (payload.id) {
      localStorage.removeItem(registrationParametersStorageKey);
     }
    }
  });
  createAPIReducerCases(updatePhoneNumberReqAction, 'updatePhoneNumberRes', builder);
  createAPIReducerCases(verifyOtpReqAction, 'verifyOtpRes', builder);
  createAPIReducerCases(sendOtpReqAction, 'sendOtpRes', builder); 
  createAPIReducerCases(requestMagicLink, 'magicLinkRes', builder);
  builder.addCase(resetLoginResState, (state) => {
    state.loginRes = APIRequestState.create();
  });
});