import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollToTheBottomOfScrollableEl } from '../../../../../shared/utils/scrollToTheBottomOfScrollableEl';
import '../Message.scss';

const ErrorMessage = ({errorText,className='',testId='reject-error'}:{errorText?:string;className?:string;testId?:string}) => {
  const { t } = useTranslation();

  useEffect(() => {
    scrollToTheBottomOfScrollableEl('.chat-container .conversation');
  },[]);

  return (
    <div className={`message reject-error ${className}`}>
      <div className="msg-content" data-testid={testId}>{errorText || t("somethingWentWrongCanYouTryAgainError")}</div>
    </div>
  )
}

export default memo(ErrorMessage);