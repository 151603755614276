import { activePlannerUnscheduledTasksPlacer, resetPlannerState } from "../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.utils";
import { useAppSelector } from "../../../app/store";
import { EPlannerMode } from "../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store";
import plannerOffLightIcon from '../../../assets/images/planner/plann-off-light.svg';
import plannerOffNotifLightIcon from '../../../assets/images/planner/planner-off-notif-light.svg';
import plannerOnIcon from '../../../assets/images/planner/planner-on.svg';
import plannerOnNotifIcon from '../../../assets/images/planner/planner-on-notif.svg';
import { useEffect, useState } from "react";
import { EAPIStatus } from "../../../shared/api/models";
import { filterForUnscheduledTasks } from "../plan.utils";
import AppButton from "../../../shared/components/app-button/AppButton";

export const PlanUnscheduledFab = ({planTourDataAttr}:{planTourDataAttr:string}) => {
    const {sessionResponse} = useAppSelector(store => store.chatReducer);
    const {plannerMode} = useAppSelector(store => store.StagePlannerReducer);
    const { allTasks } = useAppSelector(store => store.StageTasksReducer);
    const [totalUnscheduledTasks, setTotalUnscheduledTasks] = useState<number>(0);

    const onTogglePlanner = () => {
        switch (plannerMode) {
            case null:
                activePlannerUnscheduledTasksPlacer(true);
                break;
            case EPlannerMode.TIMEPICKER:
                resetPlannerState(true);
                activePlannerUnscheduledTasksPlacer(true);
                break;
            case EPlannerMode.UNSCHEDULEDTASKSPLACER:
                resetPlannerState(true);
                break;
            default:
                break;
        }    
    }

    useEffect(() => {
        const totalUnscheduledTasks = ((allTasks || [])).filter(filterForUnscheduledTasks).length;
        setTotalUnscheduledTasks(totalUnscheduledTasks);
    }, [allTasks]);

    const getImageBasedOnPlannerModeAndUnscheduledTasks = () => {
        if (plannerMode === EPlannerMode.UNSCHEDULEDTASKSPLACER) {
            return totalUnscheduledTasks > 0 ? plannerOnNotifIcon : plannerOnIcon;
        }
        return totalUnscheduledTasks > 0 ? plannerOffNotifLightIcon : plannerOffLightIcon
    }

    return (
        <AppButton data-plan-tour={planTourDataAttr} id="toggle-planner-mode" disabled={[EAPIStatus.PENDING, EAPIStatus.REJECTED].includes(sessionResponse.status)} type="button" className="planner-fab no-style-button" onClick={onTogglePlanner}>
            <img src={getImageBasedOnPlannerModeAndUnscheduledTasks()} alt="planner-fab" />
        </AppButton>
    )
}