import React, { FunctionComponent, useState } from 'react'
import { IRecurrenceValues } from '../../../stageTasks.interface';
import { getDayName } from '../../../../../../../../shared/utils/dateFormat';
import AppButton from '../../../../../../../../shared/components/app-button/AppButton';

interface ISelectCustomWeekDaysProps {
  setRecurrenceValues: React.Dispatch<React.SetStateAction<IRecurrenceValues>>;
  recurrenceValues: IRecurrenceValues;
  workTimeWeekDayIndx: number;
}

const SelectCustomWeekDays: FunctionComponent<ISelectCustomWeekDaysProps> = ({ recurrenceValues, setRecurrenceValues, workTimeWeekDayIndx }) => {
  const [selectedDays, setSelectedDays] = useState<number[]>(getInitialSelectedDays());

  function getInitialSelectedDays(){
    if(!!recurrenceValues?.workTimeRecurrenceWeekDays && recurrenceValues?.workTimeRecurrenceWeekDays.length > 0){
      return [...recurrenceValues.workTimeRecurrenceWeekDays];
    }
    return [workTimeWeekDayIndx];
  }

  const addDayIndx = (day: number) => {
    let tempSelectedDays = selectedDays;
    const dayIndx = selectedDays.findIndex(item => item === day);
    if (dayIndx >= 0) tempSelectedDays.splice(dayIndx, 1);
    else tempSelectedDays = [...tempSelectedDays, day];
    setSelectedDays([...tempSelectedDays]);
    setRecurrenceValues({ ...recurrenceValues, workTimeRecurrenceWeekDays: [...tempSelectedDays] });
  };

  return (
    <div className='recurrence-select-week-days'>
      {/* days index array [0,1,2,3,4,5,6] */}
      {Array.from(Array(7).keys()).map(dayIndx => {
        return (
          <AppButton
            id={`edit-overlay-add-custom-week-day-${getDayName(dayIndx)}`}
            className={`week-day ${selectedDays.includes(dayIndx) ? 'week-day--selected' : ''} static-string`}
            onClick={() => addDayIndx(dayIndx)}
            key={dayIndx}
          >
            {getDayName(dayIndx)}
          </AppButton>
        )
      })}
    </div>
  )
}

export default SelectCustomWeekDays
