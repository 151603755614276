import { useEffect, useState } from "react";
import sassVariables from '../../styles/style.module.scss';

export const useAppBackgroundByTimeOfDay = () => {
  const [appBackground, setAppBackground] = useState<{ desktopBG: string; mobileBG: string }>(getBackgroundByTimeOfDay());
  // Update every 1 hour
  const intervalTiming = 60 * 60 * 1000;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAppBackground(getBackgroundByTimeOfDay());
    }, intervalTiming);

    return () => clearInterval(intervalId);
  }, [intervalTiming]);

  return appBackground;
}

// Function to determine the time of day and return the corresponding CSS variable
function getBackgroundByTimeOfDay() {
  const hours = new Date().getHours();
  
  if (hours >= 11 && hours < 14) {
    return { desktopBG: sassVariables.desktopBG1, mobileBG: sassVariables.mobileBG1 };
  } else if ((hours >= 14 && hours < 17) || (hours >= 8 && hours < 11)) {
    return { desktopBG: sassVariables.desktopBG2, mobileBG: sassVariables.mobileBG2 };
  } else if ((hours >= 17 && hours < 20) || (hours >= 5 && hours < 8)) {
    return { desktopBG: sassVariables.desktopBG3, mobileBG: sassVariables.mobileBG3 };
  } else {
    return { desktopBG: sassVariables.desktopBG4, mobileBG: sassVariables.mobileBG4 };
  }
}