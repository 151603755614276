import { EOperatingSystem } from "../../../../app/auth/auth.interfaces";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { ApplicationInsightsApi } from "../../../../application-insights";
import removeAppOverlayPopover from "../../../../shared/components/app-overlay-popover/removeAppOverlayPopover";
import AppPopUpToast from "../../../../shared/components/app-pop-up-toast/AppPopUpToast";
import { EClientFlags } from "../../../../shared/components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces";
import { updateUserProfileReq } from "../../../../shared/components/content-frame-wrapper/settings-menu/settingsMenu.store";
import { FlagUtils } from "../../../../shared/utils/flagUtils";
import { getOperatingSystem } from "../../../../shared/utils/getMobileOperatingSystem";
import { isMobileDevice } from "../../../../shared/utils/isMobileDevice";
import { openUrlInNewTab } from "../../../../shared/utils/utils";

export const MobileAwarenessToast = () => {
    const { user } = useAppSelector(store => store.userReducer);
    const dispatch = useAppDispatch();
    const operatingSystem = getOperatingSystem();
    const linkToAppInStoreiOS = 'https://apps.apple.com/us/app/max-ai-learning-coach/id6737233778';
    const linkToAppInStoreAndroid = 'https://play.google.com/store/apps/details?id=com.maximal_learning.mobile';
    const linkToAppInStore = operatingSystem === EOperatingSystem.IOS ? linkToAppInStoreiOS : linkToAppInStoreAndroid;
    const toastBody = isMobileDevice() ? 'mobileAppAwarenessMessagePhone' : 'mobileAppAwarenessMessageDesktop';
    const ctaText = isMobileDevice() ? 'mobileAppAwarenessCTAPhone' : 'mobileAppAwarenessCTADesktop';

    const handleCTA = () => {
        if (isMobileDevice()) openUrlInNewTab(linkToAppInStore);
        handleUpdateClientFlags();
        removeAppOverlayPopover();
    }

    const handleUpdateClientFlags = () => {
        const clientFlags = user?.clientFlags || 0;
        const updatedClientFlags = FlagUtils.setFlag(clientFlags, EClientFlags.DONT_SHOW_MOBILE_AWARENESS);
        dispatch(updateUserProfileReq({ clientFlags: updatedClientFlags }))
            .catch(() => {
                ApplicationInsightsApi.trackException(new Error('Failed to close proactive survey popup'));
            });
    }

    return (
        <AppPopUpToast
            id="mobile-awareness-toast"
            title="mobileAppAwarenessTitle"
            body={toastBody}
            CTA_Text={ctaText}
            CTA_Href={isMobileDevice() ? linkToAppInStore : undefined}
            dontShowAgainBtnText="mobileAppAwarenessRemindMeLater" 
            CTA_action={handleCTA} 
            onClose={() => removeAppOverlayPopover()} />
    );
}