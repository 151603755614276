import { useParams } from 'react-router-dom';
import { Redirect } from '../../app/guards/Redirect';
import { sessionSourceTypeStorageKey } from '../../app/constants';

export const SessionSourceExtraction = () => {
    const { sessionSourceTypeId } = useParams();
    if (sessionSourceTypeId) {
        localStorage.setItem(sessionSourceTypeStorageKey, JSON.stringify(sessionSourceTypeId))
    }
    else{
        localStorage.removeItem(sessionSourceTypeStorageKey);
    }
     return (Redirect());
}