import { RefObject } from "react";
import { DropTargetMonitor } from "react-dnd";
import { store } from "../../../../app/store";
import { getDomElementByQuerySelector, getAllDomElementsByQuerySelector } from "../../../../shared/utils/utils";
import { setCalendarFirstCellVisiblePortion, setDragAndDropCalendarDimensions } from "../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store";
import { calendarHourLineElementClassName, calendarHourLineTransparentElClassName } from "../../../../app/constants";
import { ICalendarEvent } from "./CalendarDay";
import { IDraggedPreviewStyle } from "../../../../shared/components/dragged-destination-preview/DraggableWrapper";

export interface IDragItem extends IDraggedPreviewStyle {
  event: ICalendarEvent;
  isComeFromWorkBlock?: boolean;
  snapYDroppedPosition?: number;
}

export interface IDragAndDropCalendarDimensions {
  cellWidth: number;
  cellHeight: number;
  calendarContainerStartX:number;
  calendarContainerStartY:number;
  calendarContainerWidth: number;
  calendarContainerHeight:number;
  calendarContainerScrollTop:number;
}

export const calcCalendarDragAndDropDimensions = () => {
  const calendarContainer = getDomElementByQuerySelector(`.plan-day-card-container--inview .plan-day-card-content--calendar-day`);
  if(!calendarContainer) return;
  const calendarContainerRect =  calendarContainer?.getBoundingClientRect();
  const calendarContainerStartX = calendarContainerRect?.x;
  const calendarContainerStartY = calendarContainerRect?.y;
  
  const calendarCellElement = getDomElementByQuerySelector(`.${calendarHourLineTransparentElClassName}`);
  if (calendarCellElement) {
    const calendarCellElementRect = calendarCellElement.getBoundingClientRect();
    const cellHeight = calendarCellElementRect.height;
    const cellWidth = Number(calendarCellElementRect.width.toFixed(2));
    const firstCellVisiblePortion = cellHeight - (calendarContainer.scrollTop % cellHeight);
    
    store.dispatch(setCalendarFirstCellVisiblePortion(firstCellVisiblePortion));
    store.dispatch(setDragAndDropCalendarDimensions(
      { 
        cellWidth, 
        cellHeight,
        calendarContainerStartX,
        calendarContainerStartY,
        calendarContainerWidth: calendarContainerRect.width,
        calendarContainerHeight: calendarContainerRect.height,
        calendarContainerScrollTop: calendarContainer.scrollTop,
      }
  ));
  }
}

// get the closest hour line element to the start time of the dropped item and return the hour and minutes this line element presents
export const getDropTimeOnCalendar = (droppedItem: IDragItem, dayIndex: number, cellHeight:number): { hour: number, minutes: number } => {
  const snapYDroppedPosition = Math.round(droppedItem?.snapYDroppedPosition || 0);
  let hour = 0;
  let minutes = 0;
  let lineHourElTopPosition: number | null = null
  let foundLine = false;
  getAllDomElementsByQuerySelector(`.${calendarHourLineElementClassName}.dayIndx-${dayIndex}:not(.${calendarHourLineTransparentElClassName})`)
  ?.forEach(el => {
    lineHourElTopPosition = el.getBoundingClientRect().top;
    if (!foundLine && snapYDroppedPosition <= lineHourElTopPosition +cellHeight) {
        hour = Number(el.getAttribute("data-hour"));
        minutes = Number(el.getAttribute("data-minutes"));
        foundLine = true;
      }
    })

  return { hour, minutes };
}

export const autoScrollWhenDraggingNearTheTopOrBottom = (monitor: DropTargetMonitor<IDragItem, unknown>,scrollableContainerRef: RefObject<HTMLDivElement>) => {
  // Auto-scroll the scrollable container when dragging near the top/bottom
  const scrollThreshold = 40; // Distance from the top/bottom to trigger scrolling
  const scrollSpeed = 6; // Speed of the scrolling
  const clientOffset = monitor.getClientOffset();
  const scrollableContainer = scrollableContainerRef.current;
  if(!scrollableContainer) return;
  const boundingRect = scrollableContainer.getBoundingClientRect();

  if (clientOffset) {
      const topDistance = clientOffset.y - boundingRect.top;
      const bottomDistance = boundingRect.bottom - clientOffset.y;

      // Auto-scroll up if near the top
      if (topDistance < scrollThreshold) {
          scrollableContainer.scrollTop -= scrollSpeed;
      }

      // Auto-scroll down if near the bottom
      if (bottomDistance < scrollThreshold) {
          scrollableContainer.scrollTop += scrollSpeed;
      }
  }
}