import { useCallback, useEffect, useRef } from 'react';
import { ApplicationInsightsApi } from '../../application-insights';

interface ILongPressOptions {
  delay?: number;
  scrollThreshold?: number;
}

const useLongPress = ({ delay = 500, scrollThreshold = 10  }: ILongPressOptions = {}) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const startXRef = useRef<number | null>(null);
  const startYRef = useRef<number | null>(null);

  const start = useCallback((onLongPress: () => void) => {
    timeoutRef.current = setTimeout(() => {
      onLongPress();
    }, delay);
  }, [delay]);

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => {
    return () => clear();
  }, [clear]);

  // prevent from long press triggered while the user is scrolling
  const handleTouchMove = useCallback((e: React.TouchEvent) => {
    try {
      const moveX = e.touches[0].clientX;
      const moveY = e.touches[0].clientY;

      // calculate the distance moved
      if (startXRef.current !== null && startYRef.current !== null) {
        const distanceX = Math.abs(moveX - startXRef.current);
        const distanceY = Math.abs(moveY - startYRef.current);

        // if movement exceeds threshold (the user is scrolling), cancel long press
        if (distanceX > scrollThreshold || distanceY > scrollThreshold) {
          clear();
        }
      }
    } catch(e) {
      ApplicationInsightsApi.trackException(e);
      console.error("Prevent from long-press event triggered while the user is scrolling is failed with error: ", e);
    }
  }, [clear, scrollThreshold]);

  const bind = (onLongPress: () => void) => {
    return {
      onTouchStart: (e: React.TouchEvent) => {
        e.preventDefault();
        // record initial touch position
        startXRef.current = e.touches[0].clientX;
        startYRef.current = e.touches[0].clientY;
        start(onLongPress);
      },
      onTouchMove: handleTouchMove,
      onTouchEnd: () => {
        clear();
      },
      onTouchCancel: () => {
        clear();
      },
    };
  };

  return bind;
};

export default useLongPress;
