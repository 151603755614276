import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes, SeverityLevel } from '@microsoft/applicationinsights-web';
import { chatSessionIdLocalStorageKey, userInfoLocalStorageKey } from './app/constants';
import { IUser } from './app/auth/auth.interfaces';
import { getItemFromLocalStorage } from './shared/utils/localStorage.utils';

export var appInsights: ApplicationInsights;
export var reactPlugin: ReactPlugin;
export var clickPlugin: ClickAnalyticsPlugin

let isUserContextSet = false;

export const initAppInsights = () => {
    if (process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING) {
        reactPlugin = new ReactPlugin();
        clickPlugin = new ClickAnalyticsPlugin();
        appInsights = new ApplicationInsights({
            config: {
                connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING,
                enableAutoRouteTracking: true,
                distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
                enableCorsCorrelation: true,
                extensions: [reactPlugin, clickPlugin],
                extensionConfig: {
                    [clickPlugin.identifier]: { autoCapture: true, dataTags: { useDefaultContentNameOrId: true } }
                }
            }
        });

        appInsights.loadAppInsights();
    }
}

export abstract class ApplicationInsightsApi {
    static trackException(error: any): void {
        try {
            const sessionId = getItemFromLocalStorage<string>(chatSessionIdLocalStorageKey);
            const userInfo = getItemFromLocalStorage<IUser>(userInfoLocalStorageKey);
            appInsights?.trackException(
                { exception: new Error(typeof error === "string" ? error : error?.message || JSON.stringify(error)), severityLevel: SeverityLevel.Error },
                {
                    sessionId: sessionId ? sessionId : "sessionId_not_available",
                    userId: userInfo ? userInfo?.id : "userId_not_available"
                }
            )
        } catch (error: any) {
            appInsights?.trackException({ exception: new Error(typeof error === "string" ? error : error?.message || error?.error?.message || "error message not available"), severityLevel: SeverityLevel.Error })
        }
    }

    static trackEvent(name: string, event?: { [key: string]: any }): void {
        try {
            appInsights?.trackEvent({ name, properties: event });
        } catch (error: any) {
            appInsights?.trackException({ exception: new Error(error?.message || error?.error?.message || "error message not available"), severityLevel: SeverityLevel.Error })
        }
    }
}

// https://learn.microsoft.com/en-us/azure/azure-monitor/app/api-custom-events-metrics#authenticated-users
export function setAppInsightsUserContext() {
    try {
        if (!isUserContextSet) {
            const user = getItemFromLocalStorage<IUser>(userInfoLocalStorageKey);
            if (appInsights && user) {
                // must not include spaces or any of the characters ,;=|
                const validUserId = user.id.replace(/[,;=| ]+/g, "_");
                appInsights.setAuthenticatedUserContext(validUserId, undefined, true);
                isUserContextSet = true;
            }
        }
    } catch (error) {
        ApplicationInsightsApi.trackException(error)
    }
} 

export enum ELogLabels {
    SELECT = "Select",
}