import { useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { IUser } from '../../../../app/auth/auth.interfaces';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { isDesktopView, supportedCountries, supportedCountriesInternal } from '../../../utils/utils';
import { userInfoLocalStorageKey, userPhoneCountryTwoLetterCodeLocalStorageKey, userPhoneNumberLocalStorageKey } from '../../../../app/constants';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { sendOtpReqAction, updatePhoneNumberReqAction } from '../../../../app/auth/auth.store';
import { useApiData } from '../../../hooks/useApiData';
import phoneMascotImg from '../../../../assets/images/mascot/phone-mascot.png';
import { ContactUs } from '../../../../app/auth/register/contact-us/ContactUs';
import { parsePhoneNumber } from 'react-phone-number-input';
import { ApplicationInsightsApi } from '../../../../application-insights';
import { AppPhoneControl, IFormData } from '../../app-phone-control/AppPhoneControl';
import './EnterPhoneAndGetOtpForm.scss';

interface IEnterPhoneAndGetOtpFormProps {
  onFulfilledSendOtp: () => void;
  title: string;
  subTitle: string;
  className?: string;
  defaultPhoneNumber?: string;
  isRegistrationFlow?: boolean;
  trackEventName: string;
}

const EnterPhoneAndGetOtpForm = ({ onFulfilledSendOtp, className = '', title, subTitle, trackEventName, defaultPhoneNumber = '', isRegistrationFlow=false }: IEnterPhoneAndGetOtpFormProps) => {
  const phoneForm = useForm<IFormData>({
    defaultValues: {
      phone: '',
      country: initCountry()
    }
  });
  const { control, handleSubmit, setFocus, formState, reset } = phoneForm;
  const { sendOtpRes, registrationRes } = useAppSelector(store => store.authReducer);
  const { userAppData } = useAppSelector(store => store.userReducer);
  const [userInfo,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const [, setPhoneNumber] = useLocalStorage<string | null>(userPhoneNumberLocalStorageKey, null);
  const isInternalUser = userAppData?.data?.user?.isInternalUser || registrationRes?.data?.isInternalUser || userInfo?.isInternalUser || false;
  const [, setPhoneCountryTwoLetterCode] = useLocalStorage<string | null>(userPhoneCountryTwoLetterCodeLocalStorageKey, null);
  const supportedCountriesList = isInternalUser ? supportedCountriesInternal : supportedCountries;
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const resetPhoneTimer = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const countryWatch = useWatch({ control, name: 'country'});

  useEffect(() => {
    // set auto focus on the userPhone input
    if (isDesktopView()) setFocus(`phone`);
    reset({ 'phone': defaultPhoneNumber || '', 'country': initCountry() });

    return () => {
      // clear Timeout when the component destroyed
      if (resetPhoneTimer.current) clearTimeout(resetPhoneTimer.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPhoneNumber, reset, setFocus])

  function initCountry() {
    if (defaultPhoneNumber) {
      const phoneNumberObj = parsePhoneNumber(defaultPhoneNumber);
      if (phoneNumberObj && phoneNumberObj.country) {
        return phoneNumberObj.country;
      }
    } return 'US';
  };

  // concat the user phone number to a string and send to the server.
  const onSubmit = useCallback((formData: IFormData) => {
    setPhoneCountryTwoLetterCode(formData.country);
    const phoneNumber = `${formData.phone.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').trim()}`;
    setPhoneNumber(phoneNumber);
    dispatch(sendOtpReqAction({ phoneNumber: `${phoneNumber}` }))
      .unwrap()
      .then((sendOtpResData) => {
        if (sendOtpResData) {
          onFulfilledSendOtp();
          ApplicationInsightsApi.trackEvent(trackEventName, {request: { phoneNumber: `${phoneNumber}` }, response: sendOtpResData});
        }
      })
  }, [setPhoneCountryTwoLetterCode, setPhoneNumber, dispatch, onFulfilledSendOtp, trackEventName]);

  // reset and set focus the phone text-box input on Rejected after 500ms
  const resetPhoneInput = () => {
    if (resetPhoneTimer.current) clearTimeout(resetPhoneTimer.current);
    resetPhoneTimer.current = setTimeout(() => {
      setFocus('phone');
      reset({ 'phone': '' });
    }, 500);
  }

  useApiData(sendOtpRes, {
    //  reset phone input when registration API rejected
    onRejected(e) {
      setShouldDisplayError(true);
      resetPhoneInput();
      ApplicationInsightsApi.trackException(`${trackEventName} sendOtpRequest failed with error: ${e}`);
    },
  });

  const handleUnsupportedCountry = useCallback(() => {
    if (supportedCountriesList.includes(countryWatch)) return;
    dispatch(updatePhoneNumberReqAction({ phoneCountryTwoLetterCode: countryWatch }));
  }, [countryWatch, dispatch, supportedCountriesList]);

  useEffect(() => {
    if(isRegistrationFlow) handleUnsupportedCountry();
  }, [handleUnsupportedCountry, isRegistrationFlow]);

  return (
    <FormProvider {...phoneForm}>
    <form className={`scroll-y-container-hidden-scroll-bar phone-verification-flow enter-phone-number-get-otp ${className}`} id="update-phone-form" onSubmit={handleSubmit(onSubmit)} data-testid="update-phone-form">
      <div className='update-phone-form-content'>
        <div className='title-container'>
          <img src={phoneMascotImg} alt="mascot" className='mascot' />
          <h1 className='title'>{title}</h1>
        </div>
        <p className='add-phone-registration-permission-text add-phone-registration-permission-text--main'>{subTitle}</p>
        <AppPhoneControl
          className='phone-controllers-container' 
          errorText={shouldDisplayError ? t("enterValidPhoneNumberError") : ""}
        />
        <button className='auth-next-btn submit' form="update-phone-form" id="update-phone-form-submit" data-testid="update-phone-form-submit" type='submit' disabled={!formState.isValid || formState.isSubmitted}>{t('addPhoneRegistrationScreenSendBtnText')}</button>
        {!supportedCountriesList.includes(countryWatch) && <div>
          <p className='add-phone-registration-permission-text'>{t("addPhoneRegistrationScreenPermissionText3")}</p>
          <p className='add-phone-registration-permission-text'>{t("addPhoneRegistrationScreenPermissionText4")}</p>
          <p className='add-phone-registration-permission-text'>{userInfo?.email}</p>
        </div>}
      </div>
      {(supportedCountriesList.includes(countryWatch)) ? <p className='agreement-paragraph' data-testid="update-phone-agreement">
            {t("phoneRegistrationScreenParagraph2NewFlow")}
          </p> : <ContactUs />}
    </form>
    </FormProvider>
  )
}

export default EnterPhoneAndGetOtpForm