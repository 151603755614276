import { planTourDataAttrChangeViewsDesk, planTourDataAttrChangeViewsMobile, planTourDataAttrNavigateDesk, planTourDataAttrNavigateMobile, planTourDataAttrPlannerDesk, planTourDataAttrPlannerMobile } from '../../../app/constants';
import {ReactComponent as MascotComponent1} from '../../../assets/images/product-tour/mascot-1.svg';
import { useTranslation } from 'react-i18next';
import { IProductTour } from '../ProductTour.utils';
import { isMobileView } from '../../../shared/utils/utils';
import { activePlannerUnscheduledTasksPlacer, resetPlannerState } from '../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.utils';

export const PlanTourArray = ():IProductTour[] => {
  const { t } = useTranslation();

  const openPlanner = () => {
    activePlannerUnscheduledTasksPlacer(true);
  }

  const closePlanner = () => {
    resetPlannerState(true);
  }

  return (
    [
      {
        title: t("planTourTitle1"),
        icon: <MascotComponent1 />,
        text: t("planTourParagraph1"),
        next: () => {},
        prev: () => {},
        target: null,
      },
      { // Change views
        title: t("planTourTitle2"),
        text: t("planTourCard2SubTitle2"),
        list: [
          {
            title: t("planTourCard2ListBullet1Title"), 
            text: t("planTourCard2ListBullet1Text")
          },
          {
            title: t("planTourCard2ListBullet2Title"), 
            text: t("planTourCard2ListBullet2Text")
          },
          {
            title: t("planTourCard2ListBullet3Title"), 
            text: t("planTourCard2ListBullet3Text")
          }
        ],
        next: () => {},
        prev: () => {},
        target: planTourDataAttrChangeViewsDesk,
        mobileTarget: planTourDataAttrChangeViewsMobile,
      },
      { // Navigate
        title: t("planTourTitle3"),
        text: isMobileView() ? t("planTourParagraph3Mobile") : t("planTourParagraph3Desktop"),
        next: () => {},
        prev: () => {},
        target: planTourDataAttrNavigateDesk,
        mobileTarget: planTourDataAttrNavigateMobile,
      },
      { // Check your planner
        title: t("planTourTitle4"),
        text: t("planTourParagraph4"),
        next: () => openPlanner(),
        prev: () => {},
        target: planTourDataAttrPlannerDesk,
        mobileTarget: planTourDataAttrPlannerMobile,
      },
      { // Schedule Tasks 
        title: t("planTourTitle5"),
        text: t("planTourParagraph5"),
        next: () => closePlanner(),
        prev: () => closePlanner(),
        target: null,
        cardClassName: "plan-tour-card--opened-planner",
      },
      { 
        title: t("planTourTitle6"),
        text: t("planTourParagraph6"),
        next: () => {},
        prev: () => openPlanner(),
        target: null
      },
    ]
  )
}