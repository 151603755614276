import { Fragment, useCallback, useEffect, useState } from 'react';
import SingleTaskItemContainer, { ETaskViewType } from '../../chat-wrapper/chat/chat-conversation/message-data-card/tasks-list/tasks-list-single-item/SingleTaskItemContainer';
import { addDaysToDate, getDateBasedOnDayIndexAndWeekOffset, getDateWithoutYear, getDateWithYear } from '../../../shared/utils/dateFormat';
import { ETaskFormType, ETasksGroupsType, ETaskSource, ETaskStatus, IMessageDataTask } from '../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { store, useAppDispatch, useAppSelector } from '../../../app/store';
import { useTranslation } from 'react-i18next';
import { EPlanDayCardDisplayType } from './PlanDayCard';
import { setActiveMyPlanDay, setSelectedMainTaskForEditing, setShouldOpenAddEditTaskFrom, setTasksFilter } from '../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import { PlanDesktopNavigation } from '../plan-desktop-navigation/PlanDesktopNavigation';
import SassVariables from "../../../styles/style.module.scss";
import dueIcon from '../../../assets/images/single-task/calendar-due-date-dark.svg';
import { getExternalEventSourceDetails } from '../../../shared/utils/utils';

interface IProps {
    index: number;
    daysToDisplay: number;
    displayType: EPlanDayCardDisplayType;
    dayIndex: number;
}

export const PlanDayCardDueSection = ({ index, daysToDisplay, displayType, dayIndex }: IProps) => {
    const [tasksDue, setTasksDue] = useState<IMessageDataTask[]>([]);
    const { allTasks } = useAppSelector(store => store.StageTasksReducer);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const cardDate = useCallback((): string => {
        switch (displayType) {
            case EPlanDayCardDisplayType.MY_PLAN:
            case EPlanDayCardDisplayType.MY_DAY:
                return getDateWithYear(addDaysToDate(new Date(), index * daysToDisplay + dayIndex).toISOString());
            case EPlanDayCardDisplayType.MY_WEEK:
                if (daysToDisplay === 7) return getDateWithYear(getDateBasedOnDayIndexAndWeekOffset(dayIndex, index).toISOString());
                return getDateWithYear(addDaysToDate(new Date(), index * daysToDisplay + dayIndex).toISOString());
        }
    }, [dayIndex, daysToDisplay, displayType, index]);

    const getTaskListItemViewType = (planViewType: EPlanDayCardDisplayType): ETaskViewType => {
        switch (planViewType) {
            case EPlanDayCardDisplayType.MY_PLAN:
                return ETaskViewType.MY_PLAN_DUE;
            case EPlanDayCardDisplayType.MY_DAY:
                return ETaskViewType.MY_TASKS;
            case EPlanDayCardDisplayType.MY_WEEK:
                return ETaskViewType.MY_WEEK;
            default:
                return ETaskViewType.MY_PLAN_DUE;
        }
    }

    const getTasksDue = useCallback(() => {
        // display unique tasks based on id to avoid duplicates for tasks with recurrent work times that have a due date on this day as well
        const unique = allTasks.reduce((acc: { [key: string]: IMessageDataTask }, current) => {
            if (current.isMultiWorkTimeTask) return acc;
            if (current.status === ETaskStatus.DELETED) return acc;
            if (!current.dueDate && !current.isFullDayEvent) return acc;
            if (current.isEvent && !current.isFullDayEvent) return acc;
            const taskDate = getDateWithYear(current.dueDate as string);
            const taskWorkDate = getDateWithYear(current.workTime as string);
            if (taskDate === cardDate() || (taskWorkDate === cardDate() && current.isFullDayEvent)) acc[current.id!] = current;
            return acc;
        }, {});
        const tasksDue = Object.values(unique);
        tasksDue.sort((a, b) => {
            if (a.isFullDayEvent !== b.isFullDayEvent) {
              return a.isFullDayEvent ? -1 : 1;
            }
            const dateA = new Date(a.dueDate || "").getTime();
            const dateB = new Date(b.dueDate || "").getTime();
            return dateA - dateB;
          });
        setTasksDue(tasksDue);
    }, [allTasks, cardDate]);

    const dateText = () => {
        switch (displayType) {
            case EPlanDayCardDisplayType.MY_PLAN:
            case EPlanDayCardDisplayType.MY_DAY:
                return getDateWithoutYear(addDaysToDate(new Date(), index * daysToDisplay + dayIndex).toISOString(), false, false).replace(/,/g, '');
            case EPlanDayCardDisplayType.MY_WEEK:
                if (daysToDisplay === 7) return getDateWithoutYear(getDateBasedOnDayIndexAndWeekOffset(dayIndex, index).toISOString(), false, true).replace(/,/g, '');
                return getDateWithoutYear(addDaysToDate(new Date(), index * daysToDisplay + dayIndex).toISOString(), false, true).replace(/,/g, '');
        }
    }

    const handleEventClick = (task: IMessageDataTask) => {
        const mainTask = store.getState().StageTasksReducer.allTasks.find(t => task.parentId ? t.id === task.parentId : t.id === task.id);
        if (mainTask) {
            dispatch(setSelectedMainTaskForEditing(mainTask));
            dispatch(setShouldOpenAddEditTaskFrom(task.isEvent ? ETaskFormType.Event : ETaskFormType.Task));
        }
    }

    const renderFullDayEvent = (task: IMessageDataTask) => {
        const backgroundColor = (task.isEvent && task?.source !== ETaskSource.Internal) ? SassVariables.Neutral3Color : task?.tags && task?.tags?.length > 0 ? task.tags[0].color ? task.tags[0].color === "transparent" ? "#FFF" : task.tags[0].color : "#FFF" : "#FFF";
        const titleColor = backgroundColor !== 'transparent' && backgroundColor !== '#FFF' ? 'white' : SassVariables.MaxDarkColor;
        return (<div onClick={() => handleEventClick(task)} className={`calendar-event plan-day-card-full-day-due-item-container clickable-element`}>
            <div className={`plan-day-card-full-day-item-container-inner-container`} style={{ backgroundColor: backgroundColor, border: `${backgroundColor === "#FFF" ? "1px solid " + SassVariables.MaxDarkColor : 'none'}` }}>
                <div className="plan-day-card-full-day-text-container">
                    <h3 className='plan-day-card-full-day-title' style={{ color: titleColor }}>{task.name}</h3>
                    <div className='plan-day-card-full-day-addition-text-container' >
                       {!task.isEvent && <img src={dueIcon} alt="recurrence-icon" style={{ filter: backgroundColor !== 'transparent' && backgroundColor !== '#FFF' ? 'brightness(5)' : 'none' }} />}
                        <span className='plan-day-card-full-day-addition-text' style={{ filter: backgroundColor !== 'transparent' && backgroundColor !== '#FFF' ? 'brightness(5)' : 'none' }}>
                            {task.isEvent ? getExternalEventSourceDetails(task.source) : t('allDayEvent')}</span>
                    </div>
                </div>
            </div>
        </div>)
    }

    const isCurrentDay = (dayIndex: number) => {
        const currentDate = new Date();
        const cardDate = displayType === EPlanDayCardDisplayType.MY_WEEK && daysToDisplay === 7 ? getDateBasedOnDayIndexAndWeekOffset(dayIndex, index) : addDaysToDate(currentDate, index * daysToDisplay + dayIndex);
        return cardDate.toDateString() === currentDate.toDateString();
    
    }

    const goToMyPlan = () => {
        dispatch(setTasksFilter(ETasksGroupsType.MY_PLAN));
        const timeDiff = daysToDisplay === 7 ? getDateBasedOnDayIndexAndWeekOffset(dayIndex, index).getTime() - new Date().getTime() : addDaysToDate(new Date(), index * daysToDisplay + dayIndex).getTime() - new Date().getTime();
        const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        dispatch(setActiveMyPlanDay(`day-${daysDiff}`));
    }

    useEffect(() => {
        getTasksDue();
    }, [getTasksDue]);

    return (
        <div key={dayIndex} className={`plan-day-header-and-due-tasks-inner-container${isCurrentDay(dayIndex) ? ' plan-day-header-and-due-tasks-inner-container--today' : ''}`}>
            <div className='plan-card-header'>
                <h2 className={`plan-card-header-day${isCurrentDay(dayIndex) ? ' plan-card-header-day--today' : ''} static-string`}>{dateText()}</h2>
                <PlanDesktopNavigation />
            </div>
            <div className={`plan-day-card-content--due-tasks plan-day-card-content--due-tasks--total-${tasksDue.length}`}>
                <p className='plan-day-card-empty-filter'>{t("myPlanDayNoDue")}</p>
                <div className='plan-day-card-content-scrollable-container'>
                    <div>
                        {(displayType === EPlanDayCardDisplayType.MY_WEEK ? tasksDue.slice(0, 3) : tasksDue).map((t) => <Fragment key={t.id}>{t.isFullDayEvent ? renderFullDayEvent(t) : <SingleTaskItemContainer viewType={getTaskListItemViewType(displayType)} shouldHideOnComplete={false} singleTask={t} shouldDisplayFirstTagColor={true} shouldDisplayActionButtons={true} />}</Fragment>)}
                    </div>
                    {tasksDue.length > 3 && displayType === EPlanDayCardDisplayType.MY_WEEK && <div className='click-for-more-container clickable-element' onClick={goToMyPlan}>
                        <span>{t('andMore').replace(' ', ` ${tasksDue.length - 3} `)} →</span>
                    </div>}
                </div>
            </div>
        </div>
    )
}