import { EPlanDayCardDisplayType, PlanDayCard } from "../plan-day-card/PlanDayCard";

interface MyDayProps {
    myPlanDays?: number;
  }

export const MyDay = ({myPlanDays}: MyDayProps) => {
    return (
        <>
        {Array.from({ length: myPlanDays || 0 }).map((_, index) => (
          <PlanDayCard key={index} index={index} displayType={EPlanDayCardDisplayType.MY_DAY} />
        ))}
      </>
    )
}