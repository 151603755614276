import { FunctionComponent, useCallback, useEffect, useState } from "react";
import ProductTour from "./ProductTour";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { getItemFromLocalStorage } from "../../shared/utils/localStorage.utils";
import { IUser } from "../../app/auth/auth.interfaces";
import { userInfoLocalStorageKey } from "../../app/constants";
import { isProductTourAlreadyDisplayed } from "./ProductTour.utils";
import { useApiData } from "../../shared/hooks/useApiData";
import { FlagUtils } from "../../shared/utils/flagUtils";
import { EClientFlags } from "../../shared/components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces";
import { updateUserProfileReq } from "../../shared/components/content-frame-wrapper/settings-menu/settingsMenu.store";
import { isClientFlagOn } from "../../shared/utils/isClientFlagOn";
import { ApplicationInsightsApi } from "../../application-insights";
import { setTasksFilter } from "../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store";
import { ETasksGroupsType } from "../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface";
import { setShouldDisplayProductTour } from "../../shared/store/shared.store";

export enum EViewType {
  PRODUCT_TOUR = 1,
  PLAN_TOUR,
}

export interface IProductTourWrapperProps {
  dataAttribute: string;
  className?: string;
  viewType: EViewType;
}

const ProductTourWrapper: FunctionComponent<IProductTourWrapperProps> = ({ ...props }) => {
  const [shouldDisplayComponent, setShouldDisplayComponent] = useState<boolean>(false);
  const { sessionResponse } = useAppSelector(store => store.chatReducer);
  const { shouldDisplayProductTour } = useAppSelector(store => store.sharedStoreReducer);
  const { userAppData } = useAppSelector(store => store.userReducer);
  const clientFlags = getItemFromLocalStorage<IUser>(userInfoLocalStorageKey)?.clientFlags || userAppData.data?.user?.clientFlags || 0;
  const dispatch = useAppDispatch();

  const openTour = () => {
    switch (props.viewType) {
      case EViewType.PRODUCT_TOUR:
        handleOpenProductTour()
        break;
      case EViewType.PLAN_TOUR:
        handleOpenPlanTour();
        break;
      default:
        break
    }
  }

  useEffect(() => {
    openTour();
    // eslint-disable-next-line
  }, []);

  // Start Plan Tour Logic
  // set client flag to not show tour again
  const setPlanTourFlag = () => {
    try {
      const user = getItemFromLocalStorage<IUser>(userInfoLocalStorageKey);
      const clientFlags = user?.clientFlags || 0;
      const updatedClientFlags = FlagUtils.setFlag(clientFlags, EClientFlags.PLAN_TOUR_DISPLAYED);
      dispatch(updateUserProfileReq({ clientFlags: updatedClientFlags }));
    } catch (e) {
      ApplicationInsightsApi.trackException(e);
    }
  }

  const handleOpenPlanTour = () => {
    const planTourDisplayedFlag = isClientFlagOn(EClientFlags.PLAN_TOUR_DISPLAYED);
    if (!shouldDisplayProductTour && !planTourDisplayedFlag) {
      setPlanTourFlag();
      dispatch(setTasksFilter(ETasksGroupsType.MY_WEEK));
      setShouldDisplayComponent(true);
    }
  }
  // End Plan Tour Logic

  // Start Product Tour Logic
  const handleOpenProductTour = () => {
    if (!isProductTourAlreadyDisplayed()) {
      dispatch(setShouldDisplayProductTour(true));
      setShouldDisplayComponent(true);
    }
  }

  const turnOnTheProductTourClientFlag = useCallback(() => {
    const updatedClientFlags = FlagUtils.setFlag(clientFlags, EClientFlags.PRODUCT_TOUR_DISPLAYED);
    dispatch(updateUserProfileReq({ clientFlags: updatedClientFlags }));
  }, [clientFlags, dispatch]);

  useApiData(sessionResponse, {
    onFulfilled: () => {
      if (!isProductTourAlreadyDisplayed()) {
        turnOnTheProductTourClientFlag()
      }
    }
  });
  // End Product Tour Logic

  return (
    (shouldDisplayComponent) ? <ProductTour {...props} setShouldDisplayComponent={setShouldDisplayComponent} /> : null
  )
}

export default ProductTourWrapper