import { ContentFrameWrapper } from '../../../../shared/components/content-frame-wrapper/ContentFrameWrapper'
import { EAppStageContentType } from './stageContainer.interfaces';
import { useSearchParams } from 'react-router-dom';
import { stageParam } from '../../../../app/constants';
import { StageTasks } from './stage-tasks/StageTasks';
import { StagePlanner } from './stage-planner/StagePlanner';
import StageScratchpad from './stage-scratchpad/StageScratchpad';
import CreateOrEditTask from './stage-tasks/create-or-edit-task/CreateOrEditTask';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { setSelectedMainTaskForEditing } from './stage-tasks/stageTasks.store';
import ToastMessages from './toast-messages/ToastMessages';
import { PlanTasksPlacementList } from '../../../plan/plan-tasks-list/PlanTasksPlacementList';
import WorkBlockDetailsContainer from './work-block-details/WorkBlockDetailsContainer';
import { memo } from 'react';
import { isApiPending } from '../../../../shared/utils/utils';
import { ChatFormUserInput } from '../../chat/chat-form-user-input/ChatFormUserInput';
import { StageHeader } from './stage-header/StageHeader';

const StageContainer = () => {
  const { shouldOpenAddEditTaskFrom, selectedMainTaskForEditing, shouldStageExpand } = useAppSelector(store => store.StageTasksReducer);
  const { shouldOpenWorkBlockDetails } = useAppSelector(store => store.workBlockReducer);
  const { sessionResponse } = useAppSelector(store => store.chatReducer);
  const [searchParams,] = useSearchParams();
  const isInExpandMode: boolean = shouldStageExpand && !!searchParams.get(stageParam);
  const dispatch = useAppDispatch();

  const getStageContent = () => {
    switch (searchParams.get(stageParam)) {
      case EAppStageContentType.TASKS:
        return <StageTasks />;
      case EAppStageContentType.PLANNER:
        return <StagePlanner />;
      case EAppStageContentType.SCRATCHPAD:
        return <StageScratchpad />;
      default:
        return <></>;
    }
  }

  return (
    <div id='stage-container-main' className='stage-container'>
      <ContentFrameWrapper>
        {!isApiPending(sessionResponse.status) && <>
        <StageHeader />
        <div className='stage-content-wrapper'>
          <section className='screen-content'>
            {getStageContent()}
            {!!shouldOpenAddEditTaskFrom &&
              <CreateOrEditTask
                isIncludeSubtasks={true}
                itemForEditing={selectedMainTaskForEditing ? { ...selectedMainTaskForEditing, taskId: selectedMainTaskForEditing?.id } : null}
                onUpdateItemForEditing={(updatedTask) => dispatch(setSelectedMainTaskForEditing(updatedTask))}
              />}
            {!!shouldOpenWorkBlockDetails && <WorkBlockDetailsContainer />}
          </section>
          {isInExpandMode && <ChatFormUserInput />}
        </div>
        <PlanTasksPlacementList />
        <ToastMessages />
        <div className='transparency-background-while-overlay-opens fadeIn' />
        </>}
      </ContentFrameWrapper>
    </div>
  )
}

export default memo(StageContainer);

