import { Fragment, FunctionComponent } from "react";
import { ISessionSummariesResponse } from "../../../chat.interfaces";
import { SingleSummary } from "./single-summary/SingleSummary";
import './SummariesGroup.scss';

interface ISummariesGroupProps{
  groupTitle: string;
  summaryGroupItems: ISessionSummariesResponse[];
}

export const SummariesGroup:FunctionComponent<ISummariesGroupProps> = ({summaryGroupItems, groupTitle}) => {

  return (
    <section className='summaries-group'>
      <h3 className="group-title">{groupTitle.replaceAll('-', ' ')}</h3>
      {summaryGroupItems.map((singleSummary, i) =>
        <Fragment key={singleSummary.sessionId + i}>
          <SingleSummary singleSummary={singleSummary}/>
        </Fragment>
      )}
    </section>
  )
}