import React, { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { IAddEditTaskForm, ITaskTag } from '../../stageTasks.interface';
import CreateOrEditTask, { ISubTaskForEditing } from '../CreateOrEditTask';
import { UseFieldArrayRemove, UseFieldArrayUpdate } from 'react-hook-form';
import { isMobileView } from '../../../../../../../shared/utils/utils';
import './SubtaskDetailsModal.scss';

interface ISubtaskDetailsModalProps {
  parentName?: string;
  parentDescription?: string;
  parentTags?: ITaskTag[];
  itemForEditing: ISubTaskForEditing;
  onRemoveSubTaskFromParentFieldArray: UseFieldArrayRemove;
  onUpdateSubTaskInParentFieldArr: UseFieldArrayUpdate<IAddEditTaskForm, "subTasks">;
  setSubtaskForEditing: Dispatch<SetStateAction<ISubTaskForEditing | null>>,
}

const SubtaskDetailsModal: FunctionComponent<ISubtaskDetailsModalProps> = ({ parentName, parentDescription, parentTags, itemForEditing, setSubtaskForEditing, onUpdateSubTaskInParentFieldArr, onRemoveSubTaskFromParentFieldArray }) => {
  const subtaskDetailsModalClassName = 'subtask-details-modal-container';
  const onCloseModalOutsideTheForm = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isMobileView() && (e.target as HTMLElement).className.includes(subtaskDetailsModalClassName)) setSubtaskForEditing(null);
  }

  return (
    <div className={subtaskDetailsModalClassName} onClick={(e) => onCloseModalOutsideTheForm(e)}>
      <CreateOrEditTask
        isSubTask={true}
        itemForEditing={itemForEditing}
        onUpdateItemForEditing={(updatedTask) => setSubtaskForEditing(updatedTask)}
        onUpdateSubTaskInParentFieldArr={onUpdateSubTaskInParentFieldArr}
        onRemoveSubTaskFromParentFieldArray={onRemoveSubTaskFromParentFieldArray}
        parentName={parentName}
        parentFirstTag={parentTags?.[0] || undefined}
      />
    </div>
  )
}

export default SubtaskDetailsModal
