import { useTranslation } from "react-i18next";
import './ProactiveReminders.scss';
import { TextAndToggle } from "../../../Text-And-Toggle/TextAndToggle";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { useState } from "react";
import { IProactiveReminders, IUser } from "../../../../../app/auth/auth.interfaces";
import { useApiData } from "../../../../hooks/useApiData";
import { useLocalStorage } from "../../../../utils/useLocalStorage";
import { chatSessionIdLocalStorageKey } from "../../../../../app/constants";
import { updateUserProfileReq } from "../settingsMenu.store";
import { useFormContext } from "react-hook-form";
import { IProfileForm } from "../SettingsMenu";
import { defaultHourDailyReminder, defaultHourEveningReminder } from "../SettingsMenu.utils";
import EditReminderTimeValue from "./edit-reminder-time/EditReminderTimeValue";

export const ProactiveReminders = () => {
    const { getUserProfile, updateUserProfile } = useAppSelector(store => store.settingsMenuReducer);
    const { sessionResponse } = useAppSelector(store => store.chatReducer);
    const [userInfo, setUserinfo] = useState<IUser | undefined>(undefined);
    const [sessionIdLocalStorage,] = useLocalStorage(chatSessionIdLocalStorageKey, '');
    const remindersActiveClass = userInfo?.userTextNotificationsEnabled ? "" : "proactive-reminders-container--disabled";
    const [lastUpdatedReminderProperty, setLastUpdatedReminderProperty] = useState<keyof IProactiveReminders | null>(null);
    const form = useFormContext<IProfileForm>();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const dailyReminderHourWatch = form.watch().proactiveReminders?.proactiveDailyReminderHour;
    const eveningReminderHourWatch = form.watch().proactiveReminders?.proactiveEveningReminderHour;
    const dailyReminderExists : boolean = !!dailyReminderHourWatch || dailyReminderHourWatch === 0;
    const eveningReminderExists: boolean = !!eveningReminderHourWatch || eveningReminderHourWatch === 0;

    useApiData(getUserProfile, {
        onFulfilled: user => setUserinfo(user)
    });

    useApiData(updateUserProfile, {
        onFulfilled: user => setUserinfo(user),
        onRejected: () => {
            const proactiveReminders = form.getValues().proactiveReminders;
            const proactiveReminderProperty = lastUpdatedReminderProperty as keyof IProactiveReminders;
            if (userInfo) form.reset({ ...form.getValues(), proactiveReminders: { ...proactiveReminders, [proactiveReminderProperty]: userInfo.proactiveReminders[proactiveReminderProperty] } });
        }
    });

    const handleUpdateUserProfile = (proactiveReminderProperty: keyof IProactiveReminders, updatedValue: boolean | number | null) => {
        const requestPayload = {
            sessionId: sessionResponse?.data?.sessionId || sessionIdLocalStorage,
            [proactiveReminderProperty]: updatedValue
        }
        form.setValue(`proactiveReminders.${proactiveReminderProperty}`, updatedValue);
        setLastUpdatedReminderProperty(proactiveReminderProperty);
        dispatch(updateUserProfileReq(requestPayload));
    }

    return (
        <div className={`proactive-reminders-container ${remindersActiveClass}`}>
            <h2 className="proactive-reminders-header static-string">{t("proactiveReminders")}</h2>
            <div className="proactive-reminders-toggles-container">
                <TextAndToggle
                    icon={'☀️'}
                    header={t("dailyReminderHeader")}
                    subHeader={t("dailyReminderSubHeader")}
                    toggleClassName="app-toggle app-toggle-active-dark"
                    toggleActive={dailyReminderExists }
                    onToggle={(checked) => handleUpdateUserProfile('proactiveDailyReminderHour', checked ? defaultHourDailyReminder : null)}
                >
                    {dailyReminderExists  && <EditReminderTimeValue reminderHour={dailyReminderHourWatch!} handleUpdateUserProfile={handleUpdateUserProfile} proactiveReminderProperty={'proactiveDailyReminderHour'} />}
                </TextAndToggle>
                <TextAndToggle icon={'🌙'}
                    header={t("eveningReminderHeader")}
                    subHeader={t("eveningReminderSubHeader")}
                    toggleClassName="app-toggle app-toggle-active-dark"
                    toggleActive={eveningReminderExists}
                    onToggle={(checked) => handleUpdateUserProfile('proactiveEveningReminderHour', checked ? defaultHourEveningReminder : null)}
                >
                    {eveningReminderExists && <EditReminderTimeValue reminderHour={eveningReminderHourWatch!} handleUpdateUserProfile={handleUpdateUserProfile} proactiveReminderProperty={'proactiveEveningReminderHour'} />}
                </TextAndToggle>
                <TextAndToggle icon={'💪'}
                    header={t("workBlockReminderHeader")}
                    subHeader={t("workBlockReminderSubHeader")}
                    toggleClassName="app-toggle app-toggle-active-dark"
                    toggleActive={!!form.getValues().proactiveReminders?.isProactiveWorkBlockCheckInReminderEnabled}
                    onToggle={(checked) => handleUpdateUserProfile('isProactiveWorkBlockCheckInReminderEnabled', checked)} />
            </div>
        </div>
    )
}