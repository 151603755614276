import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../app/store';
import TasksGroup from '../../../chat/chat-conversation/message-data-card/tasks-list/TasksGroup';
import { ETaskStatus, ETasksGroupsType, IMessageDataTask } from './stageTasks.interface';
import { StageTasksEmptyState } from './stage-tasks-emty-state/StageTasksEmptyState';
import { isOverdueDate, shouldDisplayTaskOnTasksFilters } from '../../../chat/chat-conversation/message-data-card/tasks-list/TasksList.utils';
import { useEffect, useState } from 'react';
import AddTaskField from '../add-task-field/AddTaskField';
import './StageTasks.scss';

export const StageTasks = () => {
  const { tasksOrderedByGroups, tasksFilter } = useAppSelector(store => store.StageTasksReducer);
  const { shouldDisplayProductTour } = useAppSelector(store => store.sharedStoreReducer);
  const [localTaskOrderedByGroupsList, setLocalTaskOrderedByGroupsList] = useState<{[key: string]: IMessageDataTask[]} | null>(tasksOrderedByGroups);
  const { t } = useTranslation();

  const getGroupsByFilter = () => {
    if (!!localTaskOrderedByGroupsList) {
      switch (tasksFilter) {
        case ETasksGroupsType.MY_TASKS:
          return (
            <>
              <TasksGroup shouldDisplayActionButtons={true} shouldDisplayFirstTagColorEachTask={true} groupTitle={t("stageTaskPriorityGroupTitle")} taskGroupItems={localTaskOrderedByGroupsList[ETasksGroupsType.PRIORITY]} groupType={ETasksGroupsType.PRIORITY} />
              <TasksGroup shouldDisplayActionButtons={true} shouldDisplayFirstTagColorEachTask={true} groupTitle={t("stageTasksOverdueGroupTitle")} taskGroupItems={localTaskOrderedByGroupsList[ETasksGroupsType.OVERDUE]} groupType={ETasksGroupsType.OVERDUE} />
              <TasksGroup shouldDisplayActionButtons={true} shouldDisplayFirstTagColorEachTask={true} groupTitle={t("stageTaskQuickWinsGroupTitle")} taskGroupItems={localTaskOrderedByGroupsList[ETasksGroupsType.QUICK_WINS]} groupType={ETasksGroupsType.QUICK_WINS} />
              <TasksGroup shouldDisplayActionButtons={true} shouldDisplayFirstTagColorEachTask={true} groupTitle={t("stageTasksScheduledGroupTitle")} taskGroupItems={localTaskOrderedByGroupsList[ETasksGroupsType.SCHEDULED]} groupType={ETasksGroupsType.SCHEDULED} />
              <TasksGroup shouldDisplayActionButtons={true} shouldDisplayFirstTagColorEachTask={true} groupTitle={t("stageTaskUnscheduledGroupTitle")} taskGroupItems={localTaskOrderedByGroupsList[ETasksGroupsType.UNSCHEDULED]} groupType={ETasksGroupsType.UNSCHEDULED} />
            </>
          );
        case ETasksGroupsType.OVERDUE:
          return <TasksGroup shouldDisplayActionButtons={true} shouldDisplayFirstTagColorEachTask={true} groupTitle={t("stageTasksOverdueGroupTitle")} groupType={ETasksGroupsType.OVERDUE} taskGroupItems={Object.values(localTaskOrderedByGroupsList || {}).flat().filter(item => shouldDisplayTaskOnTasksFilters(item) && item?.dueDate && isOverdueDate(item.dueDate))} />

        case ETasksGroupsType.SCHEDULED:
          return <TasksGroup shouldDisplayActionButtons={true} shouldDisplayFirstTagColorEachTask={true} groupTitle={t("stageTasksScheduledGroupTitle")} groupType={ETasksGroupsType.SCHEDULED} taskGroupItems={localTaskOrderedByGroupsList[ETasksGroupsType.SCHEDULED]} />

        case ETasksGroupsType.UNSCHEDULED:
          return <TasksGroup shouldDisplayActionButtons={true} shouldDisplayFirstTagColorEachTask={true} groupTitle={t("stageTaskUnscheduledGroupTitle")} groupType={ETasksGroupsType.UNSCHEDULED} taskGroupItems={Object.values(localTaskOrderedByGroupsList || {}).flat().filter(item => shouldDisplayTaskOnTasksFilters(item) && !item?.workTime && !item?.workBlockId)} />

        case ETasksGroupsType.PRIORITY:
          return <TasksGroup shouldDisplayActionButtons={true} shouldDisplayFirstTagColorEachTask={true} groupTitle={t("stageTaskPriorityGroupTitle")} groupType={ETasksGroupsType.PRIORITY} taskGroupItems={Object.values(localTaskOrderedByGroupsList || {}).flat().filter(item => shouldDisplayTaskOnTasksFilters(item) && item.priority)} />

        case ETasksGroupsType.QUICK_WINS:
          return <TasksGroup shouldDisplayActionButtons={true} shouldDisplayFirstTagColorEachTask={true} groupTitle={t("stageTaskQuickWinsGroupTitle")} groupType={ETasksGroupsType.QUICK_WINS} taskGroupItems={Object.values(localTaskOrderedByGroupsList || {}).flat().filter(item => shouldDisplayTaskOnTasksFilters(item) && item.quickWin)} />

        case ETasksGroupsType.DONE:
          return <TasksGroup shouldDisplayActionButtons={true} shouldDisplayFirstTagColorEachTask={true} groupType={ETasksGroupsType.DONE} taskGroupItems={localTaskOrderedByGroupsList[ETasksGroupsType.DONE]} />

        default:
          return <></>
      }
    }
  }

  
  const updateLocalList = (newTask: IMessageDataTask) => {
      setLocalTaskOrderedByGroupsList(prev => {
        let tempUnscheduled:IMessageDataTask[] = prev ? prev[ETasksGroupsType.UNSCHEDULED] : [];
        tempUnscheduled = [newTask,...tempUnscheduled];
        return {...prev, [ETasksGroupsType.UNSCHEDULED]: [...tempUnscheduled]}
    })
  }

  useEffect(() => {
    setLocalTaskOrderedByGroupsList(tasksOrderedByGroups);
  },[tasksOrderedByGroups])

  return (
    <div className='stage-child-component stage-tasks-container'>
        <div className="scrollable-container">
          {getGroupsByFilter()}
          {!shouldDisplayProductTour && <StageTasksEmptyState />}
          <AddTaskField
            taskStatus={ETaskStatus.NOT_STARTED}
            placeholder={t("addTaskInputPlaceholderText")}
            onChange={(newTask) => updateLocalList(newTask)}
            className="add-main-task-input"
          />
        </div>
    </div>
  )
}