import React from 'react'
import { ReactComponent as MascotComponent } from '../../../../../../assets/images/mascot/main_5.svg';
import { useTranslation } from 'react-i18next';
import './ScratchpadEmptyState.scss';

const ScratchpadEmptyState = () => {
  const { t } = useTranslation();

  return (
    <div className='scratchpad-empty-state'>
      <div className='scratchpad-empty-state--paragraph-container'>
        <p className='scratchpad-empty-state--paragraph static-string'>{t("scratchpadEmptyStateParagraph1")}</p>
        <p className='scratchpad-empty-state--paragraph static-string'>{t("scratchpadEmptyStateParagraph2")}</p>
      </div>
      <MascotComponent className='scratchpad-empty-state--mascot' />
    </div>
  )
}

export default ScratchpadEmptyState