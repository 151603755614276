import React, { CSSProperties, Dispatch, FunctionComponent, MouseEvent, SetStateAction, useEffect, useRef, useState } from 'react'
import { ICalendarEvent, IDropResult } from './CalendarDay'
import { ETaskFormType, ETaskSource, ETaskStatus, IMessageDataTask, ITaskUpdateReqPayload } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { store, useAppDispatch, useAppSelector } from '../../../../app/store';
import { onPlaceUnscheduledTask } from '../../plan.utils';
import { EPlannerMode } from '../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import { setShouldOpenWorkBlockDetails, setWorkBlockForEdit } from '../../../chat-wrapper/resizable-container/stage-container/work-block-details/workBlock.store';
import { areDatesEqual, EDragAndDropType, getExternalEventSourceDetails, getWorkBlockOrder, isTaskAssignToThisWorkBlock } from '../../../../shared/utils/utils';
import { getTasksListReqAction, setSelectedMainTaskForEditing, setShouldOpenAddEditTaskFrom, updateTaskReqAction } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import { DragSourceMonitor, useDrop } from 'react-dnd';
import WorkBlockTasksList from './work-block-tasks-list/WorkBlockTasksList';
import { EPlanDayCardDisplayType } from '../../plan-day-card/PlanDayCard';
import recurrenceIcon from '../../../../assets/images/single-task/task_recurrence_icon.svg';
import SassVariables from "../../../../styles/style.module.scss";
import DraggableWrapper from '../../../../shared/components/dragged-destination-preview/DraggableWrapper';
import { getStyleForDraggingPreview } from '../../../../shared/components/dragged-destination-preview/DragAndDrop.utils';
import { IDragItem } from './CalendarDragAndDrop.util';
import { IUpdateDragEventApiRequestFuncArgs } from './CalendarDay.interfaces';
import { addMinutes } from 'date-fns';
import { fullDayDurationInSeconds } from '../../../../app/constants';
import { calcNewDuration, calcNewStartTime, defaultDuration, getDurationTypeClassName, getResizingEventEdge, TResizingEventEdge } from './CalendarDay.util';
import { isMobileDevice } from '../../../../shared/utils/isMobileDevice';

interface ICalendarEventProps {
  event: ICalendarEvent;
  isCalenderDayClickable: boolean;
  shouldShowHourText: boolean;
  calendarDayContainerWidth: number;
  playViewType: EPlanDayCardDisplayType;
  divideEventByGroups: (events: ICalendarEvent[]) => void;
  eventsFlatArray: ICalendarEvent[];
  dayIndex: number;
  updateDragEventApiRequest: (updateDraggedEventArgs: IUpdateDragEventApiRequestFuncArgs) => void
  setDroppingProcessCounter: Dispatch<SetStateAction<number>>
  cellHeight: number;
}

export interface IUseDragCollectedProps {
  opacity?: number;
  cursor?: string;
  transition?: string;
}

export interface IWorkBlockDropResult extends IDropResult {
  droppedWorkBlockId: string;
}

const CalendarEvent: FunctionComponent<ICalendarEventProps> = ({
  event,
  dayIndex,
  divideEventByGroups,
  eventsFlatArray,
  isCalenderDayClickable,
  shouldShowHourText,
  calendarDayContainerWidth,
  playViewType,
  updateDragEventApiRequest,
  setDroppingProcessCounter,
  cellHeight
}) => {
  const { allTasks } = useAppSelector(store => store.StageTasksReducer);
  const { plannerMode, currentTaskPlacement } = useAppSelector(store => store.StagePlannerReducer);
  const { sessionResponse } = useAppSelector(store => store.chatReducer);
  const dispatch = useAppDispatch();
  const defaultLeftPosition = 40;
  const reductionFromContainerWidth = shouldShowHourText ? 40 : 0;
  const marginBetweenEvents = 2;
  const eventRef = useRef<HTMLDivElement | null>(null);
  const leftPosition = calcEventLeft();
  const [draggingPreviewStyle, setDraggingPreviewStyle] = useState(getStyleForDraggingPreview(eventRef.current, null));
  // resizing variables
  const [resizingStartY, setResizingStartY] = useState<number | null>(null);
  const [cursor, setCursor] = useState("pointer");
  const [resizingEdge, setResizingEdge] = useState<TResizingEventEdge>(null);
  const [shouldDisableOnClick, setShouldDisableOnClick] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const initialResizingHeight = useRef<number | null>(null);
  const initialResizingTopRef = useRef<number | null>(null);
  const endResizingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const isMobile = isMobileDevice();

  useEffect(() => {
    setDraggingPreviewStyle({ ...getStyleForDraggingPreview(eventRef.current, null) });
  }, [eventRef, leftPosition])

  // Dragging is permitted only if:
  // 1. The event is not recurring, and it is not the current task placement.
  // 2. The event is not external.
  const canDrag = () => {
    const isNotRecurring = !event.isRecurring;
    const isNotCurrentTaskPlacement = currentTaskPlacement?.id !== event.id;
    const isNotExternalEvent = !event.isEvent || event.source === ETaskSource.Internal

    return (
      isNotRecurring && isNotCurrentTaskPlacement && isNotExternalEvent && cursor !== "ns-resize" && !resizingEdge
    )
  }

  const canResizing = () => {
    const isNotRecurring = !event.isRecurring;
    const isNotCurrentTaskPlacement = currentTaskPlacement?.id !== event.id;
    const isNotExternalEvent = !event.isEvent || event.source === ETaskSource.Internal
    return isNotCurrentTaskPlacement && isNotExternalEvent && isNotRecurring
  }

  const onEndDrag = (draggedItem: IDragItem, monitor: DragSourceMonitor<IDragItem, IDropResult>) => {
    const dropResult = monitor.getDropResult();
    // if dropped in different day, remove the event from the current day (else, the event is already updated in the current day inside the drop function)
    if (monitor.didDrop() && dropResult?.dayIndex !== dayIndex) {
      divideEventByGroups(eventsFlatArray.filter(e => e.id !== draggedItem.event.id));
    }
  }

  const updateLocalDroppedWorkBlockEvent = (task: IMessageDataTask, taskWorkBlockOrder: number, taskWorkBlockInstance: Date | null) => {
    const updatedTask = { 
      ...task,
      workBlockId: event?.id,
      workBlockInstance: taskWorkBlockInstance,
      workBlockOrder: taskWorkBlockOrder,
      workTime: null,
      workTimeRecurrenceType: null,
      workTimeReminder: null,
    }
    const tempEventsArr = [...eventsFlatArray];
    const workBlockEvent = tempEventsArr.find(e => e.id === event.id);
    if (workBlockEvent) {
      workBlockEvent.relatedTasks.push(updatedTask);
      divideEventByGroups([...tempEventsArr].filter(e => e.id !== task.id));
    }
  }

  const dropInToWorkBlock = (dragItem: IDragItem, dropType: EDragAndDropType): IWorkBlockDropResult => {
    setDroppingProcessCounter(prev => prev + 1);
    const task = allTasks.find(t => t.id === dragItem.event.id);
    const taskWorkBlockInstance = event.isRecurring ? event.start : null;
    // if the task is not already assigned to this workBlock, update the task and the workBlock
    if (!!task && !isTaskAssignToThisWorkBlock(event.id, taskWorkBlockInstance || "", task?.workBlockId, task?.workBlockInstance)) {
      const taskWorkBlockOrder = getWorkBlockOrder(event.relatedTasks[event.relatedTasks.length - 1]?.workBlockOrder);
      updateLocalDroppedWorkBlockEvent(task, taskWorkBlockOrder, taskWorkBlockInstance);
      updateDragEventApiRequest({
          dragItem, 
          dropType, 
          updatedWorkTime: null, 
          relatedWorkBlockId: event.id, 
          relatedWorkBlockOrder: taskWorkBlockOrder, 
          relatedWorkBlockInstance: taskWorkBlockInstance
      });
    }
    return {dayIndex, droppedWorkBlockId: event.id}
  }

  // drop into work block
  // dropRef is used only for work blocks to make them droppable
  const [{ isDraggableItemOverClassName }, dropRef] = useDrop({
    accept: [EDragAndDropType.CALENDAR_EVENT, EDragAndDropType.PLANNER_TO_CALENDAR_EVENT],
    drop: (dragItem: IDragItem,monitor) => dropInToWorkBlock(dragItem, monitor.getItemType() as EDragAndDropType),
    canDrop: (dragItem: IDragItem) => !dragItem.event.isEvent,
    collect: (monitor) => ({
      isDraggableItemOverClassName: monitor.isOver() && !monitor.getItem().event.isEvent && !monitor.getItem().event.isWorkBlock ? 'draggable-item-is-over' : '',
    }),
  });

  const onPlaceTaskInsideWorkBlock = (workBlockEvent: ICalendarEvent) => {
    onPlaceUnscheduledTask(workBlockEvent.start, workBlockEvent.id, workBlockEvent.title, workBlockEvent.isRecurring, workBlockEvent.relatedTasks.length ? getWorkBlockOrder(workBlockEvent.relatedTasks[workBlockEvent.relatedTasks.length - 1]?.workBlockOrder) : 0);
  }

  const onClickWorkBlockEvent = (workBlockEvent: ICalendarEvent) => {
    if (plannerMode === EPlannerMode.TIMEPICKER) {
      onPlaceTaskInsideWorkBlock(workBlockEvent);
      return;
    }
    const workBlock = store.getState().StageTasksReducer.allWorkBlocks.find(w => w.id === workBlockEvent.id && areDatesEqual(w.workTime, workBlockEvent.start));
    if (workBlock) {
      dispatch(setWorkBlockForEdit(workBlock));
      dispatch(setShouldOpenWorkBlockDetails(true));
    }
  }

  const handleEventClick = (clickEvent: MouseEvent<HTMLElement, any>, event: ICalendarEvent, workBlockTask?: IMessageDataTask) => {
    clickEvent.stopPropagation();
    if (!isCalenderDayClickable || cursor === "ns-resize" || shouldDisableOnClick) return;
    if (((plannerMode === EPlannerMode.UNSCHEDULEDTASKSPLACER) && currentTaskPlacement)) {
      if (event.isWorkBlock) {
        onPlaceTaskInsideWorkBlock(event);
        return;
      }
      onPlaceUnscheduledTask(event.start);
      return;
    };
    if (workBlockTask && plannerMode !== EPlannerMode.TIMEPICKER) {
      dispatch(setSelectedMainTaskForEditing(workBlockTask));
      dispatch(setShouldOpenAddEditTaskFrom(ETaskFormType.Task));
      return
    }
    if (!event.isWorkBlock && plannerMode === EPlannerMode.TIMEPICKER) {
      onPlaceUnscheduledTask(event.start);
      return;
    }
    if (event.isWorkBlock) onClickWorkBlockEvent(event);
    else {
      const task = store.getState().StageTasksReducer.allTasks.find(t => event.parentId ? t.id === event.parentId : t.id === event.id);
      if (task) {
        dispatch(setSelectedMainTaskForEditing(task));
        dispatch(setShouldOpenAddEditTaskFrom(event.isEvent ? ETaskFormType.Event : ETaskFormType.Task));
      }
    }
    clickEvent.preventDefault();
    clickEvent.stopPropagation();
  }

  function calcEventLeft() {
    const leftPosition = shouldShowHourText ? defaultLeftPosition : 0;
    if (event.columnOffset === 0) return event.columnOffset! * (calendarDayContainerWidth - reductionFromContainerWidth) / event.totalColumns! + leftPosition;
    return event.columnOffset! * (calendarDayContainerWidth - reductionFromContainerWidth) / event.totalColumns! + leftPosition;
  }

  const getEventStyle = (): CSSProperties => {
    return ({
      position: 'absolute',
      top: event.top,
      left: leftPosition,
      height: `${event.height}px`,
      width: (((calendarDayContainerWidth - reductionFromContainerWidth) / event.totalColumns!) - marginBetweenEvents) + 'px',
      transition: 'none',
    })
  }

  const getEventInnerContainerStyle = (): CSSProperties => {
    return ({
      backgroundColor: event.backgroundColor,
      border: `${event.backgroundColor === "#FFF" ? "1px solid " + SassVariables.MaxDarkColor : 'none'}`
    })
  }

  const handleMouseOverForCursor = (e: React.MouseEvent<HTMLElement>) => {
    const edgeForResizing = getResizingEventEdge(e, cellHeight);

    if (!!edgeForResizing) {
      setCursor("ns-resize");
    } else {
      setCursor("pointer");
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    const edgeForResizing = getResizingEventEdge(e, cellHeight);

    if (elementRef.current && !!edgeForResizing) {
      setShouldDisableOnClick(true);
      setResizingStartY(e.clientY);
      initialResizingHeight.current = elementRef.current.offsetHeight;
      setResizingEdge(edgeForResizing === 'top' ? "top" : "bottom");
      if (edgeForResizing === 'top') initialResizingTopRef.current = elementRef.current.offsetTop;
    }
  };

  const handleMouseMove = (e: Event) => {
    const initialHeight = initialResizingHeight.current;
    if (!resizingEdge || !resizingStartY || !initialHeight || !elementRef.current) return;
    const deltaY = (e as unknown as MouseEvent).clientY - resizingStartY;
    if (resizingEdge === "bottom") {
      const newHeight = Math.max(cellHeight, Math.round((initialHeight + deltaY) / cellHeight) * cellHeight); // Snap to increments of cellHeight
      elementRef.current.style.height = `${newHeight}px`;
    }
    else if (resizingEdge === "top" && initialResizingTopRef.current) {
      const newHeight = Math.max(cellHeight, Math.round((initialHeight - deltaY) / cellHeight) * cellHeight);
      const newTop = initialResizingTopRef.current + (initialHeight - newHeight); // Adjust top to maintain bottom position

      elementRef.current.style.height = `${newHeight}px`;
      elementRef.current.style.top = `${newTop}px`;
    }
  }

  const onUpdateLocalEventsList = (newHeight: number, updatedDuration: number, newStartTime: Date | null) => {
    const start = (newStartTime !== null) ? newStartTime : event.start
    const minutesToAddFromStartToCalcEndDate = (updatedDuration / 60 > 15) ? updatedDuration / 60 : defaultDuration;
    const end = addMinutes(start, minutesToAddFromStartToCalcEndDate);
    const durationType = getDurationTypeClassName(updatedDuration);
    const top = elementRef.current?.offsetTop || event.top;
    const updatedEvent = { 
      ...event, 
      duration: updatedDuration,
      height: newHeight,
      end, 
      durationType, 
      start,
      top
    };
    divideEventByGroups([...eventsFlatArray.filter(e => e.id !== event.id), updatedEvent ]);
  }

  const onUpdateEventDurationAndStart = () => {
    const newHeight = elementRef.current?.offsetHeight;
    const initialHeight = initialResizingHeight.current;
    const isInvalidResize = !(sessionResponse?.data?.sessionId) || !newHeight || !initialHeight || newHeight === initialHeight || !elementRef.current;
    if (isInvalidResize) {
      initialResizingHeight.current = null;
      initialResizingTopRef.current = null;
      return;
    }
    
    const newDuration = calcNewDuration(newHeight, cellHeight);
    // extending to multi-day durations is not supported
    if (fullDayDurationInSeconds <= newDuration) {
      elementRef.current!.style.height = `${initialHeight}px`; // use ! -> we know it's not null as isInvalidResize=false
    }
    else {
      // calc new start time only for top resizing
      let newStartTime = null;
      if(resizingEdge === "top") {
        newStartTime = calcNewStartTime(event.start,initialHeight, newHeight,cellHeight);
      }      
      setDroppingProcessCounter(prev => prev + 1);
      onUpdateLocalEventsList(newHeight, newDuration, newStartTime); // update the local events list before the server update
      
      const reqPayload: ITaskUpdateReqPayload = {
        sessionId: sessionResponse?.data?.sessionId!, // use ! -> we know it's not null as isInvalidResize=false
        id: event.id,
        duration: newDuration
      };
      // add the updated work time if exists to the request payload
      if(newStartTime) reqPayload.workTime = newStartTime;
      
      dispatch(updateTaskReqAction(reqPayload))
      .finally(() => {
          // Prevent the calendar from rendering until the GET request with the updated task occurs. 
          // This avoids events reverting to their previous placement before the server update tasks is complete.
          dispatch(getTasksListReqAction()).unwrap()
            .finally(() =>
              setDroppingProcessCounter(prev => prev > 0 ? prev - 1 : 0)
            )
        });
    }
    initialResizingHeight.current = null;
    initialResizingTopRef.current = null;
  }

  const cleanEndResizingTimerRef = () => {
    if (endResizingTimerRef.current) clearTimeout(endResizingTimerRef.current);
  }

  const handleMouseUp = () => {
    setResizingEdge(null);
    setResizingStartY(null);
    onUpdateEventDurationAndStart();
    cleanEndResizingTimerRef();
    // prevent from the event form modal to open while release the mouse
    endResizingTimerRef.current = setTimeout(() => { 
      setShouldDisableOnClick(false);
    }, 0);
  }

  useEffect(() => {
    if (resizingEdge && !isMobile && canResizing()) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  // eslint-disable-next-line
  }, [resizingEdge]);

  useEffect(() => {
    return () => cleanEndResizingTimerRef();
  }, [])

  const onMouseLeaveUpdateCursor = () => {
    if(!isMobile && cursor !== "pointer") setCursor("pointer");
  }

  return (
    <DraggableWrapper<IDragItem, IDropResult>
      className={`calendar-event calendar-event--${event.durationType} ${cursor === 'ns-resize' ? "cursor-resize" : ''} ${resizingEdge ? "resizing" : ""} ${event?.isWorkBlock ? 'calendar-event--work-block' : 'event-or-task'}`}
      id={`calendar-event-draggable-wrapper-${event.id}`}
      type={EDragAndDropType.CALENDAR_EVENT}
      item={{event,previewStyle: draggingPreviewStyle}}
      canDrag={() => canDrag()}
      onEndDrag={onEndDrag}
      dropRef={event.isWorkBlock ? dropRef : undefined}
      keyAttr={event.id}
      style={getEventStyle()}
      onClick={(e) => { e.stopPropagation(); handleEventClick(e, event) }}
      // Resizing events
      wrapperRef={elementRef}
      onMouseDown={(!isMobile && canResizing()) ? handleMouseDown : undefined}
      onMouseOver={(!isMobile && canResizing()) ? handleMouseOverForCursor : undefined}
      onMouseLeave={onMouseLeaveUpdateCursor}
    >
      <div ref={eventRef} className={`calendar-event-inner-container calendar-event-inner-container--${event.durationType} ${isDraggableItemOverClassName}`} style={getEventInnerContainerStyle()}>
        <div className="event-text-container">
          <div className='title-container'>
            <h1 className={`calendar-event-title calendar-event-title--${playViewType === EPlanDayCardDisplayType.MY_DAY ? "my-day" : "my-week"} ${event.status === ETaskStatus.DONE ? event.titleColor !== "white" ? 'completed-task completed-task--grey' : 'completed-task' : ''}`} style={{ color: event.titleColor, fontSize: event.titleFontSize }}>{event.title}</h1>
          </div>
          {!event?.isWorkBlock && event.source !== ETaskSource.Internal && <span className='calendar-event-addition-text' style={{ filter: event.backgroundColor !== 'transparent' && event.backgroundColor !== '#FFF' ? 'brightness(5)' : 'none' }}>{getExternalEventSourceDetails(event.source)}</span>}
          {event?.isWorkBlock && 
            <WorkBlockTasksList 
              workBlock={event} 
              onClickWorkBlockTask={handleEventClick} 
              planViewType={playViewType} 
              relatedTasks={event.relatedTasks}
              eventsFlatArray={eventsFlatArray}
              dayIndex={dayIndex}
              divideEventByGroups={divideEventByGroups} 
            />
          }
        </div>
        {!event?.isWorkBlock &&
          <div className='calendar-event-addition-info-container'>
            <img className={`${!event.isRecurring ? ' visibility-hidden' : ''}`} src={recurrenceIcon} alt="recurrence-icon" style={{ filter: event.backgroundColor !== 'transparent' && event.backgroundColor !== '#FFF' ? 'brightness(5)' : 'none' }} />
          </div>
        }
      </div>
    </DraggableWrapper>
  )
}

export default CalendarEvent