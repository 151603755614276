import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import AppButton from '../../shared/components/app-button/AppButton';
import { ReactComponent as NextIconComponent } from '../../assets/images/product-tour/next.svg';
import { getModalPosition, IProductTour, ModalPosition } from './ProductTour.utils';
import { productTourDataAttrScratchpad, stageParam } from '../../app/constants';
import productTourSassVariables from "./ProductTour.module.scss";
import { getFeatureFlagByKey, isMobileView } from '../../shared/utils/utils';
import './ProductTour.scss';
import { useAppDispatch } from '../../app/store';
import { useSearchParams } from 'react-router-dom';
import { setShouldDisplayProductTour } from '../../shared/store/shared.store';
import { ProductTourArray } from './tour-arrays/ProductTourArray';
import { PlanTourArray } from './tour-arrays/PlanTourArray';
import { EViewType, IProductTourWrapperProps } from './ProductTourWrapper';

interface IProductTourProps extends IProductTourWrapperProps {
  setShouldDisplayComponent: (shouldDisplay: boolean) => void;
}

const ProductTour: FunctionComponent<IProductTourProps> = ({ dataAttribute, className, viewType, setShouldDisplayComponent }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [modalPositionStyleObj, setModalPositionStyleObj] = useState<ModalPosition | null>(null);
  const productTourArr: IProductTour[] = (viewType === EViewType.PRODUCT_TOUR) ? ProductTourArray() : PlanTourArray();
  const { t } = useTranslation();
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const handleNext = () => {
    if (activeIndex === productTourArr.length - 1) {
      // remove product-tour
      handleClose();
      return;
    }
    // skip scratchpad screen if feature flag "HideScratchpad" is on
    if(getFeatureFlagByKey("HideScratchpad") && productTourArr[activeIndex+1].target === productTourDataAttrScratchpad) {
      productTourArr[activeIndex + 1]?.next();
      setActiveIndex(activeIndex + 2);
    }
    else {
      productTourArr[activeIndex]?.next();
      setActiveIndex(activeIndex + 1);
    }
  }

  const handlePrev = () => {
    // skip scratchpad screen if feature flag "HideScratchpad" is on
    if(getFeatureFlagByKey("HideScratchpad") && productTourArr[activeIndex-1].target === productTourDataAttrScratchpad) {
      productTourArr[activeIndex - 1]?.prev();
      setActiveIndex(activeIndex - 2);
    }
    else {
      productTourArr[activeIndex]?.prev();
      setActiveIndex(activeIndex - 1);
    }
  }

  useEffect(() => {
    if (!!productTourArr[activeIndex]?.target) {
      let target = productTourArr[activeIndex]?.target
      if(isMobileView() && !!productTourArr[activeIndex]?.mobileTarget) target = productTourArr[activeIndex]?.mobileTarget;
      if(timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        const position = getModalPosition({
          modalWidth: Number(productTourSassVariables.productTourCardWidth),
          modalHeight: Number(productTourSassVariables.productTourCardHeight),
          elementSelector: `[${dataAttribute}=${target}]`
        });
        setModalPositionStyleObj(position);
      }, 15);
    }

    return () => {
      if(timer.current) clearTimeout(timer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  const getTourCardClassName = () => {
    let cardClassName = 'product-tour-card';
    if(activeIndex === productTourArr.length - 1) cardClassName += ' product-tour-card--last-screen';
    if(!productTourArr[activeIndex]?.target) cardClassName += ' product-tour-card--no-target';
    if(!!productTourArr[activeIndex]?.target && !modalPositionStyleObj) cardClassName += ' d-none';
    if(className) cardClassName += ` ${className}`;
    if (productTourArr[activeIndex]?.cardClassName) cardClassName += ` ${productTourArr[activeIndex]?.cardClassName}`;
    return cardClassName;
  }

  const handleCloseProductTour = () => {
    setSearchParams(prev => {
      prev.delete(stageParam);
      return prev;
    });
    dispatch(setShouldDisplayProductTour(false));
  }

  const handleClose = () => {
    switch(viewType) {
      case EViewType.PRODUCT_TOUR:
        handleCloseProductTour()
        break;
      case EViewType.PLAN_TOUR:
        break;
      default:
        break
    }
    setShouldDisplayComponent(false);
  }

  return (
    <div className={`product-tour-container fadeIn ${className ? className : ""}`}>
      <div
        style={{
          top: modalPositionStyleObj?.top,
          left: modalPositionStyleObj?.left
        }}
        className={getTourCardClassName()}
      >
        <section>
          <AppButton id="skip-product-tour" className='product-tour-card-skip' onClick={handleClose}>{t("productTourSkipText")}</AppButton>
          <div className="title-container">
            <h3 className='product-tour-card-title static-string'>{productTourArr[activeIndex]?.title}</h3>
            {productTourArr[activeIndex]?.icon}
          </div>
          <p className='product-tour-card-paragraph'>{productTourArr[activeIndex]?.text}</p>
          { productTourArr[activeIndex]?.list && 
            <ul className='product-tour-card-list'>
              {(productTourArr[activeIndex]?.list || []).map((item, index) => (
                <li key={index}>
                  <b>{item.title}</b>
                  <p>{item.text}</p>
                </li>
              ))}
            </ul>
          }
        </section>
        <section className={`actions-container ${activeIndex === 0 ? 'actions-container--first-card' : ''}`}>
          {activeIndex !== 0 && <AppButton id="product-tour-prev" className='product-tour-button--prev' onClick={handlePrev}><NextIconComponent /></AppButton>}
          <AppButton id="product-tour-next" className='product-tour-button--next' onClick={handleNext}>{activeIndex === productTourArr.length - 1 ? t("productTourDoneText") : <NextIconComponent />}</AppButton>
        </section>
        <div className={`chevron ${modalPositionStyleObj?.chevronPosition?.direction}`} style={{ top: modalPositionStyleObj?.chevronPosition?.top, left: modalPositionStyleObj?.chevronPosition?.left }}></div>
      </div>
    </div>
  )
}

export default ProductTour