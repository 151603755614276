import { aiToolNames, chatSessionIdLocalStorageKey } from "../../app/constants"
import { IMessageDataTask } from "../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface"
import { getItemFromLocalStorage } from "./localStorage.utils"

export interface IUxEvent {
    sessionId: string | null,
    option: {
        text: string,
        tool: {
            name: string,
            arg?: any
        }
    }
}

export const uxEventEnterForm = (form: string, view?: string) => {
    return {
        sessionId: getItemFromLocalStorage(chatSessionIdLocalStorageKey),
        option: {
            text: "",
            tool: {
                name: aiToolNames.UX_ENTER_FORM,
                arg: {
                    form,
                    view
                }
            }
        }
    }
}

export const uxEventAddTaskOrSubTask = (parent: IMessageDataTask | null, task: IMessageDataTask, siblings: IMessageDataTask[] | null): IUxEvent => {
    const event: IUxEvent =  {
        sessionId: getItemFromLocalStorage(chatSessionIdLocalStorageKey),
        option: {
            text: "",
            tool: {
                name: aiToolNames.UX_ADD_TASK,
                arg: {
                    task,
                    parent,
                    siblings
                }
            }
        }
    }
    if (!parent) delete event.option.tool.arg.parent;
    if (!task) delete event.option.tool.arg.task;
    if (!siblings?.length)delete event.option.tool.arg.siblings;
    return event;
}