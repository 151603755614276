import { useEffect } from "react";
import { disallowedCountries, isDesktopView, supportedCountries, supportedCountriesInternal } from "../../utils/utils";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { AppSelect } from "../app-select/AppSelect";
import PhoneInput, { getCountries, getCountryCallingCode, isValidPhoneNumber } from "react-phone-number-input/input";
import en from 'react-phone-number-input/locale/en.json'
import './AppPhoneControl.scss';
import { getItemFromLocalStorage } from "../../utils/localStorage.utils";
import { IUser } from "../../../app/auth/auth.interfaces";
import { userInfoLocalStorageKey } from "../../../app/constants";

interface IProps {
    className: string;
    errorText: string;
}

export interface IFormData {
    phone: string;
    country: string;
}


export const AppPhoneControl = ({ className, errorText = '' }: IProps) => {
    const user = getItemFromLocalStorage<IUser>(userInfoLocalStorageKey);
    const supportedCountriesList = user?.isInternalUser ? supportedCountriesInternal : supportedCountries;

    const { control, setFocus, reset, setValue, formState } = useFormContext<IFormData>();

    const country = useWatch({ control, name: 'country' });

    useEffect(() => {
        if (isDesktopView()) setFocus(`phone`);
    }, [setFocus]);

    useEffect(() => {
        if (errorText.length > 0) reset();
        if (isDesktopView()) setFocus(`country`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorText])

    const handleChangeCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setValue('country', event.target.value, { shouldValidate: true });
        setValue('phone', "", { shouldValidate: true });
    }

    const getCountriesSorted = () => {
        const allowedCountries = getCountries().filter(c => !disallowedCountries.includes(c));
        const supportedCountries = allowedCountries.filter(c => supportedCountriesList.includes(c)).sort((a, b) => en[a].localeCompare(en[b]));
        return supportedCountries;
    }

    return (
        <div className={`app-phone-controllers-container ${className}`}>
            <AppSelect
                className='app-phone-select-controller'
                value={country}
                onChange={handleChangeCountry}
                options={getCountriesSorted().map(c => ({
                    value: c,
                    label: <>+{getCountryCallingCode(c)}<div className='img-wrapper'><img src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${c}.svg`} alt={`${c}-flag`} className='flag-img' />{en[c]}</div></>,
                    selectedLabel: <><img src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${c}.svg`} alt={`${c}-flag`} className='flag-img' />+{getCountryCallingCode(c)}</>,
                    labelStringForTrackEvent: `+${getCountryCallingCode(c)} ${en[c]}`,
                    className: `country-initials-${c.toLocaleLowerCase()}`
                }))}
            />
            {(supportedCountriesList.includes(country)) && <>
                <Controller
                    name='phone'
                    control={control}
                    rules={{ validate: (value) => isValidPhoneNumber(`${value}`) }}
                    render={({ field: { onChange, value } }) => (
                        <PhoneInput
                            id={`user-phone-input`}
                            data-testid={`user-phone-input`}
                            className={`app-phone-input ${formState.isValid && 'valid-mode'} ${!!errorText.length && 'red-border-error'}`}
                            placeholder='XXX-XXX-XXXX'
                            value={value}
                            onChange={onChange}
                            maxLength={15 - (getCountryCallingCode(country as any)?.length || 0)}
                            country={country as any}
                        />
                    )}
                />
                {!!errorText.length && <small className='error' data-testid="update-phone-error">{errorText}</small>}
            </>}
        </div>
    )

}