export interface ITranslate {
  x: number;
  y: number;
}

export interface IDelta {
  deltaX: number;
  deltaY: number;
}

export interface SwipeStartPosition {
  x?: number | null;
  y?: number | null;
}

export enum ECloseSwipeDirection {
  RIGHT_AND_LEFT = 'rightLeft',
  DOWN = 'down'
}

export const calculateSwipeDelta = (startPosition: SwipeStartPosition | null, touch: React.Touch): IDelta | null => {
  if (!startPosition) return null;

  const deltaX = startPosition.x ? (touch.clientX - startPosition.x) : 0;
  const deltaY = startPosition?.y ? (touch.clientY - startPosition?.y) : 0;

  return { deltaX, deltaY };
};

export const isThresholdExceeded = (
  delta: number,
  threshold: number
): boolean => Math.abs(delta) > threshold;