import { useEffect, useState } from 'react';

export const useKeyboard = () => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      // compare the current viewport height with the previous one
      // to determine if the keyboard is open
      const newViewportHeight = window.innerHeight;
      setIsKeyboardOpen(viewportHeight > newViewportHeight);
      setViewportHeight(newViewportHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [viewportHeight]);

  return { isKeyboardOpen };
};