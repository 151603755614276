// rich text editor that can detect and render URLs as clickable links
import { FunctionComponent, useEffect, useRef, useState } from "react"
import { withReact, Slate, Editable } from "slate-react";
import { createEditor, Descendant, Editor } from "slate";
import { appRichTextGetInitValue, appRichTextDecorator, appRichTextLeaf, convertRichTextDataToString } from "./AppRichText.utils";
import { ApplicationInsightsApi } from "../../../application-insights";
import './AppRichText.scss';

interface IAppRichTextProps {
  id: string;
  className?: string;
  placeholder?: string;
  testId?: string;
  onBlur?: (value: string) => void;
  initialValue?: string | null;
  disabled?: boolean;
  maxLength?: number;
}

export const AppRichText: FunctionComponent<IAppRichTextProps> = ({ id, className = "", testId, maxLength, initialValue, onBlur, placeholder, disabled = false }) => {
  const [editor] = useState(() => withReact(createEditor()));
  const initValue = appRichTextGetInitValue(initialValue);
  const updatedValueRef = useRef<Descendant[]>(initValue);
  const [editorKey, setEditorKey] = useState(1);

  const onBlurEditable = () => {
    const dataString = convertRichTextDataToString(updatedValueRef.current);
    if (onBlur && dataString) onBlur(dataString);
  }

  const onChangeSlate = (data: Descendant[]) => {
    if (data?.length > 0) {
      updatedValueRef.current = [...data];
    }
  }

  const handleKeyDownForLimitation = (event: React.KeyboardEvent<HTMLDivElement>) => {
    try {
      const textLength = Editor?.string(editor, [])?.length;
      if (maxLength && textLength >= maxLength && event.code !== "Backspace" && event.code !== "Tab") {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    } catch (e) {
      ApplicationInsightsApi.trackException(e);
      console.error("key down failed ", e);
    }
  }

  // watch initialValue when it set externally for re-render the component with the new value.
  useEffect(() => {
    if(convertRichTextDataToString(updatedValueRef.current) !== initialValue) {
      setEditorKey(editorKey + 1);
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[initialValue])  

  return (
    <Slate
      key={editorKey}
      onChange={onChangeSlate}
      editor={editor}
      initialValue={initValue}
    >
      <Editable
        renderLeaf={appRichTextLeaf}
        decorate={appRichTextDecorator}
        id={id}
        className={`app-rich-text ${className}`}
        onBlur={onBlurEditable}
        placeholder={placeholder || ""}
        readOnly={disabled}
        onKeyDown={handleKeyDownForLimitation}
        data-testid={testId}
      />
    </Slate>
  )
}
