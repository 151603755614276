import { LoginPageProps } from "../../auth.interfaces";
import magicLinkIcon from '../../../../assets/images/magic-link-icon.svg';
import { useTranslation } from "react-i18next";
import { APP_ROUTING_PATHS } from "../../../constants";
import { ELoginPageViewType } from "../Login";
import { useNavigateWithSearchParams } from "../../../../shared/utils/routingUtils";
import AppButton from "../../../../shared/components/app-button/AppButton";


export const LoginWithPhoneButton = ({viewType}: LoginPageProps) => {
    const { t } = useTranslation();
    const navigate = useNavigateWithSearchParams();

    const requestMagicLink = () => {
        navigate(APP_ROUTING_PATHS.MAGIC_LINK_PHONE);
    }

    return (
        <AppButton className="phone-login" type="button" onClick={requestMagicLink} id="phone-login">
            <img src={magicLinkIcon} alt={t('loginWithPhoneAltText')} />
            {viewType === ELoginPageViewType.NEW_USER_VIEW ? t('continueWithPhoneSignUp') : t('continueWithPhoneSignIn')}
        </AppButton>
    )
}