import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useRef, useState } from 'react'
import { isMobileDevice } from '../../../../../../../../shared/utils/isMobileDevice';
import { reminderCustomOptions } from '../../TaskEditOverlay.utils';
import { ITaskEditorOverlayType } from '../../TaskEditOverlay';
import "./CustomReminder.scss";
import AppButton from '../../../../../../../../shared/components/app-button/AppButton';

interface ICustomReminderProps {
  editOverlayType: ITaskEditorOverlayType;
  relativeDate: Date;
  selectedReminder: number | undefined | null;
  setSelectedReminder: Dispatch<SetStateAction<number | null | undefined>>;
}

const CustomReminder: FunctionComponent<ICustomReminderProps> = ({ relativeDate, selectedReminder, setSelectedReminder, editOverlayType }) => {
  const selectTimeRef = useRef<HTMLSelectElement>(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(getInitialSelectedOption());

  function getInitialSelectedOption() {
    let selectedIndex = 0
    if (selectedReminder) selectedIndex = reminderCustomOptions.findIndex(option => option.value === selectedReminder);
    return selectedIndex !== -1 ? selectedIndex : 0;
  }

  useEffect(() => {
    const selection = getInitialSelectedOption();
    if (!selectedReminder && selection === 0) {
      setSelectedReminder(reminderCustomOptions[selection].value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenDropDown = (ref: React.RefObject<HTMLSelectElement>) => {
    if (ref.current && isMobileDevice()) ref.current.focus();
  }

  const arrowUp = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
    <path d="M11.3484 7.5L7.73366 4.5L4.11893 7.5" stroke="#334C45" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>)
  }

  const arrowDown = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
    <path d="M4.1189 4.5L7.73363 7.5L11.3484 4.5" stroke="#334C45" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>)
  }

  const handleArrows = (arrow: 'up' | 'down') => {
    let selectedIndex = selectedOptionIndex;
    if (arrow === 'up') {
      if (selectedOptionIndex === reminderCustomOptions.length - 1) selectedIndex = 0;
      else selectedIndex = selectedOptionIndex + 1;
    }
    else {
      if (selectedOptionIndex === 0) selectedIndex = reminderCustomOptions.length - 1;
      else selectedIndex = selectedOptionIndex - 1;
    }
    setSelectedOptionIndex(selectedIndex);
    setSelectedReminder(reminderCustomOptions[selectedIndex].value);
  }

  return (
    <div className='task-edit-overlay-duration-custom-time-picker-container reminder-custom-time-picker'>
      <div className='task-edit-overlay-duration-custom-time-picker task-edit-overlay-duration-custom-time-picker--hours'>
        <span className='task-edit-overlay-duration-custom-time-picker-value' onClick={() => handleOpenDropDown(selectTimeRef)}>{reminderCustomOptions[selectedOptionIndex].name}</span>
        {isMobileDevice() && <select
          value={reminderCustomOptions[selectedOptionIndex].value}
          className='hidden-select-element'
          name="duration-selector"
          ref={selectTimeRef}
          onFocus={e => { e.currentTarget.size = 6; e.currentTarget.style.zIndex = "1" }}
          onBlur={e => { e.currentTarget.size = 0; e.currentTarget.style.zIndex = "-1" }}>
          {reminderCustomOptions.map(option => <option key={option.name} value={option.value}>{option.name}</option>)}
        </select>}
        <div className='task-edit-overlay-duration-picker-action-buttons-container'>
          <AppButton id="edit-reminder-overlay-custom-reminder-arrow-up" className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={() => handleArrows('up')} >{arrowUp()}</AppButton>
          <AppButton id="edit-reminder-overlay-custom-reminder-arrow-down" className='no-style-button task-edit-overlay-duration-picker-action-button' onClick={() => handleArrows('down')}>{arrowDown()}</AppButton>
        </div>
      </div>
      <div className='custom-string static-string'>{editOverlayType==='dueDate' ? "before this is due." : "before start."}</div>
    </div>
  )
}

export default CustomReminder
