import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react'
import TaskEditOverlayHeaderAndAction from '../TaskEditOverlayHeaderAndAction'
import { ETaskOverlayWorkBlockComponentStep } from './TaskOverlayWorkBlock.interfaces';
import { useTranslation } from 'react-i18next';
import { isMobileView, minutesToHoursAndMinutesStringRepresentation } from '../../../../../../../shared/utils/utils';
import './TaskOverlayWorkBlock.scss';
import OverlayTypeDetails from '../overlay-type-details/OverlayTypeDetails';
import clockIcon from '../../../../../../../assets/images/add-edit-task-duration-icon.svg'
import { TaskEditOverlayDurationPicker } from '../TaskEditOverlayDurationPicker';
import { ITaskWorkBlockDetailsEditOverlay } from '../TaskEditOverlay.utils';
import TaskOverlayWorkBlockDetails from './TaskOverlayWorkBlockDetails';

interface ITaskOverlayWorkBlockProps {
  handleSet: () => void;
  handleClear: () => void;
  handleClose: () => void;
  taskId?: string;
  taskWorkBlockDetails?: ITaskWorkBlockDetailsEditOverlay;
  setSelectedDuration: Dispatch<SetStateAction<number | null | undefined>>;
  selectedDuration?: number | null;
  isSubTask?: boolean;
  setCalendarAsTimePicker?: (isMultipleWorkTimeSelector: boolean, taskId?: string) => void;
  isMultiWorkTimeSelector:boolean;
}

const TaskOverlayWorkBlock: FunctionComponent<ITaskOverlayWorkBlockProps> = ({ handleSet, handleClear, handleClose, taskId, taskWorkBlockDetails, setSelectedDuration, selectedDuration, isSubTask,setCalendarAsTimePicker, isMultiWorkTimeSelector }) => {
  const { t } = useTranslation();
  const [componentStep, setComponentStep] = useState<ETaskOverlayWorkBlockComponentStep>(ETaskOverlayWorkBlockComponentStep.WORK_BLOCK_DETAILS);

  const getHeaderTextByStep = () => {
    switch (componentStep) {
      case ETaskOverlayWorkBlockComponentStep.DURATION_PRESET_OPTIONS:
      case ETaskOverlayWorkBlockComponentStep.DURATION_CUSTOM_SELECTOR:
        return t("createOrEditDurationPopupHeaderText");
      default:
        return t("createOrEditWorkTimePopupHeaderText");
    }
  }

  const onSet = () => {
    if (componentStep !== ETaskOverlayWorkBlockComponentStep.WORK_BLOCK_DETAILS) {
      return () => setComponentStep(ETaskOverlayWorkBlockComponentStep.WORK_BLOCK_DETAILS);
    }
    return handleSet;
  }

  const handleClearByStep = () => {
    switch (componentStep) {
      case ETaskOverlayWorkBlockComponentStep.DURATION_PRESET_OPTIONS:
      case ETaskOverlayWorkBlockComponentStep.DURATION_CUSTOM_SELECTOR:
        setSelectedDuration(null);
        setComponentStep(ETaskOverlayWorkBlockComponentStep.WORK_BLOCK_DETAILS);
        break;
      default:
        handleClear();
        break;
    }
  }

  const handleBackComponentStepChange = () => {
    switch (componentStep) {
      case ETaskOverlayWorkBlockComponentStep.DURATION_CUSTOM_SELECTOR:
        setComponentStep(ETaskOverlayWorkBlockComponentStep.DURATION_PRESET_OPTIONS);
        break;
      default:
        setComponentStep(ETaskOverlayWorkBlockComponentStep.WORK_BLOCK_DETAILS);
        break;
    }
  }

  const handleDurationChange = (duration: number | null) => {
    setSelectedDuration(duration);
    setComponentStep(ETaskOverlayWorkBlockComponentStep.WORK_BLOCK_DETAILS);
  }

  const renderContentBySteps = () => {
    switch (componentStep) {
      case ETaskOverlayWorkBlockComponentStep.DURATION_PRESET_OPTIONS:
      case ETaskOverlayWorkBlockComponentStep.DURATION_CUSTOM_SELECTOR:
        return (
          <TaskEditOverlayDurationPicker
            handleDurationChange={handleDurationChange}
            handleSet={handleSet}
            handleClear={handleClear}
            headerText={t("createOrEditDurationPopupHeaderText")}
            initialDuration={selectedDuration}
            onChooseDuration={setSelectedDuration} onClose={handleClose} isSubTask={isSubTask} isShowHeader={false} />
        )
      default:
        return (
          <>
            <OverlayTypeDetails icon={clockIcon}
              title={t("addEditTaskDurationText")}
              data={minutesToHoursAndMinutesStringRepresentation(Number(selectedDuration!) / 60)}
              addOpacity={!selectedDuration && selectedDuration !== 0}
              onClick={() => {
                setComponentStep(ETaskOverlayWorkBlockComponentStep.DURATION_PRESET_OPTIONS);
              }}
            />
            <TaskOverlayWorkBlockDetails taskWorkBlockDetails={taskWorkBlockDetails} onClickDetails={() => !!setCalendarAsTimePicker && setCalendarAsTimePicker(isMultiWorkTimeSelector, taskId)}/>
          </>
        )
    }
  }

  return (
    <div className='task-work-block-overlay'>
      <TaskEditOverlayHeaderAndAction
        headerText={getHeaderTextByStep()}
        onClear={handleClearByStep}
        onSet={onSet()}
        onClickBackArrow={componentStep !== ETaskOverlayWorkBlockComponentStep.WORK_BLOCK_DETAILS ? handleBackComponentStepChange : (isSubTask || isMobileView()) ? handleClose : null}
      />
      {renderContentBySteps()}
    </div>
  )
}

export default TaskOverlayWorkBlock