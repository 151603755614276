import { FunctionComponent } from 'react'

interface IProps {
  text?: string,
}

const DebugMessageContent: FunctionComponent<IProps> = ({ text }) => {
  return (
    <div className='message debug-message'>
      <div className='msg-content'>{text}</div>
    </div>
  )
}

export default DebugMessageContent;
