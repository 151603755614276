import { IUser } from "../../../../app/auth/auth.interfaces";
import { userInfoLocalStorageKey } from "../../../../app/constants";
import { getItemFromLocalStorage } from "../../../utils/localStorage.utils";
import { IOverlayStyle } from "../../app-overlay-popover/AppOverlayPopover";

export const userPhoneFormatter = (phone?: string): string => {
    const userLocalStoragePhone = getItemFromLocalStorage<IUser>(userInfoLocalStorageKey)?.phoneNumber ?? '';
    const phoneNumber = phone ? phone : userLocalStoragePhone;
    return phoneNumber.replaceAll(' ', '');
}

export const defaultHourDailyReminder = 7;
export const defaultHourEveningReminder = 17;

export const settingOverlaysPositionStyle: IOverlayStyle = {
    left: 'unset',
    right: 100
}