import { useState } from "react";
import { useAppDispatch } from "../../app/store";
import { IAPIError } from "../api/models";
import { AsyncThunk } from "@reduxjs/toolkit";
import { ApplicationInsightsApi } from "../../application-insights";

export const useFileDownloader = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const downloadFile = async (getFileReqAction: AsyncThunk<any, string, { rejectValue: IAPIError; }>, reqParams: string) => {
    setIsLoading(true);
    setError(null);
    try {
      dispatch(getFileReqAction(reqParams))
        .unwrap()
        .then(response => {
          const source = `data:${response.contentType};base64,` + response.fileContents;
          const link = document.createElement('a');
          link.href = source;
          link.setAttribute('download', response.fileDownloadName);
          link.click();
          link.remove();
          setIsLoading(false);
        }).catch(() => { setError('An error occurred'); setIsLoading(false); })
    } catch (error) {
      setIsLoading(false);
      setError('An error occurred');
      ApplicationInsightsApi.trackException(error);
    }
  };

  return { downloadFile, isLoading, error };
};
