import React, { FunctionComponent} from 'react'
import { ContentFrameWrapper } from '../../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { Link } from 'react-router-dom';
import { APP_ROUTING_PATHS } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { useNavigateWithSearchParams } from '../../../../shared/utils/routingUtils';
import PhoneVerificationByOtp from '../../../../shared/components/phone-verification-flow/phone-verification-by-otp/PhoneVerificationByOtp';
import './PhoneNumberVerificationAuthPage.scss';

interface IProps {
  isLoginWithPhoneFlow?: boolean;
}

const PhoneNumberVerificationAuthPage: FunctionComponent<IProps> = ({ isLoginWithPhoneFlow }) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearchParams();

  return (
    <div className="phone-verification-page shorten-onboarding-flow auth-screen" id="phone-verification-page" data-testid="phone-verification-page">
      <ContentFrameWrapper className='phone-verification-frame-wrapper'>
        <PhoneVerificationByOtp 
          className='signup-signin-phone-verification-form'
          isLoginWithPhoneFlow={isLoginWithPhoneFlow} 
          onFulfilledVerifyOtp={() => navigate(APP_ROUTING_PATHS.REDIRECT)} 
          submitText={t('welcomeScreenButtonText')} 
          trackEventName={`user-${isLoginWithPhoneFlow ? "signin" : "signup"}-verify-phone`} />
      </ContentFrameWrapper>
      <Link onClick={() => navigate(-1)} to={APP_ROUTING_PATHS.ADD_PHONE_REGISTER} className='back-link'>{t('registerScreenBackButtonText')}</Link>
    </div>
  )
}

export default PhoneNumberVerificationAuthPage