import { ReactComponent as PlusSvgComponent } from "../../../assets/images/plan-create-plus-icon.svg"
import createAppOverlayPopover, { EAppOverlaySlideInMobileAnimation } from "../../../shared/components/app-overlay-popover/createAppOverlayPopover"
import PlusButtonOverlay from "./plus-button-overlay/PlusButtonOverlay"
import './CreatePlusButton.scss';
import { useAppSelector } from "../../../app/store";
import AppButton from "../../../shared/components/app-button/AppButton";
import { ECloseSwipeDirection } from "../../../shared/hooks/swipe-hooks/swipe.utils";

const CreatePlusButton = () => {
  const { currentTaskPlacement} = useAppSelector(store => store.StagePlannerReducer);

  const onOpenOverlay = () => {
    createAppOverlayPopover(
      <PlusButtonOverlay />,
      null,
      null,
      null, 
      { // overlay config
        slideInMobileAnimation: EAppOverlaySlideInMobileAnimation.HALF_SCREEN, 
        shouldCloseBySwipeOnMobile: true, 
        closeSwipeDirection: ECloseSwipeDirection.DOWN
      }
    )
  }

  return (
    <>
    {!currentTaskPlacement && <AppButton id="plan-view-plus-button" className="create-plus-btn" onClick={onOpenOverlay}>
      <PlusSvgComponent />
    </AppButton>}
    </>
  )
}

export default CreatePlusButton
