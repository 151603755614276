import './StagePlanner.scss';
import { MyPlan } from '../../../../plan/my-plan/MyPlan';
import { PlanScrollContainer } from '../../../../plan/plan-scroll-container/PlanScrollContainer';
import { ETasksGroupsType } from '../stage-tasks/stageTasks.interface';
import { MyDay } from '../../../../plan/my-day/MyDay';
import { useAppSelector } from '../../../../../app/store';
import { MyWeek } from '../../../../plan/my-week/MyWeek';
import CreatePlusButton from '../../../../plan/create-plus-button/CreatePlusButton';
import { PlanTasksPlacementList } from '../../../../plan/plan-tasks-list/PlanTasksPlacementList';
import { DraggedCalendarPlanDestinationPreview } from '../../../../../shared/components/dragged-destination-preview/DraggedCalendarPlanDestinationPreview';
import ProductTourWrapper, { EViewType } from '../../../../product-tour/ProductTourWrapper';
import './PlanTour.scss'

export interface IPlannerProps {
  plannerDisplayType?: ETasksGroupsType | null;
}

export const StagePlanner = ({ plannerDisplayType }: IPlannerProps) => {
  const { tasksFilter } = useAppSelector(store => store.StageTasksReducer);

  return (
    <div className='stage-child-component stage-planner-container'>
      <div className='stage-planner-content-container'>
        <div className='stage-planner-content-planner-container'>
          <PlanScrollContainer
            className={'plan-container-horizontal'}
            scrollDirection={'x'}>
            {tasksFilter === ETasksGroupsType.MY_PLAN && <MyPlan />}
            {tasksFilter === ETasksGroupsType.MY_DAY && <MyDay />}
            {tasksFilter === ETasksGroupsType.MY_WEEK && <MyWeek />}
          </PlanScrollContainer>
          <CreatePlusButton />
        </div>
        <div className='stage-planner-content-unscheduled-tasks-container'>
          <PlanTasksPlacementList />
        </div>
      </div>
      <DraggedCalendarPlanDestinationPreview />
      <ProductTourWrapper
        dataAttribute='data-plan-tour'
        className='mini-plan-tour'
        viewType={EViewType.PLAN_TOUR}
      />
    </div>
  )
}