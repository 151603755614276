import React from 'react'
import './TaskEditOverlayQuickOptionItem.scss';
import AppButton from '../../../../../../shared/components/app-button/AppButton';

export interface ITaskOverlayQuickOption {
  header: string;
  date?: Date;
  action?: () => void;
}

const TaskEditOverlayQuickOptionItem = ({ option }: { option: ITaskOverlayQuickOption }) => {
  return (
    <AppButton id={`task-edit-overlay-quick-option-${option.header?.trim()}`} className="task-overlay-quick-options-single-option" key={option.header} onClick={option.action}>
      <div className="task-overlay-quick-options-single-option-content-container">
        <h2>{option.header}</h2>
        {option?.date &&
          <>
            <p>{option.date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' }).replace(',', '')}</p>
            <p>{option.date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</p>
          </>
        }
      </div>
    </AppButton>
  )
}

export default TaskEditOverlayQuickOptionItem;
