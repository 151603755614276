import { FunctionComponent, useState } from 'react';
import './EditPhoneModal.scss';
import EnterPhoneAndGetOtpForm from '../../../../../phone-verification-flow/enter-phone-and-get-otp-form/EnterPhoneAndGetOtpForm';
import { useTranslation } from 'react-i18next';
import PhoneVerificationByOtp from '../../../../../phone-verification-flow/phone-verification-by-otp/PhoneVerificationByOtp';
import { ReactComponent as CloseModalSvgComponent } from "../../../../../../../assets/images/close-create-task-modal-mobile.svg";
import AppButton from '../../../../../app-button/AppButton';
import removeAppOverlayPopover from '../../../../../app-overlay-popover/removeAppOverlayPopover';

enum EEditPhoneModalStep {
  ENTER_PHONE_NUMBER,
  ENTER_OTP_CODE,
}

interface IEditPhoneModalProps {
  phoneNumber?: string;
  setPhoneNumber: (phoneNumber: string) => void;
}

const EditPhoneModal: FunctionComponent<IEditPhoneModalProps> = ({ phoneNumber, setPhoneNumber }) => {
  const [componentStep, setComponentStep] = useState<EEditPhoneModalStep>(EEditPhoneModalStep.ENTER_PHONE_NUMBER);
  const { t } = useTranslation();

  const renderStepComponent = () => {
    switch (componentStep) {
      case EEditPhoneModalStep.ENTER_PHONE_NUMBER:
        return (
          <EnterPhoneAndGetOtpForm
            className='settings-enter-phone-number'
            onFulfilledSendOtp={() => setComponentStep(EEditPhoneModalStep.ENTER_OTP_CODE)}
            title={t("settingsAddOrChangePhoneNumberTitle")}
            subTitle={t("settingsAddOrChangePhoneNumberSubTitle")}
            defaultPhoneNumber={phoneNumber}
            trackEventName='update-user-phone-in-settings-enter-phone'
          />
        );
      case EEditPhoneModalStep.ENTER_OTP_CODE:
        return (
          <PhoneVerificationByOtp onFulfilledVerifyOtp={(phone: string) => {setPhoneNumber(phone); onCloseModal();}} trackEventName='update-user-phone-in-settings-verify-phone' />
        );
      default:
        return <></>;
    }
  };

  const onCloseModal = () => {
    removeAppOverlayPopover({ shouldSlideOutMobileAnimation: true, shouldFadeOutDesktopAnimation: true });
  }

  return (
    <div className='edit-phone-modal' data-testid="settings-edit-phone-modal">
      <AppButton
        id='settings-close-edit-phone-modal'
        className='close-edit-phone-modal'
        onClick={onCloseModal}>
        <CloseModalSvgComponent />
      </AppButton>
      {renderStepComponent()}
    </div>
  )
}

export default EditPhoneModal