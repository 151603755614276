import React, { FunctionComponent } from 'react'
import TaskActionButton from './TaskActionButton';
import createAppOverlayPopover, { EAppOverlaySlideInMobileAnimation } from '../../../../../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import { TaskEditOverlay } from '../../../../../../resizable-container/stage-container/stage-tasks/task-edit-overlays/TaskEditOverlay';
import { ITaskSingleActionButtonsProps } from '../TasksListSingleItem.interfaces';
import { useTranslation } from 'react-i18next';
import { ECloseSwipeDirection } from '../../../../../../../../shared/hooks/swipe-hooks/swipe.utils';


const TaskDueActionButton: FunctionComponent<ITaskSingleActionButtonsProps> = ({task, onUpdateTask, actionOverlaysPositionStyle, className = "" }) => {
  const { t } = useTranslation();
  
  const onChangeDueDate = (dueDate: Date, reminder?: number | null) => {
    onUpdateTask({ dueDate: dueDate ? (dueDate as Date).toISOString() : null, dueDateReminder: reminder || null });
  }

  const onClickDueAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    createAppOverlayPopover(
      <TaskEditOverlay
        overlayType='dueDate'
        date={task.dueDate ? new Date(task.dueDate) : undefined}
        onChange={(date, reminder) => onChangeDueDate(date as Date, reminder)}
      />,
      null,
      e,
      actionOverlaysPositionStyle,
      {
        slideInMobileAnimation: EAppOverlaySlideInMobileAnimation.HALF_SCREEN, 
        shouldCloseBySwipeOnMobile: true, 
        closeSwipeDirection: ECloseSwipeDirection.DOWN
      }
    )
  }

  return (
    <TaskActionButton
      actionName={t("taskDueActionButtonText")}
      actionType='due'
      onClickAction={(e) => onClickDueAction(e)}
      className={className}
    />
  )
}

export default TaskDueActionButton;
