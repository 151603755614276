// Code to remove the overlay popover from the dom
// call this component when you want to close the overlay popover

interface IRemoveAppOverlayConfig {
    shouldSlideOutMobileAnimation?: boolean;
    shouldFadeOutDesktopAnimation?: boolean;
}

const removeAppOverlayPopover = (removeAppOverlayConfig?: IRemoveAppOverlayConfig) => {
    if ((window as any).overlayContainer) {
        if (removeAppOverlayConfig?.shouldSlideOutMobileAnimation || removeAppOverlayConfig?.shouldFadeOutDesktopAnimation) {
            const popoverElement = document.getElementById('app-overlay-popover-content-container');
            removeAppOverlayConfig?.shouldSlideOutMobileAnimation && popoverElement?.classList.add("slideOut-mobile");
            removeAppOverlayConfig?.shouldFadeOutDesktopAnimation && popoverElement?.classList.add("fadeOut");
            const timer = setTimeout(() => {
                (window as any).overlayContainer.render(<></>);
                clearTimeout(timer);
            }, 500);
        } else (window as any).overlayContainer.render(<></>);
    }
}
export default removeAppOverlayPopover