import { UseFormReturn } from "react-hook-form";
import { IOverlayStyle } from "../../../../../../shared/components/app-overlay-popover/AppOverlayPopover";
import SassVariables from "../../../../../../styles/style.module.scss";
import { ETaskStatus, IAddEditTaskForm, IMessageDataTask } from "../stageTasks.interface";
import { ISubTaskForEditing } from "./CreateOrEditTask";
import { timePickerContextOverlayDefaultWidth } from "../../../../../../app/constants";

export const editOverlaysPositionStyle: IOverlayStyle = {
  left: 'unset',
  right: SassVariables.createOrEditTaskOverlaysPositionRight, // get from sass variables
}

export const taskFormToTaskEntity = (taskForm: UseFormReturn<IAddEditTaskForm, any>, itemForEditing: ISubTaskForEditing | null ,subTaskIndex?: number): IMessageDataTask => {
  const subTasks = taskForm.getValues()?.subTasks;
  const subTask = subTaskIndex && subTasks && subTasks.length ? subTasks[subTaskIndex] : null;
  const taskFormValues = taskForm.getValues();
  const taskEntity: IMessageDataTask = {
    id: subTask ? subTask.taskId : itemForEditing?.id || undefined,
    name: subTask ? subTask.name : taskFormValues?.name || "",
    dueDate: subTask ? subTask.dueDate ? new Date(subTask.dueDate).toISOString() : null : taskFormValues?.dueDate ? new Date(taskFormValues.dueDate).toISOString() : null,
    duration: subTask ? subTask.duration : taskFormValues?.duration,
    workTime: subTask ? subTask.workTime ? new Date(subTask.workTime).toISOString() : null : taskFormValues?.workTime ? new Date(taskFormValues.workTime).toISOString() : null,
    status: subTask ? subTask.status : taskFormValues?.isDone ? ETaskStatus.DONE : ETaskStatus.NOT_STARTED,
    priority: subTask ? subTask.priority : taskFormValues?.priority,
    quickWin: subTask ? subTask.priority : taskFormValues?.quickWin,
    description: subTask ? subTask.description : taskFormValues?.description,
    tags: subTask ? subTask.tags : taskFormValues?.tags,
    dueDateReminder: subTask ? subTask.dueDateReminder : taskFormValues?.dueDateReminder || null,
    workTimeReminder: subTask ? subTask.workTimeReminder : taskFormValues?.workTimeReminder || null,
    notes: subTask ? subTask.notes : taskFormValues?.notes,
    creationTime: subTask ? subTask.creationTime ? new Date(subTask.creationTime).toISOString() : itemForEditing?.creationTime ? new Date(itemForEditing.creationTime).toISOString() : new Date().toISOString() : new Date().toISOString(),
  }
  return taskEntity;
}

export const getElementPosition = (elem: HTMLElement): Partial<IOverlayStyle> => {
  const root  = document.documentElement,
      body  = document.body,
      scrollTop  = root.scrollTop  || body.scrollTop,
      scrollLeft = root.scrollLeft || body.scrollLeft,
      clientTop  = root.clientTop  || body.clientTop  || 0,
      clientLeft = root.clientLeft || body.clientLeft || 0,
      elRect  = elem.getBoundingClientRect(),
      top   = Math.round(elRect.top  + scrollTop  - clientTop),
      left  = Math.round(elRect.left + scrollLeft - clientLeft);

  return {
      top  : top,
      left : left
  };
}

export const getElementWidthAndHeight = (elem: HTMLElement): Partial<IOverlayStyle> => {
  return {
    width: elem?.getBoundingClientRect()?.width,
    height: elem?.getBoundingClientRect()?.height
  }
}

export const plannerTaskContextOverlayStyle = {
  bottom: SassVariables.overlayDesktopBottomHeight,
  left: "unset",
  right: 50,
  width: `${timePickerContextOverlayDefaultWidth}px`
}