import { FunctionComponent, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { tokenLocalStorageKey, userInfoLocalStorageKey } from '../constants';
import { useAppSelector } from '../store';
import { useLocalStorage } from '../../shared/utils/useLocalStorage';
import { IUser } from '../auth/auth.interfaces';

interface IProps {
    children: ReactElement;
}

// use protect routes that require just token
export const ProtectedRegisterRoute: FunctionComponent<IProps> = ({ children }) => {
    const [userInfoLocalStorage,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
    const [tokenLocalStorage,] = useLocalStorage(tokenLocalStorageKey, '');
    const { loginRes } = useAppSelector(store => store.authReducer);
    const { userAppData } = useAppSelector(store => store.userReducer);
    const token = loginRes.data ? loginRes.data.token : tokenLocalStorage;
    const userInfoWithPhone = userAppData.data ? userAppData.data.user?.phoneNumber : userInfoLocalStorage?.phoneNumber;
    const isUserActive = userAppData.data ? userAppData.data.user?.isActive : userInfoLocalStorage?.isActive;

    return (!!token && (!isUserActive || (isUserActive && !userInfoWithPhone))) ? children : <Navigate to='/' replace />;
};
