import { MutableRefObject, useEffect, useState } from "react";

export const useMutationObserver = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: () => void,
  customDependency?: boolean // Optional dependency to trigger the effect, add the needed type if necessary
) => {
  const [myObserver, setObserver] = useState<MutationObserver | null>(null);

  useEffect(() => {
    if (ref.current) {
      if (!myObserver) {
        const observer = new MutationObserver(callback);
        observer.observe(ref.current, { childList: true });
        setObserver(observer);
      }
      return () => {if (myObserver) myObserver.disconnect()};
    }
    
  }, [callback, myObserver, ref, customDependency]);
};