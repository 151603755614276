import { FocusEvent, FunctionComponent } from "react"
import { handleInputKeyDown } from "../../../../../shared/utils/utils";
import { ETaskStatus, IMessageDataTask } from "../stage-tasks/stageTasks.interface";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { createTaskReqAction, getTasksListReqAction } from "../stage-tasks/stageTasks.store";
import './AddTaskField.scss';
import { setQueueMessageData } from "../../../chat.store";
import { uxEventAddTaskOrSubTask } from "../../../../../shared/utils/uxEventsUtils";
import { uuid } from "../../../../../shared/utils/uuid";

interface IAddTaskFieldProps {
  taskStatus: ETaskStatus,
  placeholder: string,
  onChange?: (newTask:IMessageDataTask) => void;
  className?: string
}
const AddTaskField: FunctionComponent<IAddTaskFieldProps> = ({ taskStatus, placeholder,onChange, className = '' }) => {
  const { sessionResponse } = useAppSelector(store => store.chatReducer);
  const dispatch = useAppDispatch();

  const onAddTask = (e: FocusEvent<HTMLInputElement, Element>) => {
    const taskName = e.target?.value || "";
    const newTaskId = uuid();
    if (taskName?.length > 0 && sessionResponse?.data?.sessionId) {
      const reqPayload = {
        id: newTaskId,
        name: taskName,
        status: taskStatus,
        sessionId: sessionResponse?.data?.sessionId
      }
      if(onChange) onChange(reqPayload as unknown as IMessageDataTask)
      dispatch(createTaskReqAction(reqPayload)).unwrap()
        .then((response) => {
          dispatch(getTasksListReqAction());
          if (response?.length > 0) dispatch(setQueueMessageData({ type: 'automatic', botRequestJson: JSON.stringify(uxEventAddTaskOrSubTask(null, response[0], null))}));
        });

      e.target.value = '';
    }
  }

  return (
    <input
      type="text"
      name='add-task'
      aria-label='add-task'
      className={`add-task-input ${className}`}
      placeholder={placeholder}
      autoComplete='off'
      onBlur={onAddTask}
      onKeyDown={handleInputKeyDown} />
  )
}

export default AddTaskField
