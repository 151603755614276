import { ContentFrameWrapper } from '../../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { APP_ROUTING_PATHS, isSignUpLocalStorageKey } from '../../../constants';
import { getItemFromLocalStorage } from '../../../../shared/utils/localStorage.utils';
import { useNavigateWithSearchParams } from '../../../../shared/utils/routingUtils';
import { handleLogOut } from '../../../../shared/utils/logOut';
import EnterPhoneAndGetOtpForm from '../../../../shared/components/phone-verification-flow/enter-phone-and-get-otp-form/EnterPhoneAndGetOtpForm';
import './EnterPhoneAuthPage.scss';

const EnterPhoneAuthPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearchParams();
  const isComeFromSignUp = !!getItemFromLocalStorage(isSignUpLocalStorageKey);

  return (
    <div className="add-phone-registration-page add-phone-form-page shorten-onboarding-flow auth-screen" id="update-phone-page" data-testid="update-phone-page">
      <ContentFrameWrapper className='add-phone-frame-wrapper'>
        <EnterPhoneAndGetOtpForm
          title={t('phoneRegistrationScreenTitleNewFlow')} 
          subTitle={t("phoneRegistrationScreenParagraph1NewFlow")} 
          onFulfilledSendOtp={()=>navigate(APP_ROUTING_PATHS.PHONE_VERIFICATION)} 
          isRegistrationFlow={true} 
          trackEventName={`user-${isComeFromSignUp ? "signup" : "signin"}-enter-phone`} />
        </ContentFrameWrapper>
      <Link to={isComeFromSignUp ? APP_ROUTING_PATHS.SIGN_UP : APP_ROUTING_PATHS.SIGN_IN} onClick={() => handleLogOut(false)} className='back-link'>{t('registerScreenBackButtonText')}</Link>
    </div>
  )
}

export default EnterPhoneAuthPage