import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Chat } from '../chat/Chat';
import { useSearchParams } from 'react-router-dom';
import { stageParam } from '../../../app/constants';
import { BackgroundAnimation } from '../chat/background-animation/BackgroundAnimation';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { EAPIStatus } from '../../../shared/api/models';
import StageContainer from './stage-container/StageContainer';
import { Navbar } from './navbar/Navbar';
import { useApiData } from '../../../shared/hooks/useApiData';
import { getTasksListReqAction } from './stage-container/stage-tasks/stageTasks.store';
import { isMobileView } from '../../../shared/utils/utils';
import { EAppStageContentType } from './stage-container/stageContainer.interfaces';
import './ResizableContainer.scss';
import { getSessionSummariesReq } from '../chat.store';
import { FormProvider, useForm } from 'react-hook-form';
import { IChatForm } from '../chat.interfaces';
import { EAnimationModeClassName } from './ResizableContainer.interfaces';

export const ResizableContainer: FunctionComponent = () => {
  const [searchParams,] = useSearchParams();
  const { botResponse, sessionResponse } = useAppSelector(store => store.chatReducer);
  const { shouldStageExpand } = useAppSelector(store => store.StageTasksReducer);
  const stageOpened: boolean = useMemo(() => !!searchParams.get(stageParam), [searchParams]);
  const [animationModeClassName, setAnimationModeClassName] = useState<EAnimationModeClassName>(stageOpened ? EAnimationModeClassName.TO_COLLAPSE_FROM_CHAT_VIEW : EAnimationModeClassName.CHAT_VIEW_FADE_IN);
  const prevShouldStageExpandState = useRef<boolean>(shouldStageExpand);
  const isFirstTimeAfterRender = useRef(true);
  const chatBarForm = useForm<IChatForm>({
    defaultValues: {
      userMessage: "",
      messagesArr: [],
      isRecording: false,
      shouldDisplayRecordingErrorMsg: false
    }
  });
  const dispatch = useAppDispatch();

  const updateAnimationMode = useCallback(() => {
    // move from collapse/expend to chat view
    if (!stageOpened) {
      if (shouldStageExpand) setAnimationModeClassName(EAnimationModeClassName.TO_CHAT_VIEW_FROM_EXPEND);
      else setAnimationModeClassName(EAnimationModeClassName.TO_CHAT_VIEW_FROM_COLLAPSE);
      return;
    }
    // from collapse to expand
    else if (shouldStageExpand && !prevShouldStageExpandState.current) {
      setAnimationModeClassName(EAnimationModeClassName.TO_EXPEND_FROM_COLLAPSE);
    }
    // from expand to collapse
    else if (!shouldStageExpand && prevShouldStageExpandState.current) {
      setAnimationModeClassName(EAnimationModeClassName.TO_COLLAPSE_FROM_EXPEND);
    }
    // move from chat view to stage collapse/expend
    else if (animationModeClassName?.includes("chat")) {
      if (shouldStageExpand) setAnimationModeClassName(EAnimationModeClassName.TO_EXPEND_FROM_CHAT_VIEW);
      else setAnimationModeClassName(EAnimationModeClassName.TO_COLLAPSE_FROM_CHAT_VIEW);
      return;
    }
    prevShouldStageExpandState.current = shouldStageExpand;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldStageExpand, stageOpened])

  useEffect(() => {
    if(!isFirstTimeAfterRender.current) updateAnimationMode();
    else isFirstTimeAfterRender.current = false;
  }, [updateAnimationMode]);

  useApiData(sessionResponse, {
    onFulfilled: () => {
      dispatch(getTasksListReqAction());
      dispatch(getSessionSummariesReq({})); // TODO: remove after global client sync implementation
    }
  });

  const containerClassName = () => {
    let className = "resizable-container";
    if (stageOpened) {
      className += " display-stage";
      if (shouldStageExpand) className += " stage-expanded";
      if (searchParams.get(stageParam) === EAppStageContentType.TASKS) className += " task-list";
      else if (searchParams.get(stageParam) === EAppStageContentType.SCRATCHPAD) className += " scratchpad";
      else if (searchParams.get(stageParam) === EAppStageContentType.PLANNER) className += " planner";
    }
    return className;
  }

  return (
    <div className={`${containerClassName()} ${animationModeClassName}`}>
      <BackgroundAnimation className={`${botResponse.status === EAPIStatus.PENDING ? 'fadeIn' : 'fadeOut'}`} />
      <FormProvider {...chatBarForm}>
        <div className="flex-chat-stage-container">
          <Chat />
          {!isMobileView() && <StageContainer />}
        </div>
      </FormProvider>
      <Navbar />
    </div>
  );
}