import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EditProfileForm } from './edit-profile-form/EditProfileForm';
import { ResetProfile } from './reset-profile/ResetProfile';
import { useAppDispatch } from '../../../../../app/store';
import { getUserProfileReq } from '../settingsMenu.store';
import './PersonalInfo.scss';
import { CalendarSync } from './calendar-sync/CalendarSync';
import { Notifications } from '../notifications/Notifications';
import AudioControl from '../audio-control/AudioControl';

export const PersonalInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserProfileReq());
  }, [dispatch])

  return (
    <section className="personal-info settings-section-container">
      <h2 className='sub-title static-string'>{t("settingsPersonalInfoTitle")}</h2>
      <div className='separation'></div>
      <EditProfileForm />
      <CalendarSync />
      <ResetProfile />
      <AudioControl />
      <Notifications />
    </section>
  )
}