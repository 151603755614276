import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import './PlanScrollContainer.scss';
import { useScrollThreshold } from "../../../shared/hooks/useScrollThreshold";
import { isMobileView } from "../../../shared/utils/utils";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { setActiveMyPlanDay } from "../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store";

interface PlanScrollContainerProps {
    children: React.ReactNode;
    className?: string;
    scrollDirection?: 'x' | 'y';
}

export const PlanScrollContainer: FunctionComponent<PlanScrollContainerProps> = ({ children, className, scrollDirection }) => {
    const scrollableRef = useRef<HTMLDivElement>(null);
    const [myPlanDays, setMyPlanDays] = useState(5);
    const {activeMyPlanDay} = useAppSelector(store => store.StageTasksReducer);
    const dispatch = useAppDispatch();

    const handleThresholdReached = () => {
        setMyPlanDays(myPlanDays + 5);
      };

    useEffect(() => {
        if (activeMyPlanDay) {
            const goToDayIndex = Number(activeMyPlanDay.split('-')[1]);
            if (goToDayIndex > myPlanDays) {
                setMyPlanDays(goToDayIndex + 1);
            }
            setTimeout(() => {
                const myPlanContainer = document.getElementById(activeMyPlanDay);
                if (myPlanContainer) {
                    myPlanContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 0);
            dispatch(setActiveMyPlanDay(null));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMyPlanDay, dispatch]);

    // responsible for adding days to the plan when the user scrolls to the bottom of the plan on mobile and to the most right on desktop
    useScrollThreshold({
        ref: scrollableRef,
        threshold: scrollableRef && scrollableRef.current ? scrollableRef.current.clientHeight : 200, // Threshold in pixels
        direction: scrollDirection ? scrollDirection : isMobileView() ? 'y' : 'x', // 'x' for horizontal, 'y' for vertical
        onThresholdReached: handleThresholdReached,
        debounceTime: isMobileView() ? 0 : 200
      });

    return <div id='plan-scroll-container' ref={scrollableRef} className={`plan-scroll-container scroll-y-container-hidden-scroll-bar${className ? ` ${className}` : ''}`}>
        {!!children && React.Children.map(children, child => {
            return !!child && React.cloneElement(child as React.ReactElement, { myPlanDays });
        })}

    </div>;
}

