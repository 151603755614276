import { TouchBackend } from 'react-dnd-touch-backend'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createTransition } from 'react-dnd-multi-backend';
import { isMobileDevice } from './shared/utils/isMobileDevice';

const TouchTransition = createTransition('touchstart', (e: any) => {
  return e?.touches != null
});

const MouseTransition = createTransition('mouseDown', (e: any) => {
  return !e?.touches;
});

export const HTML5AndTouchBackends = {
  backends: isMobileDevice() ? [
    {
      backend: TouchBackend,
      options: { enableMouseEvents: false },
      preview: true,
      transition: TouchTransition,
    },
    {
      backend: HTML5Backend,
      transition: MouseTransition,
      preview: true
    },
  ] :
  [
    {
      backend: HTML5Backend,
      transition: MouseTransition,
      preview: true
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: false },
      preview: true,
      transition: TouchTransition,
    },
  ]
  ,
};