import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { chatHistoryParam } from '../../../../app/constants';
import { SessionSummaries } from './SessionSummaries';
import './SessionSummaries.scss';

export const SessionSummariesWrapper = () => {
  const [searchParams,] = useSearchParams();

  return (
    <>
      {searchParams.get(chatHistoryParam) && <SessionSummaries/>}
    </>
  )
}