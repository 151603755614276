interface IDataLayer {
    variable_name: string;
    variable_value: string;
}
export const setSeoDocumentTitle = (title: string) => {
    document.title = title + ' | Maximal-Learning';
    document.querySelector('head meta[name="description"]')?.setAttribute('content', title);

    if (window.dataLayer) window.dataLayer.push({ variable_name: 'event', variable_value: title });
};
window.dataLayer = window.dataLayer || [];

declare global {
    interface Window {
        dataLayer: IDataLayer[];
    }
}
