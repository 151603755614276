import { ISpeechTokenResponse } from "./speech.interfaces";

export const speechCookieParseToTokenPayload = (cookieValue: string): ISpeechTokenResponse | null => {
    try {
        const delimiterIndex = cookieValue.indexOf(':');
        return { token: cookieValue.slice(delimiterIndex + 1), region: cookieValue.slice(0, delimiterIndex) };
    } catch (error) {
        console.error("unable to parse speech cookie value")
        return null;
    }
}