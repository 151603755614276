import { FunctionComponent } from 'react';
import { UserAvatarHeader } from './user-avatar/UserAvatarHeader';
import { ToggleHistoryHeaderIcon } from './toggle-history-icon/ToggleHistoryHeaderIcon';
import { StageTasksDropdownFilters } from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stage-tasks-dropdown-filters/StageTasksDropdownFilters';
import './Header.scss';
import ClearScratchpadButton from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-scratchpad/clear-scratchpad-button/ClearScratchpadButton';
import { PlanUnscheduledFab } from '../../../../features/plan/plan-unscheduled-fab/PlanUnscheduledFab';
import { useSearchParams } from 'react-router-dom';
import { planTourDataAttrChangeViewsMobile, planTourDataAttrPlannerMobile, stageParam } from '../../../../app/constants';

export const Header: FunctionComponent = () => {
  const [searchParams] = useSearchParams();

  const displayPlannerFabOrAvatar = () => {
    if (searchParams.get(stageParam) === 'planner') {
      return <PlanUnscheduledFab planTourDataAttr={planTourDataAttrPlannerMobile} />;
    }
    return <UserAvatarHeader />;
  }

  return (
    <header className="header-container">
      <div className='header-content'>
        <StageTasksDropdownFilters data-plan-tour={planTourDataAttrChangeViewsMobile}/>
        <ClearScratchpadButton />
        <ToggleHistoryHeaderIcon />
        {displayPlannerFabOrAvatar()}
      </div>
    </header>
  )
}