import { EOperatingSystem } from "../../app/auth/auth.interfaces";

export const getOperatingSystem = (): EOperatingSystem => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
        return EOperatingSystem.ANDROID;
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return EOperatingSystem.IOS;
    }
    return EOperatingSystem.UNKNOWN;
}