import { createAction, createReducer } from "@reduxjs/toolkit";
import { ApiDataStateType, createAPIReducerCases } from "../../../../../shared/store/utils";
import { apiServiceCSharp, createApiThunk } from "../../../../../shared/api/axios";
import { API_ROUTES } from "../../../../../app/constants";
import { APIRequestState, IAPIRequestState } from "../../../../../shared/api/models";
import { ITaskFields } from "../stage-tasks/stageTasks.interface";

export interface IStageScratchpadStore extends ApiDataStateType {
  clearScratchpad: IAPIRequestState<boolean>;
  shouldOpenClearConfirmModal: boolean;
  scratchpadTaskUpdatedItem: ITaskFields | null;
}

const initialStateStageScratchpadStore: IStageScratchpadStore = {
  clearScratchpad: APIRequestState.create(),
  shouldOpenClearConfirmModal: false,
  scratchpadTaskUpdatedItem: null
};

const createReducerKey = (subKey: string): string => {
  return 'stageScratchpad/' + subKey;
};

export const clearScratchpadTasksListReqAction = createApiThunk(createReducerKey('clearScratchpadTasksListReqAction'), () =>
  apiServiceCSharp.delete<boolean>(API_ROUTES.TASKS.CLEAR_SCRATCHPAD),
);

export const setShouldOpenClearConfirmModal = createAction<boolean>(createReducerKey('setShouldOpenClearConfirmModal'));

export const setScratchpadTaskUpdatedItem = createAction<ITaskFields | null>(createReducerKey('setScratchpadTaskUpdatedItem'));

export const StageScratchpadReducer = createReducer(initialStateStageScratchpadStore, (builder) => {
  createAPIReducerCases(clearScratchpadTasksListReqAction, 'clearScratchpad', builder);

  builder.addCase(setShouldOpenClearConfirmModal, (state, action) => {
    state.shouldOpenClearConfirmModal = action.payload;
  });

  builder.addCase(setScratchpadTaskUpdatedItem, (state, action) => {
    state.scratchpadTaskUpdatedItem = action.payload;
  });
});

