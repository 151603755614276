import React, { Fragment, FunctionComponent } from 'react'
import SingleTaskItemContainer from '../../../../chat/chat-conversation/message-data-card/tasks-list/tasks-list-single-item/SingleTaskItemContainer';
import { IMessageDataTask } from '../../stage-tasks/stageTasks.interface';
import { useTranslation } from 'react-i18next';

interface IWorkBlockDetailsTasksList{
  tasksToDisplay: IMessageDataTask[];
}

const WorkBlockDetailsTasksList:FunctionComponent<IWorkBlockDetailsTasksList> = ({tasksToDisplay}) => {
  const { t } = useTranslation();

  return (
    <div className='work-block-details-tasks-container' data-testid="work-block-tasks-container">
      <h3 className='tasks-title'>{t("workBlockDetailsRelatedTasksTitle")}</h3>
      {tasksToDisplay.map((t) => {
        return (
          <Fragment key={t.id}>
            <SingleTaskItemContainer shouldHideOnComplete={false} singleTask={t} shouldDisplayFirstTagColor={true} shouldDisplayActionButtons={true} />
          </Fragment>
        )
      })}
    </div>
  )
}

export default WorkBlockDetailsTasksList