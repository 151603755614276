import React from 'react';
import { useTranslation } from 'react-i18next';
import { contactUsLink, privacyPolicyLink, termsOfServiceLink } from '../../../../../app/constants';
import './ImportantLinks.scss';
import { openExternalLinkOnMobileApp } from '../../../../../mobile-application-utils';

export const ImportantLinks = () => {
  const { t } = useTranslation();

  return (
    <section className="important-links settings-section-container" data-testid="important-links">
      <h2 className='sub-title static-string'>{t("sideMenuImportantLinksTitle")}</h2>
      <div className='separation'></div>
      <a href={termsOfServiceLink} target="_blank" onClick={(e) => openExternalLinkOnMobileApp(e, termsOfServiceLink)} rel="noreferrer">{t("termsOfServiceLinkText")}</a>
      <a href={privacyPolicyLink} target="_blank" onClick={(e) => openExternalLinkOnMobileApp(e, privacyPolicyLink)} rel="noreferrer">{t("privacyPolicyLinkText")}</a>
      <a href={contactUsLink} target="_blank" onClick={(e) => openExternalLinkOnMobileApp(e, contactUsLink)} rel="noreferrer">{t("contactUsLinkText")}</a>
    </section>
  )
}