import React, { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { chatHistoryParam, settingsMenuParam } from '../../../../../app/constants';
import closeIcon from '../../../../../assets/images/close-settings.svg';
import historyIcon from '../../../../../assets/images/history-icon-header.svg';
import './ToggleHistoryHeaderIcon.scss';

export const ToggleHistoryHeaderIcon: FunctionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onToggleChatHistoryPopUp = () => {
    if (searchParams.get(chatHistoryParam)) {
      setSearchParams(prev => {
        prev.delete(chatHistoryParam);
        return prev;
      });
    } else {
      setSearchParams(prev => {
        prev.append(chatHistoryParam, 'open');
        return prev;
      });
    }
  }

  return (
    <div className={`open-close-history-btn-container ${searchParams.get(settingsMenuParam) ? 'visibility-hidden' : ''}`} onClick={onToggleChatHistoryPopUp}>
      <button
        id="close-the-sessions-summary"
        className={`close-popup-icon close-history ${searchParams.get(chatHistoryParam) ? 'fadeIn' : 'fadeOut'}`}
        data-testid={`close-history`}
      >
        <img src={closeIcon} alt="close-settings" />
      </button>
      <button
        id="open-the-sessions-summary"
        className={`history-header-icon ${!searchParams.get(chatHistoryParam) ? 'fadeIn' : 'fadeOut'}`}
        data-testid={`history-header-btn`}
      >
        <img src={historyIcon} alt="history-icon" />
      </button>
    </div>
  )
}