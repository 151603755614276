import { userInfoLocalStorageKey } from "../../../../app/constants";
import { getItemFromLocalStorage } from "../../../utils/localStorage.utils";
import { IUser } from "../../../../app/auth/auth.interfaces";
import createAppOverlayPopover from "../../app-overlay-popover/createAppOverlayPopover";
import AppPopUpToast, { IAppPopUpToastProps } from "../AppPopUpToast";
import removeAppOverlayPopover from "../../app-overlay-popover/removeAppOverlayPopover";
import { store } from "../../../../app/store";
import { FlagUtils } from "../../../utils/flagUtils";


export const openAppToastPopUp = (appPopUpToastComponentProps: IAppPopUpToastProps) => {
  const dontShowAgainFlag = appPopUpToastComponentProps.dontShowAgainFlag;
  const userInfo = (
    getItemFromLocalStorage<IUser>(userInfoLocalStorageKey) ||
    store.getState().settingsMenuReducer?.updateUserProfile?.data ||
    store.getState().userReducer.userAppData?.data?.user
  );

  // if the dontShowAgainFlag props is true -> return
  if (
    !!dontShowAgainFlag &&
    (!!userInfo?.clientFlags || userInfo?.clientFlags === 0) &&
    FlagUtils.getFlag(userInfo.clientFlags, dontShowAgainFlag)
  ) {
    !!appPopUpToastComponentProps.onClose && appPopUpToastComponentProps.onClose();
    return;
  }

  createAppOverlayPopover(
    <AppPopUpToast
      {...appPopUpToastComponentProps}
      onClose={() => {
        !!appPopUpToastComponentProps.onClose && appPopUpToastComponentProps.onClose();
        removeAppOverlayPopover();
      }}
    />,
    null,
    null,
    undefined,
    { isAppToast: true }
  );
}
