import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isMobileView } from '../../utils/utils';
import removeAppOverlayPopover from './removeAppOverlayPopover';
import './AppOverlayPopover.scss';
import { IAppOverlayPopoverConfig } from './createAppOverlayPopover';
import SwipeToCloseWrapper from '../swipe-to-close-wrapper/SwipeToCloseWrapper';
import { slideUpFullScreenMobilePopoverClassName, slideUpHalfScreenMobilePopoverClassName } from '../../../app/constants';
import { ECloseSwipeDirection } from '../../hooks/swipe-hooks/swipe.utils';

// TODO: handle end of the screen positioning
// TODO: add support for WAI-ARIA attributes
// TODO: add support for animations
// TODO: add support for swipe gestures

interface IAppOverlayPopoverProps {
    content: React.ReactNode;
    event?: React.MouseEvent<HTMLElement> | null;
    className?: string | null;
    overlayStyle?: IOverlayStyle|null;
    config?: IAppOverlayPopoverConfig;
}

export interface IOverlayStyle extends React.CSSProperties {
    top?: string | number;
    bottom?: string | number;
    left?: string | number;
    right?: string | number;
    height?: string | number;
    width?: string | number;
    transform?: string;
}

export const AppOverlayPopover: React.FC<IAppOverlayPopoverProps> = ({ event, content, className, overlayStyle, config }) => {

    const { isCustomStyle, shouldOverrideDefaultStyles, closeOnClickOutside, allowInteraction } = config || {};
    const overlayRef = useRef<HTMLDivElement | null>(null);
    const [overlayStyleObj, setOverlayStyleObj] = useState<IOverlayStyle>({});
    const isComponentMounted = useRef(false);
    const [isSlideInMobileAnimationEnded, setIsSlideInMobileAnimationEnded] = useState<boolean>(false);

    const getStyle = useCallback((overlayContentHeight: number): IOverlayStyle => {
        if (event) {
            let isOverlayOverflowFromViewport = false;
            const eventClickClientY = event?.clientY;
            const visualViewportHeight = window.visualViewport?.height;
            if (eventClickClientY && visualViewportHeight && overlayContentHeight && visualViewportHeight - eventClickClientY < overlayContentHeight) {
                isOverlayOverflowFromViewport = true;
            }
            if (event && event!.clientX < 400) {
                return {
                    top: isOverlayOverflowFromViewport ? 'unset' : overlayStyle?.top ? overlayStyle?.top : event!.clientY,
                    left: overlayStyle?.left || event!.clientX,
                    right: overlayStyle?.right || event!.clientX,
                    bottom: isOverlayOverflowFromViewport ? "5px" : (overlayStyle?.bottom || 'unset')
                }
            }
            else if (event && event!.clientX > 400) {
                return {
                    top: isOverlayOverflowFromViewport ? 'unset' : overlayStyle?.top ? overlayStyle?.top : event!.clientY,
                    left: overlayStyle?.left || event!.clientX - 400,
                    right: overlayStyle?.right || event!.clientX - 400,
                    bottom: isOverlayOverflowFromViewport ? "5px" : overlayStyle?.bottom ? overlayStyle?.bottom : 'unset'

                }
            }
        }
        return { top: "50%", left: "50%", transform: "translate(-50%, -50%)" }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, overlayStyle?.bottom, overlayStyle?.left, overlayStyle?.right, overlayStyle?.top])

    useEffect(() => {
        if (!overlayRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // set the position style when the size of the element changes
            if (overlayRef.current?.clientHeight) setOverlayStyleObj(isCustomStyle && overlayStyle ? overlayStyle : getStyle(overlayRef.current?.clientHeight))
        });
        resizeObserver.observe(overlayRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, [getStyle, isCustomStyle, overlayStyle]);

    const handleClickOutside = (e: MouseEvent) => {
        if (closeOnClickOutside && isComponentMounted.current) removeAppOverlayPopover({ shouldSlideOutMobileAnimation: !!config?.slideInMobileAnimation });
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        setTimeout(() => {
            isComponentMounted.current = true;
        }, 0);
        return () => {
            document.removeEventListener('click', handleClickOutside);
            isComponentMounted.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeOnClickOutside]);

    const getSlideInAnimationClassByConfig = () => {
        if(config?.slideInMobileAnimation === "half-screen") return slideUpHalfScreenMobilePopoverClassName;
        else if(config?.slideInMobileAnimation === "full-screen") return slideUpFullScreenMobilePopoverClassName;
        return '';
    }

    const getContentContainerClassName = ():string => {
        let classNameStr = 'app-overlay-popover-content-container';
        if(className) classNameStr += ` ${className}`;
        if(!isSlideInMobileAnimationEnded) classNameStr += ` ${getSlideInAnimationClassByConfig()}`;
        if(config?.slideInMobileAnimation === "full-screen") classNameStr += ' full-screen-overlay';
        return classNameStr;
    }


    return (
        <>
            <div
                id='app-overlay-popover-background-container'
                className={`app-overlay-popover-background-container${allowInteraction ? ' allow-interaction' : ''} fadeIn`}>
                <SwipeToCloseWrapper
                    wrapperRef={overlayRef}
                    id="app-overlay-popover-content-container"
                    className={getContentContainerClassName()}
                    swipeDirection={config?.closeSwipeDirection || ECloseSwipeDirection.DOWN}
                    onClick={(e) => { e.stopPropagation(); }}
                    style={!isMobileView() ? overlayStyleObj : shouldOverrideDefaultStyles ? overlayStyleObj : {}}
                    onAnimationEnd={(animationData) => {
                        if(animationData.animationName === 'slideUpInAnimation') setIsSlideInMobileAnimationEnded(true);
                    }}
                    onCloseWhenSwipeOutEnd={() => closeOnClickOutside && removeAppOverlayPopover()}
                    canCloseOnSwipe={config?.shouldCloseBySwipeOnMobile}
                >
                    {content}
                </SwipeToCloseWrapper>
            </div>
        </>
    );
};