import { useTranslation } from "react-i18next";
import { FunctionComponent, useEffect, useRef } from "react";
import { IChatForm, IChatMessage, IHistoryParams, IHistoryQuery, IHistoryResponse, ISessionSummariesResponse } from "../../../../chat.interfaces";
import SummaryNotAvailableIcon from '../../../../../../assets/images/summary-not-available-icon.svg';
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { EAPIStatus } from "../../../../../../shared/api/models";
import { chatHistoryParam, chatSessionIdLocalStorageKey, numberOfMessagesToFetch, userInfoLocalStorageKey } from "../../../../../../app/constants";
import { getChatHistoryReq, setScrollIntoMessageId } from "../../../../chat.store";
import { useSearchParams } from "react-router-dom";
import { ApplicationInsightsApi } from "../../../../../../application-insights";
import { useFormContext } from "react-hook-form";
import { getTimeByDate } from "../../../../../../shared/utils/dateFormat";
import { getItemFromLocalStorage } from "../../../../../../shared/utils/localStorage.utils";
import { IUser } from "../../../../../../app/auth/auth.interfaces";
import './SingleSummary.scss';
import { transformResponseMessageToChatMessage } from "../../../Chat.utils";

interface ISingleSummaryProps {
  singleSummary: ISessionSummariesResponse;
}

export const SingleSummary: FunctionComponent<ISingleSummaryProps> = ({ singleSummary }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { historyResponse, localHistoryMessages } = useAppSelector(store => store.chatReducer);
  const abortControllerRef = useRef(new AbortController());
  const [, setSearchParams] = useSearchParams();
  const chatForm = useFormContext<IChatForm>();

  useEffect(() => {
    const controller = new AbortController();
    abortControllerRef.current = controller;

    return () => {
      controller.abort();
      abortControllerRef.current.abort();
    };
  }, []);

  const onClickSummary = () => {
    // if the summary is exist and this conversation already exist in the current arr search and scroll to the first message
    if (singleSummary && localHistoryMessages[0]?.creationTime < singleSummary.creationTime) {
      scrollToLocalHistory();
      closeSummariesPopUp();
    }
    // else ask this conversation from the server
    else if (singleSummary?.sessionId && ![EAPIStatus.PENDING].includes(historyResponse.status)) {
      const historyParams = {
        sessionId: singleSummary?.sessionId,
        currentMessageNumber: 1
      }
      const historyQuery = {
        before: numberOfMessagesToFetch,
        after: numberOfMessagesToFetch,
        stopDate: localHistoryMessages[0]?.creationTime
      }
      getSummaryChatHistory(historyParams, historyQuery);
    }
  }

  const getSummaryChatHistory = (historyParams: IHistoryParams, historyQuery: IHistoryQuery) => {
    dispatch(getChatHistoryReq({ historyParams, historyQuery, signal: abortControllerRef.current.signal }))
      .unwrap()
      .then((historyData) => {
        scrollToTheFirstMsg(historyData);
      })
      .catch((e) => {
        ApplicationInsightsApi.trackException(e);
      })
  }

  const scrollToTheFirstMsg = (historyData: IHistoryResponse[]) => {
    let firstSummaryMessage: IChatMessage | undefined;
    const sessionSummaryFirstMessage = historyData.find(item => item.sessionId === singleSummary?.sessionId && (item.party === 'Bot' || item.party === 'User'));
    if (sessionSummaryFirstMessage) {
     const transformedHistoryMessages = transformResponseMessageToChatMessage([sessionSummaryFirstMessage], null);
     if(transformedHistoryMessages.length > 0) firstSummaryMessage = transformedHistoryMessages[0];
    }
    // if message not found in the response array, search it in the original array
    else firstSummaryMessage = localHistoryMessages.find(item => item.sessionId === singleSummary?.sessionId && (item.party === 'Bot' || item.party === 'User'));

    if (firstSummaryMessage && firstSummaryMessage?.msgId) {
      dispatch(setScrollIntoMessageId(firstSummaryMessage?.msgId));
    }

    ApplicationInsightsApi.trackEvent("click on summary - scroll into message after getHistory req",{
      userId: (getItemFromLocalStorage(userInfoLocalStorageKey) as IUser)?.id || "userId not available",
      currentSessionId: getItemFromLocalStorage(chatSessionIdLocalStorageKey) || "sessionId not available",
      scrollIntoMessageId: firstSummaryMessage?.msgId || 'message id is not exist',
      scrollIntoMessageParty: firstSummaryMessage?.party || 'message party is not exist',
      summarySessionId: singleSummary?.sessionId || "summary session id is not exist",
      summaryText: singleSummary.summary,
      summaryCreationTime: singleSummary.creationTime,
    });
  }

  const scrollToLocalHistory = () => {
    const firstSummaryMessage = [...localHistoryMessages, ...chatForm.getValues("messagesArr")].find(item => item.sessionId === singleSummary?.sessionId && (item.party === 'Bot' || item.party === 'User'));
    if (firstSummaryMessage && firstSummaryMessage?.msgId) {
      const firstSummaryMessageElement = document.getElementById(firstSummaryMessage?.msgId) as HTMLElement;
      firstSummaryMessageElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    ApplicationInsightsApi.trackEvent("click on summary - scroll into local message",{
      userId: (getItemFromLocalStorage(userInfoLocalStorageKey) as IUser)?.id || "userId not available",
      currentSessionId: getItemFromLocalStorage(chatSessionIdLocalStorageKey) || "sessionId not available",
      scrollIntoMessageId: firstSummaryMessage?.msgId || 'message id is not exist',
      scrollIntoMessageParty: firstSummaryMessage?.party || 'message party is not exist',
      summarySessionId: singleSummary?.sessionId || "summary session id is not exist",
      summaryText: singleSummary.summary,
      summaryCreationTime: singleSummary.creationTime,
    });
  }

  const closeSummariesPopUp = () => {
    setSearchParams(prev => {
      prev.delete(chatHistoryParam);
      return prev;
    });
  }

  return (
    <div className={`single-summary ${!!singleSummary.summary ? "summary-available" : "summary-not-available-yet"}`} onClick={onClickSummary} data-testid='summary'>
      <h4 className="summary-time">{getTimeByDate(singleSummary.creationTime)}</h4>
      {singleSummary.summary
        ?
        <div className="summary-name">{singleSummary.summary}</div>
        :
        <div className="summary-not-available"><img src={SummaryNotAvailableIcon} alt="summary" />{t('summariesPageSummaryNotAvailableText')}</div>
      }
    </div>
  )
}