import { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { resetUserProfileRequest } from '../../settingsMenu.store';
import { ConfirmModal } from '../../../../confirm-modal/ConfirmModal';
import { useApiData } from '../../../../../hooks/useApiData';
import { isErrorCodeNotForbiddenOrUnauthorized } from '../../../../../utils/isErrorCodeNotForbiddenOrUnauthorized';
import { ErrorMessage } from '../../error-message/ErrorMessage';
import { LottieAppLoader } from '../../../../lottie-loader/LottieLoader';
import { EAPIStatus } from '../../../../../api/models';
import { IResetUserReqPayload } from '../../SettingsMenu.interfaces';
import { useLocalStorage } from '../../../../../../shared//utils/useLocalStorage';
import { IUser } from '../../../../../../app/auth/auth.interfaces';
import { userInfoLocalStorageKey } from '../../../../../../app/constants';
import { handleLocalLogOut } from '../../../../../utils/logOut';

export const ResetProfile = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [shouldOpenConfirmReset, setShouldOpenConfirmReset] = useState(false);
  const [isPartialReset, setIsPartialReset] = useState(false);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const { resetUserProfile } = useAppSelector(store => store.settingsMenuReducer);
  const [userInfo,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);

  useApiData(resetUserProfile, {
    onRejected(error) {
      if (isErrorCodeNotForbiddenOrUnauthorized(error.code)) setShouldDisplayError(true);
    }
  })

  const handleReset = async () => {
    const resetPayload: IResetUserReqPayload = { isPartialReset };
    setShouldOpenConfirmReset(false);
    await dispatch(resetUserProfileRequest(resetPayload));
    handleLocalLogOut();
  }

  return (
    <Fragment>
      <section className='data'>
        <div>
          <h3 className='title static-string'>{t("settingsPersonalInfoDataTitle")}</h3>
          <p>{t("settingsPersonalInfoDataSubTitle")}</p>
        </div>
        <div className="button-container">
          <button className='personal-info-btn' data-testid="reset-profile" onClick={() => setShouldOpenConfirmReset(true)}>{t("settingsPersonalInfoDeleteProfileButton")}</button>
          { userInfo?.isInternalUser && <button className='personal-info-btn' data-testid="partial-reset-profile" onClick={() => { setIsPartialReset(true); setShouldOpenConfirmReset(true); }}>{t("settingsPersonalInfoPartialResetProfileButton")}</button>}
        </div>
        { shouldDisplayError && <ErrorMessage /> }
      </section>
      {shouldOpenConfirmReset &&
        <ConfirmModal
          title={t("settingsResetProfileConfirmModalTitleText")}
          informationText={t("settingsResetProfileConfirmModalSubTitleText")}
          onCancel={() => setShouldOpenConfirmReset(false)}
          onConfirm={handleReset}
          confirmBtnText={t("settingsResetProfileConfirmModalConfirmButtonText")}
        />
      }
      {resetUserProfile.status === EAPIStatus.PENDING && <LottieAppLoader testId='reset-profile-loader' className='reset-profile-loader' />}
    </Fragment>
  )
}