import { createAction, createReducer } from '@reduxjs/toolkit';
import { i18LanguageCode } from '../i18/i18n.codes';

export interface SharedStoreState {
    languageCode: i18LanguageCode;
    isComeFromRegistration: boolean;
    clickNavbarConfirmModalText: string | null;
    shouldDisplayProductTour: boolean;
}

const initialStateSharedStore: SharedStoreState = {
    languageCode: 'en',
    isComeFromRegistration: false,
    clickNavbarConfirmModalText: null,
    shouldDisplayProductTour: false
};


export const setLanguageCode = createAction<i18LanguageCode>('sharedStore/setLanguage');

export const setIsComeFromRegistration = createAction<boolean>('sharedStore/setIsComeFromRegistration');

export const setClickNavbarConfirmModalText = createAction<string | null>('sharedStore/setClickNavbarConfirmModalText');

export const setShouldDisplayProductTour = createAction<boolean>('sharedStore/setShouldDisplayProductTour');

export const sharedStoreReducer = createReducer(initialStateSharedStore, (builder) => {
    builder.addCase(setLanguageCode, (state, action) => {
        state.languageCode = action.payload;
    });
    builder.addCase(setIsComeFromRegistration, (state, action) => {
        state.isComeFromRegistration = action.payload;
    });
    builder.addCase(setClickNavbarConfirmModalText, (state, action) => {
        state.clickNavbarConfirmModalText = action.payload;
    });
    builder.addCase(setShouldDisplayProductTour, (state, action) => {
        state.shouldDisplayProductTour = action.payload;
    });
});
